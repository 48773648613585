const Initialstate = {
  projectList: []
};

export const ProjectListReducer = (state = Initialstate, action) => {
  switch (action.type) {
  case 'PROJECT_LIST':
    return {
      projectList: action.payload
    };
  default:
    return state;
  }
};
