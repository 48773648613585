/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  Text,
  Button,
  Center,
  SegmentedControl,
  Select,
  Loader,
  Card as MantineCard,
  Accordion,
  Modal,
  Flex,
  Divider,
  ScrollArea,
  LoadingOverlay,
} from "@mantine/core";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
import {
  IconArrowLeft,
  IconDeviceFloppy,
  IconFileDownload,
  IconPencil,
  IconTemplate,
  IconTrashX,
} from "@tabler/icons-react";
import { motion } from "framer-motion";
import "../Bookmarks/bookmarks.scss";
import ReactQuill from "react-quill";
import {
  AxiosGetWithToken,
  AxiosPostWithToken,
  AxiosPutWithToken,
  downloadProcessPost,
} from "../../Utils/Api";
import GenerateContentModal from "../../Components/AiWriting/Modal/GenerateContent.tsx";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mantine/hooks";
import HTMLtoDOCX from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import EditDocumentModal from "../../Components/AiWriting/Modal/EditDocument";
import BookmarkAccordion from "../../Components/Bookmarks/BookmarkSideComponent";
import { useDispatch } from "react-redux";
import { allScrappingData } from "../../Redux/Action/scrappingSide";
import Book from "../Book/Book";
import { LinearProgress } from "@mui/material";

interface Header {
  Authorization: string | null;
}

interface LocationState {
  keywordName: string;
  keywordId: string;
  projectId: string | null;
  projectName: string | null;
  backPath: string;
  pathName: string;
}

interface comments {
  description: string;
  rating: number;
  content: string;
  author: string;
  commentedOn: Date;
}

interface bookData {
  description?: string;
  name: string;
  author: string;
  publicationDate: string;
  reviews: comments[];
  url?: string | undefined;
  uuid: string;
  site: string;
  lookInside: number;
  tableOfContent: string;
}

interface star {
  star1: comments[];
  star2: comments[];
  star3: comments[];
  star4: comments[];
  star5: comments[];
}

const AiWritingEditor: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { keywordName, projectId, projectName, keywordId, backPath, pathName } =
    location.state as LocationState;

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const isMobile = useMediaQuery("(max-width: 50em)");

  const [segmentedControlOption, setSegmentedControlOption] =
    useState<string>("bookmarks");

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const [editingArtifactName, setEditingArtifactName] = useState<string>("");

  const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  useEffect(() => {
    if (keywordName) {
      setEditingArtifactName(keywordName);
    }
  }, [keywordName]);

  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  const [allBooksData, setAllBooksData] = useState<Array<bookData>>([]);

  const [getAmazonData, setGetAmazonData] = useState<boolean>(false);
  const [getAudibleData, setGetAudibleData] = useState<boolean>(false);
  const [getBarnesNobelData, setGetBarnesNobelData] = useState<boolean>(false);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const dispatch = useDispatch();
  const [list, setList] = useState({});

  const [fetchedKeywordName, setFetchedKeywordName] = useState<string>();

  let allSideData: Array<bookData> = [];
  const bookIdList: Array<string> = [];

  let amazonIndex = 0;
  let audioIndex = 0;
  let barnsIndex = 0;

  useEffect(() => {
    setLoading(true);
    if (location.pathname.includes("performSearch") && keywordId) {
      if (headers?.Authorization) {
        setLoading(true);
        AxiosGetWithToken(`/getSearchResultsByKeywordID/${keywordId}`, {
          headers,
        }).then((response) => {
          setFetchedKeywordName(keywordName);
          setGetAmazonData(true);
          setGetAudibleData(true);
          setGetBarnesNobelData(true);
          setLoading(false);
          setAllBooksData([...response.data.data.searchList]);
          dispatch(
            allScrappingData(
              JSON.parse(JSON.stringify(response.data.data.searchList))
            )
          );
        });
      }
    }

    if (location.pathname.includes("performSearch") && keywordName) {
      if (headers?.Authorization) {
        setLoading(true);
        console.log(keywordName);
        console.log("creating keyword");
        AxiosPostWithToken(
          "/keyword/createWithoutProject",
          { key: keywordName, projectId: projectId },
          { headers }
        ).then((response) => {
          const keywordData = response.data.data.keywordData;

          console.log(keywordData);

          sessionStorage.setItem("keywordId", keywordData._id);

          console.log("scraping started");

          sequentialScrape(keywordName, keywordData._id);

          console.log("scraping completed");

          allSideData = [];

          amazonIndex = 0;
          audioIndex = 0;
          barnsIndex = 0;

          setGetAmazonData(false);
          setGetAudibleData(false);
          setGetBarnesNobelData(false);
        });
      }
    }

    if (location.pathname.includes("viewSearchResults")) {
      if (headers?.Authorization) {
        //setLoading(true);
        setIsFetching(true);
        AxiosGetWithToken(`/getSearchResultsByKeywordID/${keywordId}`, {
          headers,
        }).then((response) => {
          setLoading(false);
          setIsFetching(false);
          setAllBooksData([...response.data.data.searchList]);
          dispatch(
            allScrappingData(
              JSON.parse(JSON.stringify(response.data.data.searchList))
            )
          );
        });
      }
    }

    setAllBooksData([]);
    dispatch(allScrappingData(JSON.parse(JSON.stringify(allSideData))));
  }, []);

  // const commonDownloadProgress = async (progressEvent, commonIndex) => {
  //   const dataChunk: string = progressEvent.currentTarget.response;

  //   const splitArray = dataChunk.split("12d5c6a4-4eba-11ed-bdc3-0242ac120002");

  //   if (splitArray?.length > 0) {
  //     if (splitArray[splitArray?.length - 1] === "\r\n") {
  //       splitArray.forEach(async (bookData: string) => {
  //         // If the data contains only \r\n then do not process it
  //         if (bookData !== "\r\n") {
  //           const obj: bookData = await JSON.parse(bookData.trim());

  //           /* if uuid not exist in array -> Push data into all data and add uuid into array.
  //           if uuid exist in array no action required */
  //           if (!bookIdList.includes(obj.uuid)) {
  //             bookIdList.push(obj.uuid);

  //             if (obj?.site) {
  //               allSideData.push(obj);
  //               setLoading(false);
  //               setAllBooksData(allSideData);
  //               dispatch(
  //                 allScrappingData(JSON.parse(JSON.stringify(allSideData)))
  //               );
  //             }
  //           }
  //         }
  //       });
  //     }
  //   }
  // };

  const commonDownloadProgress = async (progressEvent, commonIndex) => {
    const dataChunk: string = progressEvent.currentTarget.response;
    const splitArray = dataChunk.split("12d5c6a4-4eba-11ed-bdc3-0242ac120002");

    if (splitArray?.length > 0) {
      if (splitArray[splitArray?.length - 1] === "\r\n") {
        splitArray.forEach(async (bookData: string, index: number) => {
          if (bookData !== "\r\n") {
            const obj: bookData = await JSON.parse(bookData.trim());

            if (!bookIdList.includes(obj.uuid)) {
              bookIdList.push(obj.uuid);

              if (obj?.site) {
                allSideData.push(obj);
                setAllBooksData((prevData) => [...prevData, obj]);
                dispatch(
                  allScrappingData(
                    JSON.parse(JSON.stringify([...allSideData, obj]))
                  )
                );
                if (index === 0) {
                  setLoading(false); // Disable loading after the first book is fetched
                }
              }
            }
          }
        });
      }
    }
  };

  const sequentialScrape = async (keyword: string, keywordId: any) => {
    await getDataAmazonList(keyword, keywordId);
    await getAudibleDataList(keyword, keywordId);
    await getBarnesNobelDataList(keyword, keywordId);
  };

  const getDataAmazonList = async (keyword: string, keywordId: any) => {
    const data = {
      key: keyword,
      keywordId,
    };

    async function onDownloadProgress(progressEvent) {
      await commonDownloadProgress(progressEvent, amazonIndex);
    }

    console.log("Fetching books from amazon");

    console.log(headers);

    try {
      const response = await downloadProcessPost(
        "/fetch-data/amazon",
        data,
        onDownloadProgress,
        headers
      );
      setGetAmazonData(true);
      console.log("Completed fetching books from amazon");
      toast.success("Data from Amazon fetched successfully!");
      // This function now effectively returns a Promise that resolves when this line is reached
    } catch (error) {
      // Start by assuming the simplest structure and expand as necessary
      let errorMessage = "An unexpected error occurred";
      if (typeof error === "object" && error !== null) {
        // Narrow down the error type with further checks
        const axiosError = error as {
          response?: { data?: { message?: string } };
        };
        if (axiosError.response?.data?.message) {
          errorMessage = axiosError.response.data.message;
        }
      }
      toast.error(errorMessage);
    }
  };

  const getAudibleDataList = async (keyword: string, keywordId: any) => {
    const data = {
      key: keyword,
      keywordId,
    };

    async function onDownloadProgress(progressEvent) {
      await commonDownloadProgress(progressEvent, amazonIndex);
    }

    console.log("Fetching books from audible");

    try {
      const response = await downloadProcessPost(
        "/fetch-data/audible",
        data,
        onDownloadProgress,
        headers
      );
      setGetAudibleData(true);
      console.log("Completed fetching books from audible");
      toast.success("Data from Audible fetched successfully!");
      // This function now effectively returns a Promise that resolves when this line is reached
    } catch (error) {
      // Start by assuming the simplest structure and expand as necessary
      let errorMessage = "An unexpected error occurred";
      if (typeof error === "object" && error !== null) {
        // Narrow down the error type with further checks
        const axiosError = error as {
          response?: { data?: { message?: string } };
        };
        if (axiosError.response?.data?.message) {
          errorMessage = axiosError.response.data.message;
        }
      }
      toast.error(errorMessage);
    }
  };

  const getBarnesNobelDataList = async (keyword: string, keywordId: any) => {
    const data = {
      key: keyword,
      keywordId,
    };

    async function onDownloadProgress(progressEvent) {
      await commonDownloadProgress(progressEvent, amazonIndex);
    }

    console.log("Fetching books from b&n");

    try {
      const response = await downloadProcessPost(
        "/fetch-data/barnesAndNoble",
        data,
        onDownloadProgress,
        headers
      );
      setGetBarnesNobelData(true);
      console.log("Completed fetching books from b&n");
      toast.success("Data from Barnes & Nobel fetched successfully!");
      // This function now effectively returns a Promise that resolves when this line is reached
    } catch (error) {
      // Start by assuming the simplest structure and expand as necessary
      let errorMessage = "An unexpected error occurred";
      if (typeof error === "object" && error !== null) {
        // Narrow down the error type with further checks
        const axiosError = error as {
          response?: { data?: { message?: string } };
        };
        if (axiosError.response?.data?.message) {
          errorMessage = axiosError.response.data.message;
        }
      }
      toast.error(errorMessage);
    }
  };

  //   useEffect(() => {
  //     fetchBooks();
  //   }, [id]);

  const fetchBooks = async () => {
    setLoading(true);
    setIsPageLoading(true);
    setError(null);
    try {
      const response = await AxiosGetWithToken(`/artifact/${id}`, {
        headers,
      });
      setEditingArtifactName(response.data.data.artifact.NAME);

      setIsPageLoading(false);
    } catch (err) {
      setError("Failed to fetch snippets");
    } finally {
      setLoading(false);
      setIsPageLoading(false);
    }
  };

  const handleDeleteKeyword = async () => {
    setIsDeleting(true);
    try {
      await AxiosPutWithToken(`/keyword/deleteById/${id}`, {}, { headers });
      toast.success("Document deleted successfully!");
      setIsDeleting(false);
      navigate("/webscrape");
    } catch (err) {
      console.error("Failed to delete document", err);
      toast.error("Failed to delete document");
      setIsDeleting(false);
    }
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.7 },
  };

  return (
    <div className="bookmark-content-page">
      <CommonHeader />
      <div className="bg-light-yellow py-4 full-width">
        {/* {location.pathname.includes("performSearch") && (
          <LoadingOverlay
            visible={loading}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
            loaderProps={{ color: "blue", type: "bars" }}
          />
        )} */}
        <Container>
          <Row>
            <Col md={12} className="mx-auto ">
              <Button
                leftSection={<IconArrowLeft size={15} />}
                variant="light"
                color="gray"
                radius="xl"
                size="xs"
                onClick={() =>
                  navigate(backPath, { state: { projName: projectName } })
                }
              >
                {pathName}
              </Button>
              <h2 className="text-left mt-2">{editingArtifactName}</h2>
              <Row className="actions mt-2">
                <Col xs={4} md={2} className="text-center ">
                  <Card
                    className="bookmarks-option-card bookmarks-select-option d-flex flex-column justify-content-center align-items-center"
                    onClick={() => {
                      setDeleteModalOpened(true);
                    }}
                  >
                    <IconTrashX
                      color="red"
                      size={40}
                      stroke={1}
                      className="icon mb-2"
                    />
                    <Card.Text className="label">Delete</Card.Text>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {!(getBarnesNobelData && getAmazonData && getAudibleData) &&
        !loading &&
        location.pathname.includes("performSearch") && (
          <LinearProgress className="progressBar mt-1" />
        )}
      {/* <LinearProgress className="progressBar mt-1" /> */}
      <Container>
        <motion.div {...fadeInUp}>
          <Row className="my-4">
            <Col md={4}>
              <Card className="p-3 mb-3">
                <SegmentedControl
                  fullWidth
                  value={segmentedControlOption}
                  onChange={setSegmentedControlOption}
                  data={[
                    {
                      value: "bookmarks",
                      label: (
                        <Center style={{ gap: 5 }}>
                          <span>Bookmarks</span>
                        </Center>
                      ),
                    },
                  ]}
                  className="filter-segmented-control"
                />

                <BookmarkAccordion />
              </Card>
            </Col>
            <Col md={8}>
              {location.pathname.includes("performSearch") &&
              loading === true ? (
                <div style={{ height: "300px" }}>
                  <Center mt={"120px"}>
                    <Loader color="blue" type="bars" />
                  </Center>
                  <Center mt={"10px"}>
                    <p>
                      Please wait, this search may take a while. Feel free to
                      explore the site while the results populate.
                    </p>
                  </Center>
                </div>
              ) : null}
              {isFetching ? (
                <Center style={{ height: "400px" }}>
                  <Loader color="blue" type="bars" />
                </Center>
              ) : (
                <>
                  {allBooksData?.length > 0 ? (
                    allBooksData.map((book: bookData, index: number) => {
                      const starObj: star = {
                        star1: [],
                        star2: [],
                        star3: [],
                        star4: [],
                        star5: [],
                      };
                      for (
                        let reviewsRating = 1;
                        reviewsRating <= 5;
                        reviewsRating++
                      ) {
                        const result =
                          book.reviews.length > 0 &&
                          book.reviews.filter(
                            (review: comments) =>
                              review.rating === reviewsRating
                          );
                        starObj[`star${reviewsRating}`] = result;
                      }
                      return (
                        <Fragment key={index}>
                          <div>
                            <Fragment>
                              <Book
                                indexOfBook={index}
                                bookData={book}
                                starComments={starObj}
                                list={list}
                                setList={setList}
                                url={book?.url}
                                bookName={book?.name}
                                bookAuthor={book?.author}
                              />
                            </Fragment>
                          </div>
                        </Fragment>
                      );
                    })
                  ) : (
                    <Fragment>
                      {allBooksData.length === 0 &&
                        location.pathname.includes("viewSearchResults") &&
                        !loading && (
                          <div>
                            <Fragment>
                              <h2>No search results found </h2>
                            </Fragment>
                          </div>
                        )}
                      {getAmazonData &&
                        getAudibleData &&
                        getBarnesNobelData &&
                        location.pathname.includes("performSearch") &&
                        !loading && (
                          <div>
                            <Fragment>
                              <h2>No search results found</h2>
                            </Fragment>
                          </div>
                        )}
                    </Fragment>
                  )}
                </>
              )}
            </Col>
          </Row>
        </motion.div>
      </Container>
      {/* Delete document modal */}
      <Modal
        opened={deleteModalOpened}
        onClose={() => {
          setDeleteModalOpened(false);
          setIsDeleting(false);
        }}
        title={
          <Text size="xl" fw={700} c={"#333"}>
            Confirm Delete
          </Text>
        }
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        centered
        radius={15}
      >
        <Text size="sm">
          Are you sure you want to delete this keyword? This action cannot be
          undone.
        </Text>
        <Flex mt="md" justify="flex-end" gap="xs" align="center">
          <Button
            variant="default"
            radius="md"
            onClick={() => {
              setDeleteModalOpened(false);
              setIsDeleting(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="red"
            radius="md"
            loading={isDeleting}
            loaderProps={{ type: "dots" }}
            onClick={() => {
              handleDeleteKeyword();
            }}
          >
            Confirm
          </Button>
        </Flex>
      </Modal>
    </div>
  );
};

export default AiWritingEditor;
