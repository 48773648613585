/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  Button,
  TextInput,
  Group,
  SegmentedControl,
  Menu,
  Table,
  Center,
  ActionIcon,
  Popover,
  ScrollArea,
  Checkbox,
  Pagination,
  Text,
  Divider,
  Loader,
} from "@mantine/core";
import {
  IconFilter,
  IconStack2,
  IconCirclePlus,
  IconDots,
  IconTrash,
  IconHistory,
  IconFolderOpen,
  IconEdit,
  IconArrowLeft,
  IconAlertCircle,
  IconAlertTriangle,
  IconTemplate,
} from "@tabler/icons-react";
import "../Bookmarks/bookmarks.scss";
import { motion } from "framer-motion";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { AxiosGetWithToken, AxiosPutWithToken } from "../../Utils/Api";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
import { toast } from "react-toastify";
import SelectTemplateModal from "../../Components/AiWriting/Modal/SelectTemplate";
import CreateBlankModal from "../../Components/AiWriting/Modal/CreateBlank";

interface Header {
  Authorization: string | null;
}

interface Artifact {
  _id: string;
  NAME: string;
  CREATED_ON: string;
  UPDATED_ON: string;
  PROJECT_ID: string | null;
}

interface Project {
  _id: string;
  NAME: string;
}

const AiWritingPage: React.FC = () => {
  const [filter, setFilter] = useState<string>("recents");
  const [opened, setOpened] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [projectSearchValue, setProjectSearchValue] = useState<string>("");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([
    "alphabetical",
  ]);
  const [sortOrder, setSortOrder] = useState<string[]>(["alphabetical"]);
  const [activePage, setActivePage] = useState(1);
  const [selectTemplateModalOpened, setSelectTemplateModalOpened] =
    useState<boolean>(false);
  const [createBlankModalOpened, setCreateBlankModalOpened] =
    useState<boolean>(false);
  const [data, setData] = useState<Artifact[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  useEffect(() => {
    fetchProjects();
    fetchData();
  }, [filter, activePage]);

  const fetchProjects = async () => {
    try {
      const response = await AxiosGetWithToken("/getProjectsByUserId", {
        headers,
      });
      setProjects(response.data.data.userProjects);
    } catch (err) {
      console.error("Failed to fetch projects", err);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await AxiosGetWithToken(
        `/getArtifactsByUserId/?page=${activePage}&limit=12`,
        {
          headers,
        }
      );
      setData(response.data.data.artifacts);
    } catch (err) {
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const handleProjectChange = async (artifactId, projectId) => {
    try {
      const updatedProjectId = projectId === "none" ? null : projectId;
      await AxiosPutWithToken(
        `/artifact/update/${artifactId}`,
        { PROJECT_ID: updatedProjectId },
        { headers }
      );
      toast.success("Document updated successfully!");
      fetchData();
    } catch (err) {
      console.error("Failed to update document", err);
      toast.error("Failed to update document");
    }
  };

  const handleDeleteDocument = async (id) => {
    try {
      await AxiosPutWithToken(`/artifact/delete/${id}`, {}, { headers });
      toast.success("Document deleted successfully!");
      fetchData();
    } catch (err) {
      console.error("Failed to delete document", err);
      toast.error("Failed to delete document");
    }
  };

  const filteredProjects = [
    { _id: "none", NAME: "None" },
    ...projects.filter((project) =>
      project.NAME.toLowerCase().includes(projectSearchValue.toLowerCase())
    ),
  ];

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  const handleFilterApply = () => {
    setSortOrder(selectedFilters);
    setOpened(false);
  };

  useEffect(() => {
    if (filter === "all") {
      let sortedData = [...data];
      if (sortOrder.includes("alphabetical")) {
        sortedData = sortedData.sort((a, b) => a.NAME.localeCompare(b.NAME));
      }
      if (sortOrder.includes("recent")) {
        sortedData = sortedData.sort(
          (a, b) =>
            new Date(b.UPDATED_ON).getTime() - new Date(a.UPDATED_ON).getTime()
        );
      }
      if (sortOrder.includes("created")) {
        sortedData = sortedData.sort(
          (a, b) =>
            new Date(b.CREATED_ON).getTime() - new Date(a.CREATED_ON).getTime()
        );
      }
      if (sortOrder.includes("updated")) {
        sortedData = sortedData.sort(
          (a, b) =>
            new Date(b.UPDATED_ON).getTime() - new Date(a.UPDATED_ON).getTime()
        );
      }
      setData(sortedData);
    }
  }, [sortOrder, filter, data]);

  const filteredData = data.filter(
    (item) =>
      item.NAME.toLowerCase().includes(searchValue.toLowerCase()) ||
      (item.PROJECT_ID &&
        projects
          .find((project) => project._id === item.PROJECT_ID)
          ?.NAME.toLowerCase()
          .includes(searchValue.toLowerCase()))
  );

  const displayedData =
    filter === "recents"
      ? filteredData
          .sort(
            (a, b) =>
              new Date(b.UPDATED_ON).getTime() -
              new Date(a.UPDATED_ON).getTime()
          )
          .slice(0, 5)
      : filteredData;

  const paginatedData = displayedData.slice(
    (activePage - 1) * 12,
    activePage * 12
  );

  const formatDate = (date: string) =>
    format(new Date(date), "MMM dd, yyyy KK:mm a");

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.7 },
  };

  return (
    <div className="bookmarks-page">
      <CommonHeader />
      <div className="bg-light-yellow py-4 full-width">
        <Container>
          <Row>
            <Col md={12} className="mx-auto">
              <Button
                leftSection={<IconArrowLeft size={15} />}
                variant="light"
                color="gray"
                radius="xl"
                size="xs"
                onClick={() => navigate("/home")}
              >
                Home
              </Button>
              <h2 className="text-left mt-2">Writer</h2>

              <Row className="actions mt-2">
                <Col xs={4} md={2} className="text-center ">
                  <Card
                    className="bookmarks-option-card bookmarks-select-option d-flex flex-column justify-content-center align-items-center"
                    onClick={() => setSelectTemplateModalOpened(true)}
                  >
                    <IconTemplate size={40} stroke={1} className="icon mb-2" />
                    <Card.Text className="label">Select template</Card.Text>
                  </Card>
                </Col>
                <Col xs={4} md={2} className="text-center ">
                  <Card
                    className="bookmarks-option-card bookmarks-select-option d-flex flex-column justify-content-center align-items-center"
                    onClick={() => setCreateBlankModalOpened(true)}
                  >
                    <IconCirclePlus
                      size={40}
                      stroke={1}
                      className="icon mb-2"
                    />
                    <Card.Text className="label">Create Blank</Card.Text>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <motion.div {...fadeInUp}>
          <Row className="filters my-4">
            <Col md={12} className="mx-auto d-flex align-items-center">
              <Col xs={3} md={1} className="text-center p-0">
                <SegmentedControl
                  value={filter}
                  onChange={setFilter}
                  data={[
                    {
                      value: "recents",
                      label: (
                        <Center style={{ gap: 5 }}>
                          <IconHistory size={18} stroke={1} />
                          <span>Recents</span>
                        </Center>
                      ),
                    },
                    {
                      value: "all",
                      label: (
                        <Center style={{ gap: 5 }}>
                          <IconStack2 size={18} stroke={1} />
                          <span>All</span>
                        </Center>
                      ),
                    },
                  ]}
                  className="filter-segmented-control"
                />
              </Col>
              <Col
                xs={filter === "all" ? 5 : 5}
                md={filter === "all" ? 7 : 7}
              />

              <Col xs={4} md={4} className="p-0">
                <Group>
                  <TextInput
                    placeholder="Search"
                    value={searchValue}
                    onChange={handleSearch}
                    rightSection={
                      filter === "all" && (
                        <Popover
                          width={250}
                          trapFocus
                          position="bottom"
                          withArrow
                          shadow="md"
                        >
                          <Popover.Target>
                            <ActionIcon
                              variant="subtle"
                              color="gray"
                              aria-label="Settings"
                              onClick={() => setOpened((o) => !o)}
                            >
                              <IconFilter size={18} stroke={1} />
                            </ActionIcon>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <Text fw={500} className="mb-2">
                              Sort by
                            </Text>
                            <Checkbox.Group
                              value={selectedFilters}
                              onChange={setSelectedFilters}
                            >
                              <Checkbox
                                className="mb-2"
                                value="alphabetical"
                                label="Alphabetical Order"
                              />
                              <Checkbox
                                className="mb-2"
                                value="recent"
                                label="Most Recent"
                              />
                              <Checkbox
                                className="mb-2"
                                value="created"
                                label="Created Date"
                              />
                              <Checkbox
                                className="mb-3"
                                value="updated"
                                label="Updated Date"
                              />
                            </Checkbox.Group>
                            <Divider className="mb-1" variant="dashed" />
                            <Text fw={500} className="mb-2">
                              Advanced
                            </Text>
                            <Button
                              fullWidth
                              color="blue"
                              mt="sm"
                              onClick={handleFilterApply}
                            >
                              Apply Filters
                            </Button>
                          </Popover.Dropdown>
                        </Popover>
                      )
                    }
                    style={{ width: "100%" }}
                  />
                </Group>
              </Col>
            </Col>
          </Row>

          <Row className="content">
            <Col md={12}>
              {loading ? (
                <Center style={{ height: "400px" }}>
                  <Loader color="blue" type="bars" />
                </Center>
              ) : error ? (
                <Center
                  style={{
                    flexDirection: "column",
                    height: "400px",
                    gap: "10px",
                  }}
                >
                  <IconAlertTriangle size={48} stroke={1.5} color="#f03e3e" />
                  <Text color="red" size="lg">
                    {error}
                  </Text>
                </Center>
              ) : data.length === 0 ? (
                <Center
                  style={{
                    flexDirection: "column",
                    height: "400px",
                    gap: "10px",
                  }}
                >
                  <IconAlertCircle size={48} stroke={1.5} color="#adb5bd" />
                  <Text color="dimmed" size="lg">
                    No data found
                  </Text>
                </Center>
              ) : (
                <>
                  <Table
                    className="reset-table"
                    highlightOnHover
                    withRowBorders
                    striped
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Created On</th>
                        <th>Updated On</th>
                        <th>Project</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {(filter === "recents"
                        ? displayedData
                        : paginatedData
                      ).map((item, index) => (
                        <tr key={index}>
                          <td style={{ color: "#0a58ca", cursor: "pointer" }}>
                            <Link
                              to={`/writer/${item._id}`}
                              state={{
                                artifactName: item.NAME,
                                projectId: item.PROJECT_ID,
                                projectName:
                                  projects.find(
                                    (project) => project._id === item.PROJECT_ID
                                  )?.NAME || "N/A",
                                backPath: "/writer",
                                pathName: "Writer",
                              }}
                            >
                              {item.NAME}
                            </Link>
                          </td>
                          <td>{formatDate(item.CREATED_ON)}</td>
                          <td>{formatDate(item.UPDATED_ON)}</td>
                          <td
                            style={{ position: "relative", width: "240px" }}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {projects.find(
                                (project) => project._id === item.PROJECT_ID
                              )?.NAME || "N/A"}
                              {hoveredIndex === index && (
                                <Popover
                                  width={300}
                                  trapFocus
                                  position="bottom"
                                  withArrow
                                  shadow="md"
                                  closeOnClickOutside
                                  onOpen={() => {
                                    setSelectedProject(
                                      item.PROJECT_ID || "none"
                                    );
                                  }}
                                  onClose={() => setProjectSearchValue("")}
                                >
                                  <Popover.Target>
                                    <ActionIcon
                                      variant="transparent"
                                      size="sm"
                                      color="grey"
                                      style={{ marginLeft: 10 }}
                                      onClick={() => {
                                        setOpened((o) => !o);
                                        setEditingIndex(index);
                                        setSelectedProject(
                                          item.PROJECT_ID || "none"
                                        );
                                      }}
                                    >
                                      <IconEdit size={18} stroke={1} />
                                    </ActionIcon>
                                  </Popover.Target>
                                  <Popover.Dropdown>
                                    <TextInput
                                      placeholder="Search or create project"
                                      value={projectSearchValue}
                                      onChange={(event) =>
                                        setProjectSearchValue(
                                          event.currentTarget.value
                                        )
                                      }
                                    />
                                    <ScrollArea style={{ maxHeight: 250 }}>
                                      {filteredProjects.map((project, i) => (
                                        <div
                                          key={i}
                                          onClick={() => {
                                            setSelectedProject(project._id);
                                          }}
                                          className="mt-2"
                                          style={{
                                            padding: 5,
                                            cursor: "pointer",
                                            backgroundColor:
                                              project._id === selectedProject
                                                ? "#f5f5f5"
                                                : "transparent",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          {project.NAME}
                                        </div>
                                      ))}
                                    </ScrollArea>
                                    <Button
                                      fullWidth
                                      color="blue"
                                      mt="sm"
                                      onClick={() =>
                                        handleProjectChange(
                                          item._id,
                                          selectedProject
                                        )
                                      }
                                    >
                                      OK
                                    </Button>
                                  </Popover.Dropdown>
                                </Popover>
                              )}
                            </div>
                          </td>
                          <td>
                            <Menu position="bottom-end">
                              <Menu.Target>
                                <Button variant="subtle" color="gray">
                                  <IconDots size={18} />
                                </Button>
                              </Menu.Target>
                              <Menu.Dropdown>
                                <Menu.Item
                                  leftSection={
                                    <IconFolderOpen size={18} stroke={1} />
                                  }
                                >
                                  <Link
                                    to={`/writer/${item._id}`}
                                    state={{
                                      artifactName: item.NAME,
                                      projectId: item.PROJECT_ID,
                                      projectName:
                                        projects.find(
                                          (project) =>
                                            project._id === item.PROJECT_ID
                                        )?.NAME || "N/A",
                                      backPath: "/writer",
                                      pathName: "Writer",
                                    }}
                                  >
                                    Open
                                  </Link>
                                </Menu.Item>

                                <Menu.Item
                                  leftSection={
                                    <IconTrash size={18} stroke={1} />
                                  }
                                  color="red"
                                  onClick={() => {
                                    handleDeleteDocument(item._id);
                                  }}
                                >
                                  Delete
                                </Menu.Item>
                              </Menu.Dropdown>
                            </Menu>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {filter === "all" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <Pagination
                        value={activePage}
                        onChange={setActivePage}
                        total={Math.ceil(data.length / 12)}
                      />
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </motion.div>
      </Container>
      <SelectTemplateModal
        opened={selectTemplateModalOpened}
        onClose={() => setSelectTemplateModalOpened(false)}
        refreshDocuments={fetchData}
      />
      <CreateBlankModal
        opened={createBlankModalOpened}
        onClose={() => setCreateBlankModalOpened(false)}
        refreshDocuments={fetchData}
      />
    </div>
  );
};

export default AiWritingPage;
