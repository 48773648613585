const Initialstate = {
  projectDetails: []
};

export const ProjectDetailsReducer = (state = Initialstate, action) => {
  switch (action.type) {
  case 'PROJECT_DETAILS':
    return {
      projectDetails: action.payload
    };
  default:
    return state;
  }
};
