import React from 'react';
import Popover from '@mui/material/Popover';
import { useNavigate } from 'react-router-dom';

import { TbSettings, TbTemplate } from 'react-icons/tb';
import { BiUserCircle, BiCategory, BiListUl } from 'react-icons/bi';
import './AdminSettingsMenu.css';

const AdminSettingsMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  return (
    <>
      <button
        className="btn-header-menu"
        title="Settings"
        onClick={handleClick}
      >
        <TbSettings size={18} />
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <div className="p-2">
          <div className="d-flex flex-row text-center">
            <a className='menuBox pointer' onClick={() => { navigate('/template'); }} >
              <div
                className="d-flex flex-column text-center"
              >
                <TbTemplate className='menuIcons' size={30} />
                <div>Templates</div>
              </div>
            </a>
            <a className='menuBox pointer' onClick={() => { navigate('/predefinedCategory'); }}>
              <div
                className="d-flex flex-column text-center"
              >
                <BiUserCircle className='menuIcons' size={30} />
                <div>Categories</div>
              </div>
            </a>
            <a className='menuBox pointer' onClick={() => { navigate('/userList'); }}>
              <div
                className="d-flex flex-column text-center"
              >
                <BiUserCircle className='menuIcons' size={30} />
                <div>Users</div>
              </div>
            </a>
          </div>
          <div className="d-flex flex-row text-center mt-2">
            <a className='menuBox pointer' onClick={() => { navigate('/managesSupportRequest'); }}>
              <div
                className="d-flex flex-column text-center"
              >
                <BiListUl className='menuIcons' size={30} />
                <div>Manage support ticket</div>
              </div>
            </a>
            <a className='menuBox pointer' onClick={() => { navigate('/supportRequestType'); }}>
              <div
                className="d-flex flex-column text-center"
              >
                <BiCategory className='menuIcons' size={30} />
                <div>Setup support types</div>
              </div>
            </a>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default AdminSettingsMenu;
