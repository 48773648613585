/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";

import { toast } from "react-toastify";
import { RiSortDesc, RiSortAsc } from "react-icons/ri";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Pagination from "../Pagination";
import SearchBar from "../Search";
import { AxiosGetWithToken, AxiosPutWithToken } from "../../Utils/Api";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { keywordDetail, keywordList } from "../../Redux/Action/keywordList";
import deleteIcon from "../../assets/images/delete_icon.svg";
import "./keyword.css";
import { Button, Flex, Modal, TextInput, Text } from "@mantine/core";
import { BsSearch } from "react-icons/bs";
import { IconSearch } from "@tabler/icons-react";

function KeyWord(props) {
  interface keywords {
    _id: string;
    ID: string;
    KEYWORD: string;
    booksCount: number;
    commentsCount: number;
    CREATED_ON: Date;
  }
  interface Header {
    Authorization: string | null;
  }
  interface keywordsList {
    keywordsList: keywords[];
  }
  interface data {
    data: keywordsList;
    message: string;
    status: number;
  }

  interface Project {
    _id: string;
    NAME: string;
  }

  interface keywordListResponse extends AxiosResponse {
    data: data;
  }
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const { projectId } = useParams();

  const dispatch = useDispatch();

  const [isVisibleSearchbox, setIsVisibleSearchbox] = useState<boolean>(false);

  const [keyword, setKeyword] = useState<string>("");

  const [sortState, setSortState] = useState({
    ascCreatedOn: true,
    ascKeyWord: true,
    ascBookCount: true,
    ascCommentCount: true,
  });

  const [index, setIndex] = useState(3);

  const [allKeywords, setAllKeywords] = useState<Array<keywords>>([]);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [showDelateModal, setShowDelateModal] = useState(false);

  const [keywordId, setKeywordId] = useState<string | null>();

  const navigate = useNavigate();

  const [projects, setProjects] = useState<Project[]>([]);

  const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false);

  // get All keywords
  const getKeywords = () => {
    if (headers?.Authorization) {
      AxiosGetWithToken(`/getSearchResultCountCommentCount/${projectId}`, {
        headers,
      }).then((response: keywordListResponse) => {
        setAllKeywords(response.data.data.keywordsList);
        dispatch(keywordList(response.data.data.keywordsList));
      });
    }
  };

  // useEffect to get keywords
  useEffect(() => {
    if (headers?.Authorization) {
      getKeywords();
    }
    sessionStorage.removeItem("keywordId");
  }, []);

  const onSearchClick = (e) => {
    if (keyword && keyword.trim() !== "") {
      projectId &&
        keyword &&
        navigate(`/project/${projectId}/performSearch/${keyword}`);
    } else {
      // toast.error('Please enter a keyword');
      // setKeywordError('Please enter a keyword');
    }
    e.preventDefault();
  };

  // sorting
  const handleSort = (sortIndex) => {
    setPage(0);

    if (sortIndex === 0) {
      setSortState((prevState) => ({
        ...prevState,
        ascKeyWord: index !== sortIndex ? false : !sortState.ascKeyWord,
      }));
      allKeywords.sort((a: keywords, b: keywords) =>
        a.KEYWORD.toLocaleLowerCase() > b.KEYWORD.toLocaleLowerCase() &&
        (index !== sortIndex || sortState.ascKeyWord)
          ? 1
          : -1
      );
      setAllKeywords([...allKeywords]);
    }

    if (sortIndex === 1) {
      setSortState((prevState) => ({
        ...prevState,
        ascBookCount: index !== sortIndex ? false : !sortState.ascBookCount,
      }));
      allKeywords.sort((a: keywords, b: keywords) =>
        a.booksCount > b.booksCount &&
        (index !== sortIndex || sortState.ascBookCount)
          ? 1
          : -1
      );
      setAllKeywords([...allKeywords]);
    }

    if (sortIndex === 2) {
      setSortState((prevState) => ({
        ...prevState,
        ascCommentCount:
          index !== sortIndex ? false : !sortState.ascCommentCount,
      }));
      allKeywords.sort((a: keywords, b: keywords) =>
        a.commentsCount > b.commentsCount &&
        (index !== sortIndex || sortState.ascCommentCount)
          ? 1
          : -1
      );
      setAllKeywords([...allKeywords]);
    }

    if (sortIndex === 3) {
      setSortState((prevState) => ({
        ...prevState,
        ascCreatedOn: index !== sortIndex ? false : !sortState.ascCreatedOn,
      }));
      allKeywords.sort((a: keywords, b: keywords) =>
        a.CREATED_ON > b.CREATED_ON &&
        (index !== sortIndex || sortState.ascCreatedOn)
          ? 1
          : -1
      );
      setAllKeywords([...allKeywords]);
    }

    setIndex(sortIndex);
  };

  const onDelete = () => {
    if (headers?.Authorization) {
      AxiosPutWithToken(
        `/keyword/delete/${keywordId}/${projectId}`,
        {},
        { headers }
      ).then((response) => {
        const updatedList = allKeywords.filter(
          (keyword) => keyword._id !== keywordId
        );

        const renderData = updatedList.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );

        if (renderData.length === 0) {
          setPage(page - 1);
        }

        setAllKeywords(updatedList);

        toast.success(response.data.message);

        setKeywordId(null);
      });
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await AxiosGetWithToken("/getProjectsByUserId", {
        headers,
      });
      setProjects(response.data.data.userProjects);
    } catch (err) {
      console.error("Failed to fetch projects", err);
    }
  };

  const navigateToPerformSearch = () => {
    navigate(`/project/${projectId}/performSearch/${keyword}`, {
      state: {
        keywordName: keyword,
        projectId: projectId,
      },
    });
  };

  const navigateToSearchResult = (keywordID, item) => {
    navigate(`/project/${projectId}/viewSearchResults/${keywordID}`, {
      state: {
        keywordName: item.KEYWORD,
        keywordId: item._id,
        projectId: item.PROJECT_ID,
        projectName:
          projects.find((project) => project._id === item.PROJECT_ID)?.NAME ||
          "N/A",

        backPath: `/project/${item.PROJECT_ID}`,
        pathName: "Project Dashboard",
      },
    });
  };

  return (
    <Fragment>
      <div className="d-flex flex-wrap w-100">
        <h5 className="flex-fill flex-shrink-1">Market Research</h5>
        <div className="d-flex flex-fill flex-grow-2 justify-content-end">
          <Button
            variant="filled"
            color="yellow"
            radius={"xl"}
            className={`float-end `}
            onClick={() => {
              setIsSearchModalOpen(true);
            }}
          >
            <IconSearch className="me-2" size={18} stroke={2} />
            Search
          </Button>
        </div>
      </div>
      <Table
        responsive="sm"
        className="table-general w-100 mt-3 table-bordered"
      >
        <thead className="colHeading">
          <tr>
            <th>
              <span
                className={index === 0 ? "sorting active" : "sorting"}
                onClick={() => {
                  handleSort(0);
                }}
              >
                Keyword
                <button className="btn-sort">
                  {index !== 0 ? (
                    <RiSortDesc />
                  ) : !sortState.ascKeyWord ? (
                    <RiSortDesc />
                  ) : (
                    <RiSortAsc />
                  )}
                </button>
              </span>
            </th>
            <th>
              <span
                className={index === 1 ? "sorting active" : "sorting"}
                onClick={() => {
                  handleSort(1);
                }}
              >
                Books Count
                <button className="btn-sort">
                  {index !== 1 ? (
                    <RiSortDesc />
                  ) : !sortState.ascBookCount ? (
                    <RiSortDesc />
                  ) : (
                    <RiSortAsc />
                  )}
                </button>
              </span>
            </th>
            <th>
              <span
                className={index === 2 ? "sorting active" : "end sorting"}
                onClick={() => {
                  handleSort(2);
                }}
              >
                Comments Count
                <button className="btn-sort">
                  {index !== 2 ? (
                    <RiSortDesc />
                  ) : !sortState.ascCommentCount ? (
                    <RiSortDesc />
                  ) : (
                    <RiSortAsc />
                  )}
                </button>
              </span>
            </th>
            <th>
              <span
                className={index === 3 ? "sorting active" : " sorting"}
                onClick={() => {
                  handleSort(3);
                }}
              >
                {" "}
                Searched On
                <button className="btn-sort">
                  {/* {!sortState.ascCreatedOn && index === 3 ? <RiSortDesc /> : <RiSortAsc />} */}
                  {index !== 3 ? (
                    <RiSortAsc />
                  ) : !sortState.ascCreatedOn ? (
                    <RiSortDesc />
                  ) : (
                    <RiSortAsc />
                  )}
                </button>
              </span>
            </th>
            <th> </th>
          </tr>
        </thead>
        {allKeywords.length === 0 && (
          <tbody>
            <tr>
              <td colSpan={4}>No keywords found</td>
            </tr>
          </tbody>
        )}
        {allKeywords
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((keyword: keywords, index) => (
            <tbody key={index}>
              <tr>
                <td
                  data-label="KEYWORD"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="View search result in keyword"
                >
                  <span>
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(keywordDetail(keyword));
                        navigateToSearchResult(keyword._id, keyword);
                      }}
                    >
                      {keyword.KEYWORD}
                    </a>
                  </span>
                </td>
                <td data-label="BOOKS COUNT">
                  <span>{keyword.booksCount}</span>
                </td>
                <td data-label="COMMENTS COUNT">
                  <span>{keyword.commentsCount}</span>
                </td>
                <td data-label="SEARCHED ON">
                  <span>
                    {moment(keyword.CREATED_ON).format("MMM DD, YYYY hh:mm a")}
                  </span>
                </td>
                <td data-label="">
                  <span
                    className="float-md-end"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Delete"
                  >
                    <button
                      className="btn-del"
                      onClick={() => {
                        setShowDelateModal(true);
                        setKeywordId(keyword._id);
                      }}
                    >
                      <img src={deleteIcon} />
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          ))}
      </Table>

      {allKeywords.length > 5 && (
        <Pagination
          data={allKeywords}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}

      {showDelateModal && (
        <ConfirmationDialog
          confirmButtonText="Delete"
          confirmDialogTitle="Delete keyword?"
          show={showDelateModal}
          setShow={setShowDelateModal}
          onConfirm={onDelete}
          message="Are you sure you want to delete the selected keyword and associated search results?
        Please note that deleting the keyword will not affect any contents in clipboard or artifacts."
          isConfirmButtonVisible={true}
        />
      )}

      {/* Initiate search modal */}
      <Modal
        opened={isSearchModalOpen}
        onClose={() => {
          setIsSearchModalOpen(false);
        }}
        title={
          <Text size="xl" fw={700} c={"#333"}>
            Initiate Scraping
          </Text>
        }
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        centered
        radius={15}
      >
        <TextInput
          label={
            <Text size="lg" mb={3} fw={600} c={"#555"}>
              Keyword
            </Text>
          }
          placeholder="Enter keyword"
          size="md"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Flex mt="md" justify="flex-end" gap="xs" align="center">
          <Button
            radius="md"
            onClick={() => {
              navigateToPerformSearch();
            }}
          >
            New Book Search
          </Button>
        </Flex>
      </Modal>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(KeyWord);
