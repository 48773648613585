import React, { useEffect, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Components/Loader/loader';

const ProtectedRoutes = (props: any) => {
  const { Component } = props;
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      navigate('/login');
    }
  });

  return (
    <>
      <Suspense fallback={<><Loader /></>}>
        <Component />
      </Suspense>
    </>
  );
};

export default ProtectedRoutes;
