const Initialstate = {
  amazonData: [],
  barnsData: [],
  audiableData: [],
  allScrappingData: []
};

export const ScrappingReducer = (state = Initialstate, action) => {
  switch (action.type) {
  case 'ADD_AMAZON':
    return {
      amazonData: action.payload
    };
  case 'ADD_BARNS':
    return {
      barnsData: action.payload
    };
  case 'ADD_AUDIABLE':
    return {
      audiableData: action.payload
    };
  case 'ALL_SCRAPPING_DATA':
    return {
      allScrappingData: action.payload
    };
  default:
    return state;
  }
};
