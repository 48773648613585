/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Text,
  Modal,
  ScrollArea,
  Divider,
  Flex,
  Button,
  Grid,
  TextInput,
  Select,
  Textarea,
  Card,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AxiosGetWithToken, AxiosPostWithToken } from "../../../../Utils/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface Header {
  Authorization: string | null;
}

interface AddSnippetGeneralProps {
  opened: boolean;
  onClose: () => void;
  content: string;
  type: string;
  isBook?: boolean;
  bookURL?: string;
  bookAuthor?: string;
  bookName?: string;
}

interface Bookmark {
  _id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  projectId: string | null;
}

const AddSnippetGeneral: React.FC<AddSnippetGeneralProps> = ({
  opened,
  onClose,
  content: initialContent,
  type: initialType,
  isBook,
  bookAuthor,
  bookName,
  bookURL,
}) => {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [title, setTitle] = useState<string>("");
  const [editBookName, setEditBookName] = useState<string>("");
  const [editBookAuthor, setEditBookAuthor] = useState<string>("");
  const [editBookURL, setEditBookURL] = useState<string>("");
  const [contentType, setContentType] = useState<string>(initialType);
  const [content, setContent] = useState<string>(initialContent);
  const [adding, setIsAdding] = useState<boolean>(false);
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
  const [selectedBookmarkId, setSelectedBookmarkId] = useState<string>("");
  const [newBookmark, setNewBookmark] = useState<string>("");

  const navigate = useNavigate();

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  useEffect(() => {
    if (bookName) {
      setEditBookName(bookName);
    }
    if (bookAuthor) {
      setEditBookAuthor(bookAuthor);
    }
  }, [bookName, bookAuthor]);

  useEffect(() => {
    fetchBookmarks();
  }, []);

  const fetchBookmarks = async () => {
    try {
      const response = await AxiosGetWithToken(`/bookmarks`, { headers });
      setBookmarks(response.data.data.bookmarks);
    } catch (error) {
      toast.error("Failed to fetch bookmarks");
    }
  };

  const handleContentChange = (newContent: string) => {
    setContent(newContent);
  };

  const renderContentInput = () => {
    switch (contentType) {
      case "rich-text":
        return (
          <>
            <Text size="md" mt="md" mb={3} fw={500} c={"#666"}>
              CONTENT
            </Text>
            <ReactQuill
              className="react-quill-container"
              value={content}
              onChange={(value) => handleContentChange(value)}
            />
          </>
        );
      default:
        return (
          <Textarea
            mt="md"
            label={
              <Text size="md" mb={3} fw={500} c={"#666"}>
                CONTENT
              </Text>
            }
            placeholder="Enter content"
            autosize
            minRows={4}
            value={content}
            onChange={(e) => handleContentChange(e.currentTarget.value)}
          />
        );
    }
  };

  const handleAdd = async () => {
    const updatedSnippet = {
      title,
      type: contentType,
      content: content,
      isBook: isBook,
      bookName: editBookName,
      bookAuthor: editBookAuthor,
      bookURL: bookURL,
    };

    setIsAdding(true);

    try {
      if (newBookmark) {
        // const response = await AxiosPostWithToken(`/bookmarks`, { name: newBookmark }, { headers });
        // setSelectedBookmarkId(response.data.data._id);
        const bookmarkData = {
          name: newBookmark,
          snippets: [updatedSnippet], // Filter out empty snippets
        };
        await AxiosPostWithToken("/bookmarks/create", bookmarkData, {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        });
        console.log(newBookmark, updatedSnippet);
      } else {
        await AxiosPostWithToken(
          `/bookmarks/${selectedBookmarkId}/snippets`,
          updatedSnippet,
          { headers }
        );
      }
      toast.success("Snippet added successfully!");
      //refreshSnippets();
      setIsAdding(false);
      onClose();
      navigate(0);
    } catch (err) {
      console.error("Failed to add snippet", err);
      toast.error("Couldn't add the snippet");
      setIsAdding(false);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
        setIsAdding(false);
        setTitle("");
        setContent("");
        setContentType("auto-detect");
        setNewBookmark("");
      }}
      title={
        <Text size="xl" fw={700} c={"#333"}>
          Add a snippet
        </Text>
      }
      size="70%"
      fullScreen={isMobile}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      centered
      radius={15}
    >
      <Divider />
      <ScrollArea style={{ height: isMobile ? "78vh" : "65vh" }}>
        <Card p="md">
          <Grid>
            <Grid.Col span={12}>
              <Select
                label={
                  <Text size="md" mb={3} fw={500} c={"#666"}>
                    SELECT BOOKMARK
                  </Text>
                }
                placeholder="Select bookmark"
                data={[
                  { value: "new", label: "Create new bookmark" },
                  ...bookmarks.map((bookmark) => ({
                    value: bookmark._id,
                    label: bookmark.name,
                  })),
                ]}
                value={selectedBookmarkId}
                // onChange={(id) => setSelectedBookmarkId(id!)}
                onChange={(id) => {
                  if (id === "new") {
                    setSelectedBookmarkId("new");
                    setNewBookmark("");
                  } else {
                    setSelectedBookmarkId(id!);
                    setNewBookmark("");
                  }
                }}
              />
              {selectedBookmarkId === "new" && (
                <TextInput
                  label={
                    <Text size="md" mb={3} fw={500} c={"#666"}>
                      NEW BOOKMARK NAME
                    </Text>
                  }
                  mt="sm"
                  placeholder="Enter new bookmark name"
                  value={newBookmark}
                  onChange={(e) => setNewBookmark(e.currentTarget.value)}
                />
              )}
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label={
                  <Text size="md" mb={3} fw={500} c={"#666"}>
                    SNIPPET TITLE
                  </Text>
                }
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.currentTarget.value)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                label={
                  <Text size="md" mb={3} fw={500} c={"#666"}>
                    CONTENT TYPE
                  </Text>
                }
                placeholder="Select type"
                data={[
                  { value: "auto-detect", label: "Auto Detect" },
                  { value: "plain-text", label: "Plaintext" },
                  { value: "rich-text", label: "Rich Text" },
                ]}
                value={contentType}
                onChange={(type) => setContentType(type!)}
              />
            </Grid.Col>
            {isBook && (
              <>
                <Grid.Col span={6}>
                  <TextInput
                    label={
                      <Text size="md" mb={3} fw={500} c={"#666"}>
                        BOOK NAME
                      </Text>
                    }
                    placeholder="Enter book name"
                    value={editBookName}
                    onChange={(e) => setEditBookName(e.currentTarget.value)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label={
                      <Text size="md" mb={3} fw={500} c={"#666"}>
                        BOOK AUTHOR
                      </Text>
                    }
                    placeholder="Enter book author"
                    value={editBookAuthor}
                    onChange={(e) => setEditBookURL(e.currentTarget.value)}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    label={
                      <Text size="md" mb={3} fw={500} c={"#666"}>
                        BOOK URL
                      </Text>
                    }
                    placeholder="Enter book URL"
                    value={bookURL}
                    onChange={(e) => setEditBookURL(e.currentTarget.value)}
                  />
                </Grid.Col>
              </>
            )}
          </Grid>
          {renderContentInput()}
        </Card>
      </ScrollArea>
      <Divider my="sm" />
      <Flex mt="md" justify="flex-end" gap="xs" align="center">
        <Button
          variant="default"
          radius="md"
          onClick={() => {
            onClose();
            setIsAdding(false);
            setTitle("");
            setContent("");
            setContentType("auto-detect");
            setNewBookmark("");
          }}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          radius="md"
          onClick={handleAdd}
          loading={adding}
          loaderProps={{ type: "dots" }}
        >
          Add
        </Button>
      </Flex>
    </Modal>
  );
};

export default AddSnippetGeneral;
