import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import moment from 'moment';
import Pagination from '../../Components/Pagination';
import { AxiosGetWithToken } from '../../Utils/Api';

interface propsTypes{
  showPaymentDetailsModal:boolean,
  setShowPaymentDetailsModal:Function
  userId:string | undefined,
  setUserID:Function
}
interface Header {Authorization: string | null }

// eslint-disable-next-line no-unused-vars
enum StatusType {
  // eslint-disable-next-line no-unused-vars
  success = 'success',
  // eslint-disable-next-line no-unused-vars
  failed = 'failed',

};

interface paymentDetailsResponse {
  STATUS : string;
  AMOUNT:string;
  CREATED_ON:string;
  SESSION_ID:string

}

export const PaymentDetails = (props: propsTypes) => {
  const { showPaymentDetailsModal, setShowPaymentDetailsModal, userId, setUserID } = props;

  const headers: Header = { Authorization: localStorage.getItem('accessToken') };

  const handleClose = () => { setShowPaymentDetailsModal(!showPaymentDetailsModal); setPaymentDetails([]); setUserID(); };

  const [paymentDetailsOfUser, setPaymentDetails] = useState<Array<paymentDetailsResponse>>([]);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    if (showPaymentDetailsModal && headers?.Authorization && userId) {
      AxiosGetWithToken(`/getPaymentHistoryById/${userId}`, { headers })
        .then((response) => {
          setPaymentDetails(response.data.data.paymentHistoryData);
        });
    }
  }, [userId]);

  return (
    <>

      <Modal show={showPaymentDetailsModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Payment detail</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0'>
          <Table className='table-general w-100 mt-3 table-bordered'>
            <thead>
              <tr>
                <th>Status</th>
                <th>Amount</th>
                <th>Transaction date</th>
                <th>Session userId</th>
              </tr>
            </thead>
            {paymentDetailsOfUser.length === 0 &&
              <tbody>
                <tr>
                  <td colSpan={4}>No payment details found</td>
                </tr>
              </tbody>}
            {paymentDetailsOfUser
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((paymentDetail, index) => (

                <tbody key={index}>
                  <tr>
                    <td className={paymentDetail.STATUS === StatusType.success ? 'text-success' : 'text-danger'}>{paymentDetail.STATUS}</td>
                    <td>{paymentDetail.AMOUNT}</td>
                    <td>{moment(paymentDetail.CREATED_ON).format('MMM DD, YYYY')}</td>
                    <td className=' text-break'>{paymentDetail.SESSION_ID}</td>
                  </tr>

                </tbody>

              ))

            }
          </Table>

          {paymentDetailsOfUser.length > 5 &&
            <Pagination
              data={paymentDetailsOfUser}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage} />
          }
        </Modal.Body>

      </Modal>
    </>
  );
};
