import { combineReducers } from 'redux';
import { ScrappingReducer } from './scrappingSide';
import { LoginReducer } from './login';
import { ProjectListReducer } from './projectList';
import { ProjectDetailsReducer } from './projectDetails';
import { KeywordListReducer } from './keywordList';
import { AuthReducer } from './auth';
import { UserReducer } from './userDetail';
const newRootReducer = combineReducers({
  ScrappingReducer,
  LoginReducer,
  ProjectListReducer,
  ProjectDetailsReducer,
  KeywordListReducer,
  AuthReducer,
  UserReducer
});

export { newRootReducer as allReducer };
