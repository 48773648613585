/* eslint-disable */
import React, { useState, useRef } from "react";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { AxiosPostWithToken } from "../../../Utils/Api";
import Loader from "../../../Components/Loader/loader";
import momLogo from "../../../assets/images/momlogo.png";
import abrahamLogo from "../../../assets/images/abrahamLogo.jpg";
import momHorizontalLogo from "../../../assets/images/momhorilogo.png";
import resendLogo from "../../../assets/images/icon-sendotp.svg";

import "../SignUp/signup.scss";

function ForgotPassword() {
  interface SignUpCredentials {
    email: string;
    password: string;
    confirmPassword: string;
    otp: Number;
    otpandPasswordMessage: string;
  }

  interface forgetUser {
    email: string;
  }
  interface otpCredential {
    otp: Number;
    password: string;
  }
  interface data {
    status: number;
    data: {
      ID: number;
    };
  }
  interface Header {
    Authorization: string | null;
  }
  interface Event {
    preventDefault: Function;
  }
  interface ResponseData extends AxiosResponse {
    data: data;
  }
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const [loading, setLoading] = useState(false);
  const [sendedOtp, setSendedOtp] = useState(false);
  const [timer, setTimer] = useState("0");

  const Ref: {
    current: string | Number | any;
  } = useRef(null);

  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setError,
  } = useForm<SignUpCredentials>();

  const setLoadingFalse = (errorMessage) => {
    toast.error(errorMessage);
    setLoading(false);
  };

  const handleForgotPassword = (userData: forgetUser) => {
    if (userData.email) {
      setLoading(true);

      const bodyData = {
        email: userData.email,
      };

      AxiosPostWithToken("/user/forgot-password", bodyData, { headers })
        .then((response: ResponseData) => {
          if (response.status === 200) {
            setLoading(false);
            setSendedOtp(true);
            toast.success(
              "An OTP has been sent to your registered email. Please verify the OTP and set new password."
            );
          }
        })
        .catch((error: Error | any) => {
          setLoadingFalse(error.response.data.message);
        });
    } else {
      toast.error(
        "Required information is missing for some fields. Please enter the details."
      );
    }
  };

  const onOtpCheck = (data: otpCredential) => {
    if (data.otp && data.password) {
      setLoading(true);

      const bodyData = {
        email: getValues("email"),
        verificationCode: data.otp,
        newPassword: data.password,
      };

      AxiosPostWithToken("/user/forgot-password-confirmNewPassword", bodyData, {
        headers,
      })
        .then((response: ResponseData) => {
          if (response.status === 200) {
            setLoading(false);
            toast.success("Password changed successfully.");
            navigate("/login");
          }
        })
        .catch(() => {
          toast.error("Please enter a valid OTP");
          setLoading(false);
        });
    } else {
      setError("otpandPasswordMessage", {
        type: "custom",
        message: "Please enter otp and password.",
      });
    }
  };

  const getDeadTime = () => {
    const deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  const startTimer = (e) => {
    const currentDate: any = new Date();
    const total = Date.parse(e) - Date.parse(currentDate);
    const seconds: number = Math.floor((total / 1000) % 60);

    if (total >= 0) {
      setTimer(seconds > 9 ? `${seconds}` : "0" + seconds);
    }
  };

  const clearTimer = (e) => {
    setTimer("30");

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);

    Ref.current = id;
  };

  const resendOtp = (bodyData: object) => {
    setLoading(true);

    AxiosPostWithToken("/user/forgot-password", bodyData, { headers })
      .then((response: ResponseData) => {
        if (response.status === 200) {
          setLoading(false);

          clearTimer(getDeadTime());

          toast.success(
            "OTP has been sent successfully to your registered email address."
          );
        }
      })
      .catch((error: Error | any) => {
        setLoadingFalse(error.response.data.message);
      });
  };

  const onResendOtp = (event: Event) => {
    event.preventDefault();

    if (getValues("email")) {
      const bodyData = {
        email: getValues("email"),
      };

      resendOtp(bodyData);
    } else {
      toast.error("Email address not found.");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="login-page bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-xl-2 offset-lg-1">
              <div className="bg-light-md login-block shadow rounded">
                <div className="row">
                  <div className="col-md-5 d-none d-md-block">
                    <div className="form-left h-100 bg-primary brand-block text-white text-center pt-5">
                      <div className="d-flex flex-column">
                        <div className="logo">
                          <img src={momLogo} alt="" />
                        </div>
                        <div className="gp-logo align-items-end py-2">
                          <img src={abrahamLogo} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="login-card bg-white">
                      <div className="mob-logo mt-3">
                        <img src={momHorizontalLogo} />
                      </div>
                      <div className="form-right login-section d-block h-100 py-4 px-4">
                        <form
                          className="row g-3"
                          onSubmit={
                            sendedOtp
                              ? handleSubmit(onOtpCheck)
                              : handleSubmit(handleForgotPassword)
                          }
                        >
                          <h2 className="mt-1 mb-3">Forgot password</h2>
                          <div className="col-12 textfield">
                            <label>
                              Email<span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter email"
                              disabled={sendedOtp}
                              {...register("email", {
                                required: "Email is required.",
                                pattern: {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: "Please enter a valid email.",
                                },
                              })}
                            />
                            <p className="error invalid-feedback d-block h-30">
                              <span className="text-danger">
                                <ErrorMessage errors={errors} name="email" />
                              </span>
                            </p>
                          </div>
                          {sendedOtp && (
                            <>
                              <div className="col-6 col-12 textfield">
                                <label>
                                  OTP<span className="text-danger">*</span>
                                </label>
                                <input
                                  type="Number"
                                  className="form-control"
                                  autoFocus
                                  placeholder="Enter OTP"
                                  maxLength={6}
                                  {...register("otp", {
                                    required: "Please enter a valid OTP.",
                                    minLength: {
                                      value: 6,
                                      message: "Please enter a valid OTP.",
                                    },
                                  })}
                                />
                                <button
                                  type="button"
                                  className="btn-send-otp"
                                  onClick={onResendOtp}
                                  disabled={parseInt(timer) > 0}
                                >
                                  {parseInt(timer) > 0 ? (
                                    `${timer}`
                                  ) : (
                                    <img src={resendLogo} />
                                  )}
                                </button>
                                <p className="error invalid-feedback d-block h-30">
                                  <span className="text-danger">
                                    <ErrorMessage errors={errors} name="otp" />
                                  </span>
                                </p>
                              </div>
                              <div className="col-6 col-12 textfield">
                                <label>
                                  New password
                                  <span
                                    className="text-danger"
                                    {...register("otpandPasswordMessage", {})}
                                  >
                                    *
                                  </span>
                                </label>

                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Enter new password"
                                  {...register("password", {
                                    required: "Please enter a password.",
                                    minLength: {
                                      value: 8,
                                      message:
                                        "Please enter at least 8 character password.",
                                    },
                                    pattern: {
                                      value:
                                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
                                      message:
                                        "Password must have at least one lowercase, uppercase, special, and numeric character.",
                                    },
                                  })}
                                />

                                <p className="error invalid-feedback d-block h-30">
                                  <span className="text-danger">
                                    <ErrorMessage
                                      errors={errors}
                                      name="password"
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="otpandPasswordMessage"
                                    />
                                  </span>
                                </p>
                              </div>
                            </>
                          )}
                          <div className="col-12">
                            <button
                              type="submit"
                              className="btn btn-primary border-0 float-start mt-2 me-3"
                            >
                              Continue
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary border-0 float-start mt-2 me-3"
                              onClick={() => navigate("/login")}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
