import React from 'react';
import { TablePagination } from '@mui/material';
import './pagination.css';

interface Props{
  data:Object[];
  rowsPerPage:number;
  page:number;
  setPage:Function;
  setRowsPerPage: Function;
}

export default function Pagination (props:Props) {
  const { data, rowsPerPage, page, setPage, setRowsPerPage } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      rowsPerPageOptions={[5]}
      component="div"
      count={data?.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage} />
  );
}
