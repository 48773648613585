import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoutes = (props: any) => {
  const { Component } = props;
  const navigate = useNavigate();
  interface data {
    message?: string;
    status?: number;
    FIRST_NAME: string;
    LAST_NAME: string;
    TYPE?: string;
  }

  const userDetail = useSelector((state: {
    UserReducer: { userDetail: data }
  }) => state.UserReducer.userDetail);

  const token = localStorage.getItem('accessToken');

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  });

  useEffect(() => {
    if (token && (userDetail && userDetail.TYPE && userDetail.TYPE !== 'admin')) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('idToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('email');
      window.location.reload();
    }
  }, [userDetail]);

  return (
    <>
      <Component />
    </>
  );
};

export default ProtectedRoutes;
