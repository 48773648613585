import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import { AxiosPostWithToken } from '../../Utils/Api';
import Loader from '../Loader/loader';

interface Props {
  show: boolean;
  setShow: Function;
}

export default function ChangePassword (props: Props) {
  interface changePasswordData {
    oldPassword: string;
    newPassword: string;
    PasswordMessage: string;
    OldNewPasswordRequired: string
  }
  interface Header {
    Authorization: string | null
  }

  interface data {
    status: number,
    data: {
      ID: number
    }
  }

  interface ResponseData extends AxiosResponse {
    data: data;
  }

  const { show, setShow } = props;

  const [loading, setLoading] = useState(false);

  const { register, formState: { errors }, handleSubmit, setError } = useForm< changePasswordData>();
  const headers: Header = { Authorization: localStorage.getItem('accessToken') };

  const getIdToken: string | null = localStorage.getItem('idToken');
  const getRefreshToken: string | null = localStorage.getItem('refreshToken');

  // show and close the popup.
  function handleClose () {
    setShow(!show);
  }

  // on Change password call function
  const onChangePassword = (passwordData: changePasswordData) => {
    if (passwordData?.newPassword && passwordData?.oldPassword) {
      setLoading(true);
      if (passwordData.newPassword !== passwordData.oldPassword) {
        if (headers?.Authorization) {
          AxiosPostWithToken('/user/change-password', {
            oldPassword: passwordData.oldPassword,
            newPassword: passwordData.newPassword,
            idToken: (typeof getIdToken === 'string') && getIdToken.slice(1, -1),
            refreshToken: (typeof getRefreshToken === 'string') && getRefreshToken.slice(1, -1)
          }, { headers })
            .then((response: ResponseData) => {
              if (response.status === 200) {
                setLoading(false);
                handleClose();
                toast.success('Password updated successfully');
              }
            })
            .catch((error: Error | any) => {
              // toast.error(error.response.data.message);
              setError('newPassword', { type: 'custom', message: error.response.data.message });
              setLoading(false);
            });
        }
      } else {
        setLoading(false);
        setError('PasswordMessage', { type: 'custom', message: 'Old and new password should be different.' });
      }
    } else {
      setError('PasswordMessage', { type: 'custom', message: 'Old and new password required.' });
    }
  };

  return (
    <>
      {loading && (<Loader />)}
      <Container fluid>
        <Row>
          <Col >
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Header closeButton className='px-4'>
                <Modal.Title >
                  Change password
                </Modal.Title>
              </Modal.Header>

              <Modal.Body className='px-4'>
                <Form onSubmit={handleSubmit(onChangePassword)}>
                  <Col>
                    <Form.Group className="mb-4" controlId="First_name"
                      {...register('PasswordMessage', {
                      })}
                      {...register('OldNewPasswordRequired', {
                      })}
                    >
                      <div className='col-12 textfield'>
                        <label>Current password<span className='text-danger'>*</span></label>

                        <input type='password' className='form-control' placeholder='Enter current password' {...register('oldPassword', {
                          required: 'Current password is required.',
                          minLength: {
                            value: 8,
                            message: 'Please enter at least 8 character password.'
                          },
                          pattern: {
                            value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
                            message: 'Password must have at least one lowercase, uppercase, special, and numeric character.'
                          }
                        })}/>

                        <p className='error invalid-feedback d-block h-40'>
                          <span className='text-danger'>
                            <ErrorMessage errors={errors} name="oldPassword" />
                          </span>
                        </p>
                      </div>
                      <div className='col-12 textfield mt-5'>
                        <label>New password<span className='text-danger'>*</span></label>

                        <input type='password' className='form-control' placeholder='Enter new password' {...register('newPassword', {
                          required: 'New password is required.',
                          minLength: {
                            value: 8,
                            message: 'Please enter at least 8 character password.'
                          },
                          pattern: {
                            value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
                            message: 'Password must have at least one lowercase, uppercase, special, and numeric character.'
                          }
                        })}/>

                        <p className='error invalid-feedback d-block h-40'>
                          <span className='text-danger'>
                            <ErrorMessage errors={errors} name="newPassword" />
                            <ErrorMessage errors={errors} name="PasswordMessage" />
                            <ErrorMessage errors={errors} name="OldNewPasswordRequired" />
                          </span>
                        </p>
                      </div>
                    </Form.Group>
                  </Col>

                  <Button type='submit' className='px-3 mt-4 momBotTextButton' variant="primary"> Update </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
}
