/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { setProjectList } from "../../Redux/Action/projectList";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  ActionIcon,
  Button,
  Center,
  Group,
  Popover,
  SegmentedControl,
  TextInput,
  Text,
  Checkbox,
  Divider,
  Table,
  ScrollArea,
  Menu,
  Pagination,
  Loader,
  Modal,
  Flex,
} from "@mantine/core";
import {
  IconAlertCircle,
  IconAlertTriangle,
  IconArchive,
  IconArrowLeft,
  IconChecks,
  IconCirclePlus,
  IconDots,
  IconEdit,
  IconFilter,
  IconFolderOpen,
  IconHistory,
  IconPlayerPause,
  IconProgress,
  IconStack2,
  IconTemplate,
  IconTrash,
} from "@tabler/icons-react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import Joyride, { Step } from "react-joyride";
import { db } from "../../config/firebaseConfig";
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  updateDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import ProjectList from "../Home/projectList";
import CreateNewProject from "../Home/CreateNewProject";
import ConfirmationDialog from "../../Components/ConfirmationDialog/ConfirmationDialog";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
import { AxiosGetWithToken, AxiosPutWithToken } from "../../Utils/Api";
import { format } from "date-fns";
import "./newprojects.scss";

interface Header {
  Authorization: string | null;
}

interface Data {
  CLIENT_NAME(CLIENT_NAME: string, arg1: string): unknown;
  NAME: string;
  START_ON: string;
  DUE_ON: string;
  MODIFIED_ON: string;
  ID: Number;
  _id: string;
  STATUS: string;
}

interface userProjects {
  userProjects: Data[];
}

interface data {
  data: userProjects;
  message: string;
  status: number;
}

interface projectListResponse extends AxiosResponse {
  data: data;
}

interface userData {
  PLAN_ID: number;
  TYPE: string;
}

function Projects() {
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [projectId, setProjectId] = useState<string | null>(null);
  const [showDelateModal, setShowDelateModal] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isCreated, setIsCreated] = useState("");
  const [data, setData] = useState<Data[]>([]);
  const [list, setList] = useState<Data[]>([]);
  const [projectlist, setAllProjects] = useState<Data[]>([]);
  const [filter, setFilter] = useState<string>("in progress");
  const [opened, setOpened] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [activePage, setActivePage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const [projectSearchValue, setProjectSearchValue] = useState<string>("");

  const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const userDetail = useSelector(
    (state: { UserReducer: { userDetail: userData } }) =>
      state.UserReducer.userDetail
  );

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.7 },
  };

  const setLoadingFalse = (projectList: Data[]) => {
    setLoading(false);
    setList(projectList);
    dispatch(setProjectList(projectList));
  };

  useEffect(() => {
    if (headers?.Authorization) {
      setLoading(true);
      AxiosGetWithToken("/getProjectsByUserId", { headers }).then(
        (response: projectListResponse) => {
          setLoading(false);
          setLoadingFalse(response?.data?.data?.userProjects);
        }
      );
    }
  }, [headers?.Authorization]);

  useEffect(() => {
    if (isCreated && headers?.Authorization) {
      setLoading(true);
      AxiosGetWithToken("/getProjectsByUserId", { headers }).then(
        (response: projectListResponse) => {
          setLoading(false);
          setLoadingFalse(response?.data?.data?.userProjects);
        }
      );
    }
  }, [isCreated, headers?.Authorization]);

  const handleSearch = useCallback(() => {
    if (searchValue.trim() === "") {
      setData(list);
    } else {
      const filtered = list.filter((project) =>
        project.NAME.toLowerCase().includes(searchValue.toLowerCase())
      );
      setData(filtered);
    }
  }, [searchValue, list]);

  useEffect(() => {
    handleSearch();
  }, [searchValue, handleSearch]);

  const handleFilter = useCallback(() => {
    let filteredList = list;
    if (filter !== "") {
      filteredList = list.filter((project) => project.STATUS === filter);
    }
    setData(filteredList);
  }, [filter, list]);

  useEffect(() => {
    handleFilter();
  }, [filter, handleFilter]);

  const fetchUserproject = useCallback(() => {
    if (headers?.Authorization) {
      setLoading(true);
      AxiosGetWithToken("/getProjectsByUserId", { headers }).then(
        (response: projectListResponse) => {
          setLoading(false);
          setList(response?.data?.data?.userProjects);
          setAllProjects(response?.data?.data?.userProjects);
          dispatch(setProjectList(response?.data?.data?.userProjects));
        }
      );
    }
  }, [headers?.Authorization, dispatch]);

  const deleteProject = useCallback(
    (ID: string) => {
      if (headers?.Authorization && projectId) {
        setIsDeleting(true);
        AxiosPutWithToken(`/project/delete/${projectId}`, {}, { headers })
          .then((response: projectListResponse) => {
            setIsDeleting(false);
            setDeleteModalOpened(false);
            setLoading(false);
            fetchUserproject();
            toast.success(response.data.message);
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    },
    [headers?.Authorization, projectId, fetchUserproject]
  );

  const formatDate = (date: string) =>
    format(new Date(date), "MMM dd, yyyy KK:mm a");

  return (
    <div>
      <div className="first-step">
        <CommonHeader />
      </div>

      <div className="bg-light-yellow py-4 full-width">
        <Container>
          <Row>
            <Col md={12} className="mx-auto">
              <Button
                leftSection={<IconArrowLeft size={15} />}
                variant="light"
                color="gray"
                radius="xl"
                size="xs"
                onClick={() => navigate("/home")}
              >
                Home
              </Button>
              <h2 className="text-left mt-2">Projects</h2>

              <Row className="actions mt-2">
                <Col xs={4} md={2} className="text-center ">
                  <Card
                    className="bookmarks-option-card bookmarks-select-option d-flex flex-column justify-content-center align-items-center"
                    onClick={() => setShow(true)}
                  >
                    <IconCirclePlus
                      size={40}
                      stroke={1}
                      className="icon mb-2"
                    />
                    <Card.Text className="label">Create project</Card.Text>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <motion.div {...fadeInUp}>
          <Row className="filters my-4">
            <Col md={12} className="mx-auto d-flex align-items-center">
              <Col xs={3} md={1} className="text-center p-0">
                <SegmentedControl
                  value={filter}
                  onChange={setFilter}
                  data={[
                    {
                      value: "in progress",
                      label: (
                        <Center style={{ gap: 5 }}>
                          <IconProgress size={18} stroke={1} />
                          <span>In progress</span>
                        </Center>
                      ),
                    },
                    {
                      value: "paused",
                      label: (
                        <Center style={{ gap: 5 }}>
                          <IconPlayerPause size={18} stroke={1} />
                          <span>Paused</span>
                        </Center>
                      ),
                    },
                    {
                      value: "completed",
                      label: (
                        <Center style={{ gap: 5 }}>
                          <IconChecks size={18} stroke={1} />
                          <span>Completed</span>
                        </Center>
                      ),
                    },
                    {
                      value: "archived",
                      label: (
                        <Center style={{ gap: 5 }}>
                          <IconArchive size={18} stroke={1} />
                          <span>Archived</span>
                        </Center>
                      ),
                    },
                  ]}
                  className="filter-segmented-control"
                />
              </Col>
              <Col
                xs={filter === "all" ? 5 : 5}
                md={filter === "all" ? 7 : 7}
              />

              <Col xs={4} md={4} className="p-0">
                <Group>
                  <TextInput
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.currentTarget.value)}
                    rightSection={
                      filter === "all" && (
                        <Popover
                          width={250}
                          trapFocus
                          position="bottom"
                          withArrow
                          shadow="md"
                        >
                          <Popover.Target>
                            <ActionIcon
                              variant="subtle"
                              color="gray"
                              aria-label="Settings"
                              onClick={() => setOpened((o) => !o)}
                            >
                              <IconFilter size={18} stroke={1} />
                            </ActionIcon>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <Text fw={500} className="mb-2">
                              Sort by
                            </Text>
                            <Checkbox.Group
                              value={selectedFilters}
                              onChange={setSelectedFilters}
                            >
                              <Checkbox
                                className="mb-2"
                                value="alphabetical"
                                label="Alphabetical Order"
                              />
                              <Checkbox
                                className="mb-2"
                                value="recent"
                                label="Most Recent"
                              />
                              <Checkbox
                                className="mb-2"
                                value="created"
                                label="Created Date"
                              />
                              <Checkbox
                                className="mb-3"
                                value="updated"
                                label="Updated Date"
                              />
                            </Checkbox.Group>
                            <Divider className="mb-1" variant="dashed" />
                            <Text fw={500} className="mb-2">
                              Advanced
                            </Text>
                            <Button
                              fullWidth
                              color="blue"
                              mt="sm"
                              onClick={() => setOpened(false)}
                            >
                              Apply Filters
                            </Button>
                          </Popover.Dropdown>
                        </Popover>
                      )
                    }
                    style={{ width: "100%" }}
                  />
                </Group>
              </Col>
            </Col>
          </Row>

          <Row className="content">
            <Col md={12}>
              {loading ? (
                <Center style={{ height: "400px" }}>
                  <Loader color="blue" type="bars" />
                </Center>
              ) : error ? (
                <Center
                  style={{
                    flexDirection: "column",
                    height: "400px",
                    gap: "10px",
                  }}
                >
                  <IconAlertTriangle size={48} stroke={1.5} color="#f03e3e" />
                  <Text color="red" size="lg">
                    {error}
                  </Text>
                </Center>
              ) : data.length === 0 ? (
                <Center
                  style={{
                    flexDirection: "column",
                    height: "400px",
                    gap: "10px",
                  }}
                >
                  <IconAlertCircle size={48} stroke={1.5} color="#adb5bd" />
                  <Text color="dimmed" size="lg">
                    No data found
                  </Text>
                </Center>
              ) : (
                <>
                  <Table
                    className="reset-table"
                    highlightOnHover
                    withRowBorders
                    striped
                  >
                    <thead>
                      <tr>
                        <th>Project Name</th>
                        <th>Start date</th>
                        <th>Due date</th>
                        <th>Modified on</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        .slice((activePage - 1) * 10, activePage * 10)
                        .map((item, index) => (
                          <tr key={index}>
                            <td style={{ color: "#0a58ca", cursor: "pointer" }}>
                              <Link
                                to={`/project/${item._id}`}
                                state={{
                                  artifactName: item.NAME,
                                  projectId: item.ID,
                                  projName: item.NAME,
                                }}
                              >
                                {item.NAME}
                              </Link>
                            </td>
                            <td>{formatDate(item.START_ON)}</td>
                            <td>{formatDate(item.DUE_ON)}</td>
                            <td>{formatDate(item.MODIFIED_ON)}</td>
                            <td>
                              <Menu position="bottom-end">
                                <Menu.Target>
                                  <Button variant="subtle" color="gray">
                                    <IconDots size={18} />
                                  </Button>
                                </Menu.Target>
                                <Menu.Dropdown>
                                  <Menu.Item
                                    leftSection={
                                      <IconFolderOpen size={18} stroke={1} />
                                    }
                                  >
                                    <Link
                                      to={`/project/${item._id}`}
                                      state={{
                                        artifactName: item.NAME,
                                        projectId: item.ID,
                                        projectName: item.NAME,
                                      }}
                                    >
                                      Open
                                    </Link>
                                  </Menu.Item>
                                  <Menu.Item
                                    leftSection={
                                      <IconTrash size={18} stroke={1} />
                                    }
                                    color="red"
                                    onClick={() => {
                                      //setProjectId(item.ID);
                                      setDeleteModalOpened(true);
                                    }}
                                  >
                                    Delete
                                  </Menu.Item>
                                </Menu.Dropdown>
                              </Menu>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {data.length > 10 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <Pagination
                        value={activePage}
                        onChange={setActivePage}
                        total={Math.ceil(data.length / 10)}
                      />
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </motion.div>
      </Container>

      {show && (
        <CreateNewProject
          show={show}
          setShow={setShow}
          setIsCreated={setIsCreated}
        />
      )}

      {showDelateModal && (
        <ConfirmationDialog
          confirmButtonText="Delete"
          confirmDialogTitle="Delete project?"
          show={showDelateModal}
          setShow={setShowDelateModal}
          onConfirm={() => deleteProject(projectId!)}
          message="Are you sure you want to delete the selected project?"
          isConfirmButtonVisible={true}
        />
      )}

      {/* Delete document modal */}
      <Modal
        opened={deleteModalOpened}
        onClose={() => {
          setDeleteModalOpened(false);
          setIsDeleting(false);
        }}
        title={
          <Text size="xl" fw={700} c={"#333"}>
            Confirm Delete
          </Text>
        }
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        centered
        radius={15}
      >
        <Text size="sm">
          Are you sure you want to delete this project? This action cannot be
          undone.
        </Text>
        <Flex mt="md" justify="flex-end" gap="xs" align="center">
          <Button
            variant="default"
            radius="md"
            onClick={() => {
              setDeleteModalOpened(false);
              setIsDeleting(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="red"
            radius="md"
            loading={isDeleting}
            loaderProps={{ type: "dots" }}
            onClick={() => {
              deleteProject(projectId!);
            }}
          >
            Confirm
          </Button>
        </Flex>
      </Modal>
    </div>
  );
}

function mapStateToProps(state: any) {
  return state;
}

export default connect(mapStateToProps)(Projects);
