import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { userData as setUserDetails } from '../../Redux/Action/user';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import { AxiosGetWithToken, AxiosPutWithToken } from '../../Utils/Api';
import Loader from '../Loader/loader';

interface Props{
  show ?:boolean;
  setShow: Function;
  setName ?: any;
}

export default function MyProfile (props: Props) {
  interface changePasswordData {
    firstName: string;
    lastName: string;
  }
  interface userDetailsData {
    EMAIL: string;
    FIRST_NAME: string;
    LAST_NAME: string;
  }
  interface Header {
    Authorization: string | null
  }

  interface data {
    data: userDetailsData
  }

  interface ResponseData extends AxiosResponse {
    data: data;
  }

  const { show, setShow, setName } = props;

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<userDetailsData>({
    EMAIL: '',
    FIRST_NAME: '',
    LAST_NAME: ''
  });

  const { register, formState: { errors }, handleSubmit, setValue } = useForm<changePasswordData>();
  const headers: Header = { Authorization: localStorage.getItem('accessToken') };

  const userDetail: userDetailsData = useSelector((state: {
    UserReducer: { userDetail: { FIRST_NAME: string, LAST_NAME: string, EMAIL: string; } }
  }) => state.UserReducer.userDetail);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetail) {
      setValue('firstName', userDetail.FIRST_NAME);
      setValue('lastName', userDetail.LAST_NAME);
      setUserData(userDetail);
    } else {
      if (headers?.Authorization) {
        AxiosGetWithToken('/user', { headers })
          .then((response: ResponseData) => {
            dispatch(setUserDetails(response.data.data));
            setValue('firstName', response.data.data.FIRST_NAME);
            setValue('lastName', response.data.data.LAST_NAME);
            setUserData(response.data.data);
          });
      }
    }
  }, []);

  // show and close the popup.
  function handleClose () {
    setShow(!show);
  }

  // on Change password call function
  const onChangeProfile = (userData) => {
    if (userData?.firstName && userData?.lastName) {
      if (headers?.Authorization) {
        setLoading(true);
        AxiosPutWithToken('/users/update', {
          FIRST_NAME: userData?.firstName,
          LAST_NAME: userData?.lastName
        }, { headers })
          .then((response: ResponseData) => {
            AxiosGetWithToken('/user', { headers })
              .then((response: ResponseData) => {
                setLoading(false);
                dispatch(setUserDetails(response.data.data));
                setValue('firstName', response.data.data.FIRST_NAME);
                setValue('lastName', response.data.data.LAST_NAME);
                setUserData(response.data.data);
                setName(`${response.data.data.FIRST_NAME} ${response.data.data.LAST_NAME}`);
                toast.success('User detail updated successfully');
              }).catch((error) => {
                setLoading(false);
                toast.success(error.response.data.message);
              });
            setShow(false);
          }).catch((error) => {
            setLoading(false);
            toast.success(error.response.data.message);
          });
      }
    }
  };

  return (
    <>
      {loading && (<Loader />)}
      <Container fluid>
        <Row>
          <Col >
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Header closeButton className='px-4'>
                <Modal.Title >
                  My account
                </Modal.Title>
              </Modal.Header>

              <Modal.Body className='px-4'>
                <Form onSubmit={handleSubmit(onChangeProfile)}>
                  <Col>
                    <Form.Group className="mb-4" controlId="First_name">
                      <div className='col-12 textfield'>
                        <label>First name<span className='text-danger'>*</span></label>
                        <input type='text' className='form-control' autoFocus placeholder='Enter first name'
                          maxLength={25}
                          {...register('firstName', {
                            required: 'First name is required.',
                            pattern: {
                              value: /^[a-z\d\-_\s]+$/i,
                              message: 'Special characters are not allowed.'
                            },
                            minLength: {
                              value: 1,
                              message: 'Please enter a value.'
                            }
                          // maxLength: {
                          //   value: 25,
                          //   message: 'Please enter a value lesser than 26 characters.'
                          // }
                          })}/>
                        <p className='error invalid-feedback d-block h-30'>
                          <span className='text-danger'>
                            <ErrorMessage errors={errors} name="firstName" />
                          </span>
                        </p>
                      </div>
                      <div className='col-12 textfield mt-2'>
                        <label>Last name<span className='text-danger'>*</span></label>

                        <input type='text' className='form-control' placeholder='Enter last name'
                          maxLength={25}
                          {...register('lastName', {
                            required: 'Last name is required.',
                            pattern: {
                              value: /^[a-z\d\-_\s]+$/i,
                              message: 'Special characters are not allowed.'
                            },
                            minLength: {
                              value: 1,
                              message: 'Please enter a value.'
                            }
                          // maxLength: {
                          //   value: 25,
                          //   message: 'Please enter a value lesser than 26 characters.'
                          // }
                          })}/>
                        <p className='error invalid-feedback d-block h-30'>
                          <span className='text-danger'>
                            <ErrorMessage errors={errors} name="lastName" />
                          </span>
                        </p>
                      </div>
                      <div className='col-12 textfield'>
                        <label>Email<span className='text-danger'>*</span></label>
                        <input className='form-control' placeholder='Enter email' value={userData?.EMAIL} disabled />
                      </div>
                    </Form.Group>
                  </Col>

                  <Button type='submit' className='px-3 mt-2 momBotTextButton' variant="primary"> Update </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
}
