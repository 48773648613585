const verifyEmailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const verifyEmail = (email:any) => {
  if (verifyEmailPattern.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const dateFormat = 'MM/DD/YYYY';
