/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Modal,
  Text,
  Divider,
  TextInput,
  Flex,
  Button,
  Select,
} from "@mantine/core";
import { AxiosGetWithToken, AxiosPutWithToken } from "../../../../Utils/Api";
import { toast } from "react-toastify";

interface Header {
  Authorization: string | null;
}

interface Project {
  _id: string;
  NAME: string;
}

interface EditBookmarkModalProps {
  opened: boolean;
  onClose: () => void;
  refreshArtifact: () => void;
  artifactName: string;
  artifactId: string;
  projectId: string;
  projectName: string;
}

const EditDocumentModal: React.FC<EditBookmarkModalProps> = ({
  opened,
  onClose,
  refreshArtifact,
  artifactName,
  artifactId,
  projectId,
  projectName,
}) => {
  const [editingArtifactName, setEditingArtifactName] = useState<string>("");
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<string | null>("none");
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  useEffect(() => {
    if (artifactName) {
      setEditingArtifactName(artifactName);
    }
    if (projectId) {
      setSelectedProject(projectId);
    }

    fetchProjects();
  }, [artifactName, projectId]);

  const fetchProjects = async () => {
    try {
      const response = await AxiosGetWithToken("/getProjectsByUserId", {
        headers,
      });
      setProjects(response.data.data.userProjects);
    } catch (err) {
      console.error("Failed to fetch projects", err);
    }
  };

  const handleBookmarkUpdate = async () => {
    setIsEditing(true);
    try {
      const updatedProjectId =
        selectedProject === "none" ? null : selectedProject;
      await AxiosPutWithToken(
        `/artifact/update/${artifactId}`,
        { PROJECT_ID: updatedProjectId, NAME: editingArtifactName },
        { headers }
      );
      setIsEditing(false);
      toast.success("Document updated successfully!");
      refreshArtifact();
      onClose();
    } catch (err) {
      console.error("Failed to update document", err);
      setIsEditing(false);
      toast.error("Failed to update document");
    }
  };

  const projectOptions = [
    { value: "none", label: "None" },
    ...projects.map((project) => ({ value: project._id, label: project.NAME })),
  ];

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Text size="xl" fw={700} c={"#333"}>
          Edit Document
        </Text>
      }
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      size="lg"
      centered
      radius={15}
    >
      <Divider />
      <TextInput
        mt={"xs"}
        label={
          <Text size="md" mb={3} fw={600} c={"#555"}>
            Document Name
          </Text>
        }
        placeholder="Enter bookmark name"
        size="md"
        value={editingArtifactName}
        onChange={(e) => setEditingArtifactName(e.currentTarget.value)}
      />
      <Select
        mt="md"
        label={
          <Text size="md" mb={3} fw={600} c={"#555"}>
            Project
          </Text>
        }
        placeholder="Select project"
        searchable
        value={selectedProject}
        onChange={setSelectedProject}
        data={projectOptions}
      />
      <Divider my="sm" />
      <Flex mt="md" justify="flex-end" gap="xs" align="center">
        <Button variant="default" radius="md" onClick={onClose}>
          Cancel
        </Button>
        <Button
          radius="md"
          loading={isEditing}
          loaderProps={{ type: "dots" }}
          onClick={handleBookmarkUpdate}
        >
          Confirm
        </Button>
      </Flex>
    </Modal>
  );
};

export default EditDocumentModal;
