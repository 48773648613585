const Initialstate = {
  users: []
};

export const LoginReducer = (state = Initialstate, action) => {
  switch (action.type) {
  case 'LOGIN_USERS':
    return {
      users: action.payload
    };
  default:
    return state;
  }
};
