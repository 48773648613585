import React from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';

interface Props{
  show:boolean;
  confirmDialogTitle:string;
  setShow:Function;
  message:string;
  onConfirm:Function;
  confirmButtonText:string;
  isConfirmButtonVisible?:boolean;
}

export default function ConfirmationDialog (props:Props) {
  const { show, setShow, message, onConfirm, confirmButtonText, confirmDialogTitle, isConfirmButtonVisible } = props;

  const handleClose = () => {
    setShow(!show);
  };

  const onAgree = () => {
    setShow(!show);
    onConfirm();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Container>

        <Modal.Header closeButton>
          <Modal.Title>{confirmDialogTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body >
          <Row>
            {isConfirmButtonVisible && message.includes('?')
              ? <Col>
                <p>{message.split('?')[0] + '?'}</p>
                <p>{message.split('?')[1] }</p>
              </Col>
              : <Col>
                <p>{message}</p>
              </Col>
            }
          </Row>
          <Row >

            <Col>
              {isConfirmButtonVisible &&
              <Button className='me-2'variant="primary" onClick={onAgree}>{confirmButtonText}</Button>
              }
              <Button variant="primary" onClick={handleClose}>Cancel</Button>
            </Col>

          </Row>

        </Modal.Body>

      </Container>

    </Modal>
  );
}
