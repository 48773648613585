export const keywordList = (data) => {
  return {
    type: 'KEYWORD_LIST',
    payload: data
  };
};

export const keywordDetail = (data) => {
  return {
    type: 'KEYWORD_DETAIL',
    payload: data
  };
};
