/* eslint-disable */
import React, { Fragment } from "react";
import Routes from "./Routes/Routes";
import "../src/assets/css/App.css";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";

function App() {
  return (
    <Fragment>
      <MantineProvider withGlobalClasses>
        <Routes />
      </MantineProvider>
    </Fragment>
  );
}

export default App;
