import React, { Fragment } from 'react';
import './loader.css';
const loader = (props:any) => {
  const { showLoaderMessage } = props;
  return (
    <Fragment>
      <div className="overlay">
        <div className="overlay__inner">
          <div className="overlay__content">
            <span className="spinner">
            </span>
            { showLoaderMessage &&
                <h5 className='mt-3 message'> We are looking for your books...</h5>
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default loader;
