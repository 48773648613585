/* eslint-disable */

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Table,
  Button,
  Form,
} from "react-bootstrap";
import { AxiosResponse } from "axios";
import { AxiosGetWithToken } from "../../Utils/Api";
import { useNavigate, useParams } from "react-router-dom";
import "../../assets/css/modal.css";
import "../../assets/css/style.scss";
import "../../assets/css/selectTemplate.css";
interface propsType {
  show: boolean;
  setShow: Function;
}

interface Header {
  Authorization: string | null;
}

interface templateResponse extends AxiosResponse {
  ID?: number;
  TITLE?: string;
  DESCRIPTION?: string;
  CREATED_BY?: number;
}

interface template {
  ID: number;
  TITLE: string;
  DESCRIPTION: string;
  CREATED_BY: number;
}

export default function SelectTemp(props: propsType) {
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const { projectId } = useParams();

  const { show, setShow } = props;

  const navigate = useNavigate();

  const [checkedValue, setCheckedValue] = useState<string>();

  const [templates, setTemplates] = useState<Array<template>>([]);

  const [flag, setFlag] = useState(false);

  const handleClose = () => {
    setShow(!show);
    setFlag(false);
    setCheckedValue("");
  };

  const handleChange = (e) => {
    const target = e.target;
    if (target.checked) {
      setCheckedValue(target.value);
      setFlag(false);
    }
  };

  const addArtifact = (e) => {
    e.preventDefault();
    if (!checkedValue || checkedValue === null || checkedValue === "") {
      setFlag(true);
    }
    if (checkedValue) {
      navigate(`/addArtifact/${projectId}/${checkedValue}`);
    }
  };

  useEffect(() => {
    // Get templates
    if (headers?.Authorization && show) {
      AxiosGetWithToken("/template/findAll", { headers }).then(
        (response: templateResponse) => {
          setTemplates(response.data.data.data);
        }
      );
    }
  }, [show]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton className="px-3 border-0">
              <Modal.Title>Select template to create a document</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-3">
              <div className="maxheight-350 mb-3">
                <Table className="table table-bordered border">
                  <thead className="table table-bordered border">
                    <tr>
                      <th></th>
                      <th className="py-3">Title</th>
                      <th className="py-3">Description</th>
                    </tr>
                  </thead>

                  {templates.length === 0 && (
                    <tbody>
                      <tr>
                        <td colSpan={2}>No templates Found</td>
                      </tr>
                    </tbody>
                  )}
                  {templates.map((template: any, index) => (
                    <tbody key={index}>
                      <tr>
                        <td data-label="">
                          <span>
                            <Form.Check
                              type="radio"
                              value={template._id}
                              className="mx-2"
                              name="template"
                              onChange={handleChange}
                            />
                          </span>
                        </td>
                        <td data-label="TITLE">
                          <span>{template.TITLE}</span>
                        </td>
                        <td data-label="DESCRIPTION">
                          <span className="templateDescription">
                            {template.DESCRIPTION}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </div>
              <Row className="mb-3">
                <div className="col-6 textfield">
                  <p className="error invalid-feedback d-block h-50">
                    <span className="text-danger">
                      {flag && "Please select a template"}
                    </span>
                  </p>
                </div>
              </Row>
              <Button
                onClick={addArtifact}
                variant="primary"
                className="momBotTextButton"
              >
                {" "}
                Create
              </Button>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}
