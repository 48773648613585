const InitialState = {
  registerUserData: {},
  verifyUser: false
};

export const AuthReducer = (state = InitialState, action) => {
  switch (action.type) {
  case 'REGISTER_USER':
    return {
      registerUserData: action.payload
    };
  case 'CLEAR_VERIFY_USER':
    return {
      verifyUser: action.payload
    };
  case 'VERIFY_USER':
    return {
      verifyUser: action.payload
    };
  default:
    return state;
  }
};
