/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Text,
  Card,
  TextInput,
  Accordion,
  AccordionControlProps,
  ActionIcon,
  Loader,
  Center,
  Menu,
  Flex,
} from "@mantine/core";
import { AxiosGetWithToken } from "../../Utils/Api";
import { toast } from "react-toastify";
import {
  IconCirclePlus,
  IconCopy,
  IconDots,
  IconFolderOpen,
  IconPencil,
  IconTrash,
} from "@tabler/icons-react";
import ManageSnippetModal from "./Modal/ManageSnippetModal";
import AddSnippetModal from "./Modal/AddSnippet";
import CreateBookmarkModal from "./Modal/CreateNewBookmark";

interface Header {
  Authorization: string | null;
}

interface Snippet {
  title: string;
  type: string;
  content: string;
  isBook: boolean | undefined;
  bookName?: string | undefined;
  bookAuthor: string | undefined;
  bookURL: string | undefined;
  _id: string;
  createdAt: string;
  updatedAt: string;
}

interface Bookmark {
  _id: string;
  name: string;
  userId: string;
  snippets: Snippet[];
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  projectId: string | null;
}

const BookmarkAccordion: React.FC = () => {
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
  const [filteredBookmarks, setFilteredBookmarks] = useState<Bookmark[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSnippet, setSelectedSnippet] = useState<Snippet | null>(null);
  const [isManageModalOpen, setIsManageModalOpen] = useState<boolean>(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [selectedBookmarkId, setSelectedBookmarkId] = useState<string>("");
  const [openCreateBookMark, setOpenCreateBookmark] = useState<boolean>(false);

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  useEffect(() => {
    fetchBookmarks();
  }, []);

  const fetchBookmarks = async () => {
    setLoading(true);
    try {
      const response = await AxiosGetWithToken("/bookmarksWithSnippets", {
        headers,
      });
      setBookmarks(response.data.data.bookmarks);
      setFilteredBookmarks(response.data.data.bookmarks);
    } catch (error) {
      toast.error("Failed to fetch bookmarks");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setSearchQuery(value);
    const filtered = bookmarks.filter((bookmark) =>
      bookmark.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredBookmarks(filtered);
  };

  const handleCopyToClipboard = (content: string) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = content;
    document.body.appendChild(tempElement);

    const range = document.createRange();
    range.selectNodeContents(tempElement);

    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);

    document.execCommand("copy");

    selection?.removeAllRanges();
    document.body.removeChild(tempElement);

    toast.success("Copied to clipboard");
  };

  const AccordionControl = ({
    value,
    ...props
  }: AccordionControlProps & { value: string }) => {
    const handleAddSnippet = () => {
      setSelectedBookmarkId(value);
      console.log(value);
      setIsAddModalOpen(true);
    };
    return (
      <Center>
        <Accordion.Control {...props} />
        <Menu position="bottom-end">
          <Menu.Target>
            <ActionIcon size="lg" variant="subtle" color="gray">
              <IconDots size="1rem" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              leftSection={<IconCirclePlus size={18} stroke={1.5} />}
              onClick={handleAddSnippet}
            >
              Add a snippet
            </Menu.Item>
            <Menu.Item leftSection={<IconPencil size={18} stroke={1.5} />}>
              Edit
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Center>
    );
  };

  const openManageSnippetModal = (snippet: Snippet, bookmarkId: string) => {
    setSelectedSnippet(snippet);
    setSelectedBookmarkId(bookmarkId);
    setIsManageModalOpen(true);
  };

  const closeManageSnippetModal = () => {
    setIsManageModalOpen(false);
    setSelectedSnippet(null);
    setSelectedBookmarkId("");
  };

  const closeAddSnippetModal = () => {
    setIsAddModalOpen(false);
    setSelectedBookmarkId("");
  };

  return (
    <div>
      <div className="d-flex flex-row">
        <TextInput
          my={10}
          placeholder="Search bookmarks"
          value={searchQuery}
          onChange={handleSearch}
          className="flex-grow-1 flex-fill"
        />
        <div className="ms-2">
          <ActionIcon
            variant="default"
            size={"lg"}
            h={"36"}
            title="Create new bookmark"
            color="gray"
            onClick={() => {
              setOpenCreateBookmark(true);
            }}
            mt={10}
          >
            <IconCirclePlus size={18} stroke={1.5} />
          </ActionIcon>
        </div>
      </div>
      {loading ? (
        <Center style={{ height: "200px" }}>
          <Loader color="blue" type="bars" />
        </Center>
      ) : (
        <Accordion chevronPosition="left" variant="contained">
          {filteredBookmarks.map((bookmark) => (
            <Accordion.Item value={bookmark._id} key={bookmark._id}>
              <AccordionControl value={bookmark._id}>
                {bookmark.name}
              </AccordionControl>
              <Accordion.Panel>
                {bookmark.snippets.map((snippet) => (
                  <Card
                    key={snippet._id}
                    withBorder
                    p={"xs"}
                    mt="sm"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Flex
                      gap="md"
                      justify="flex-start"
                      align="center"
                      direction="row"
                      wrap="nowrap"
                      style={{ width: "100%" }}
                    >
                      <Text
                        fw={500}
                        size="md"
                        style={{
                          maxWidth: "80%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {snippet.title}
                      </Text>
                      <Menu position="bottom-end">
                        <Menu.Target>
                          <ActionIcon variant="subtle" color="gray" ml={"auto"}>
                            <IconDots size={18} />
                          </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                          <Menu.Item
                            leftSection={<IconCopy size={18} stroke={1.5} />}
                            onClick={() =>
                              handleCopyToClipboard(snippet.content)
                            }
                          >
                            Copy
                          </Menu.Item>
                          <Menu.Item
                            leftSection={
                              <IconFolderOpen size={18} stroke={1.5} />
                            }
                            onClick={() =>
                              openManageSnippetModal(snippet, bookmark._id)
                            }
                          >
                            Open
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                    </Flex>
                  </Card>
                ))}
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
      {selectedSnippet && (
        <ManageSnippetModal
          opened={isManageModalOpen}
          onClose={closeManageSnippetModal}
          refreshSnippets={fetchBookmarks}
          snippet={selectedSnippet}
          bookmarkId={selectedBookmarkId}
        />
      )}
      <AddSnippetModal
        opened={isAddModalOpen}
        onClose={closeAddSnippetModal}
        refreshSnippets={fetchBookmarks}
        bookmarkId={selectedBookmarkId}
      />
      <CreateBookmarkModal
        opened={openCreateBookMark}
        onClose={() => setOpenCreateBookmark(false)}
        refreshBookmarks={fetchBookmarks}
      />
    </div>
  );
};

export default BookmarkAccordion;
