/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  TextInput,
  Select,
  Textarea,
  ActionIcon,
  Card,
  Grid,
  Text,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles for react-quill

interface SnippetEntryProps {
  index: number;
  entry: {
    title: string;
    type: string;
    content: string;
  };
  onUpdate: (index: number, entry: any) => void;
  onDelete: (index: number) => void;
}

const detectContentType = (content: string) => {
  // Rich text detection (basic HTML tags)
  const richTextPattern = /<\/?[a-z][\s\S]*>/i;
  if (richTextPattern.test(content)) {
    return "rich-text";
  }

  // Default to plain text
  return "plain-text";
};

const SnippetEntry: React.FC<SnippetEntryProps> = ({
  index,
  entry,
  onUpdate,
  onDelete,
}) => {
  const [content, setContent] = useState<string>(entry.content);
  const [contentType, setContentType] = useState<string>(entry.type);

  const handleContentChange = (newContent: string) => {
    setContent(newContent);
  };

  useEffect(() => {
    if (entry.type === "auto-detect") {
      const detectedType = detectContentType(content);
      setContentType(detectedType);
      onUpdate(index, { ...entry, type: detectedType, content });
    } else {
      setContentType(entry.type);
      onUpdate(index, { ...entry, content });
    }
  }, [content, entry.type]);

  const renderContentInput = () => {
    switch (contentType) {
      case "rich-text":
        return (
          <>
            {
              <Text size="md" mt="md" mb={3} fw={500} c={"#666"}>
                CONTENT
              </Text>
            }
            <ReactQuill
              className="react-quill-container"
              value={content}
              onChange={(value) => handleContentChange(value)}
            />
          </>
        );
      default:
        return (
          <Textarea
            mt="md"
            label={
              <Text size="md" mb={3} fw={500} c={"#666"}>
                CONTENT
              </Text>
            }
            placeholder="Enter content"
            autosize
            minRows={4}
            value={content}
            onChange={(e) => handleContentChange(e.target.value)}
          />
        );
    }
  };

  return (
    <Card
      p="md"
      radius="md"
      mt={10}
      style={{ backgroundColor: "#f1f3f5", display: "flex" }}
    >
      <Grid>
        <Grid.Col span={6}>
          <TextInput
            label={
              <Text size="md" mb={3} fw={500} c={"#666"}>
                SNIPPET TITLE
              </Text>
            }
            placeholder="Enter title"
            value={entry.title}
            onChange={(e) =>
              onUpdate(index, { ...entry, title: e.currentTarget.value })
            }
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label={
              <Text size="md" mb={3} fw={500} c={"#666"}>
                CONTENT TYPE
              </Text>
            }
            placeholder="Select type"
            data={[
              { value: "auto-detect", label: "Auto Detect" },
              { value: "plain-text", label: "Plaintext" },
              { value: "rich-text", label: "Rich Text" },
            ]}
            value={entry.type}
            onChange={(type) => {
              onUpdate(index, { ...entry, type });
            }}
          />
        </Grid.Col>
      </Grid>
      {renderContentInput()}
      {index == 0 ? null : (
        <ActionIcon
          variant="transparent"
          color="red"
          size="lg"
          mt="md"
          onClick={() => onDelete(index)}
          style={{ marginLeft: "auto" }}
        >
          <IconTrash size={24} stroke={1.5} />
        </ActionIcon>
      )}
    </Card>
  );
};

export default SnippetEntry;
