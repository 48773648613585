/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Text,
  Modal,
  ScrollArea,
  Divider,
  Flex,
  Button,
  Grid,
  TextInput,
  Select,
  Textarea,
  Card,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AxiosPostWithToken } from "../../../../Utils/Api";
import { toast } from "react-toastify";

interface Header {
  Authorization: string | null;
}

interface AddSnippetModalProps {
  opened: boolean;
  onClose: () => void;
  refreshSnippets: () => void;
  bookmarkId: string;
}

const detectContentType = (content: string) => {
  // Rich text detection (basic HTML tags)
  const richTextPattern = /<\/?[a-z][\s\S]*>/i;
  if (richTextPattern.test(content)) {
    return "rich-text";
  }

  // Default to plain text
  return "plain-text";
};

const AddSnippetModal: React.FC<AddSnippetModalProps> = ({
  opened,
  onClose,
  refreshSnippets,
  bookmarkId,
}) => {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [title, setTitle] = useState<string>("");
  const [contentType, setContentType] = useState<string>("auto-detect");
  const [content, setContent] = useState<string>("");
  const [adding, setIsAdding] = useState<boolean>(false);

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const handleContentChange = (newContent: string) => {
    setContent(newContent);
  };

  useEffect(() => {
    if (contentType === "auto-detect") {
      const detectedType = detectContentType(content);
      setContentType(detectedType);
    } else {
      setContentType(contentType);
    }
  }, [content, contentType]);

  const renderContentInput = () => {
    switch (contentType) {
      case "rich-text":
        return (
          <>
            {
              <Text size="md" mt="md" mb={3} fw={500} c={"#666"}>
                CONTENT
              </Text>
            }
            <ReactQuill
              className="react-quill-container"
              value={content}
              onChange={(value) => handleContentChange(value)}
            />
          </>
        );
      default:
        return (
          <Textarea
            mt="md"
            label={
              <Text size="md" mb={3} fw={500} c={"#666"}>
                CONTENT
              </Text>
            }
            placeholder="Enter content"
            autosize
            minRows={4}
            value={content}
            onChange={(e) => handleContentChange(e.currentTarget.value)}
          />
        );
    }
  };

  const handleAdd = async () => {
    const updatedSnippet = {
      title,
      type: contentType,
      content,
    };

    setIsAdding(true);

    try {
      await AxiosPostWithToken(
        `/bookmarks/${bookmarkId}/snippets`,
        updatedSnippet,
        { headers }
      );
      toast.success("Snippet added successfully!");
      refreshSnippets();
      setIsAdding(false);
      onClose();
    } catch (err) {
      console.error("Failed to add snippet", err);
      toast.error("Couldn't add the snippet");
      setIsAdding(false);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
        setIsAdding(false);
        setTitle("");
        setContent("");
        setContentType("auto-detect");
      }}
      title={
        <Text size="xl" fw={700} c={"#333"}>
          Add a snippet
        </Text>
      }
      size="70%"
      fullScreen={isMobile}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      centered
      radius={15}
    >
      <Divider />
      <ScrollArea style={{ height: isMobile ? "78vh" : "65vh" }}>
        <Card p="md">
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label={
                  <Text size="md" mb={3} fw={500} c={"#666"}>
                    SNIPPET TITLE
                  </Text>
                }
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.currentTarget.value)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                label={
                  <Text size="md" mb={3} fw={500} c={"#666"}>
                    CONTENT TYPE
                  </Text>
                }
                placeholder="Select type"
                data={[
                  { value: "auto-detect", label: "Auto Detect" },
                  { value: "plain-text", label: "Plaintext" },
                  { value: "rich-text", label: "Rich Text" },
                ]}
                value={contentType}
                onChange={(type) => {
                  setContentType(type!);
                }}
              />
            </Grid.Col>
          </Grid>
          {renderContentInput()}
        </Card>
      </ScrollArea>
      <Divider my="sm" />
      <Flex mt="md" justify="flex-end" gap="xs" align="center">
        <Button
          variant="default"
          radius="md"
          onClick={() => {
            onClose();
            setIsAdding(false);
            setTitle("");
            setContent("");
            setContentType("auto-detect");
          }}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          radius="md"
          onClick={() => {
            handleAdd();
          }}
          loading={adding}
          loaderProps={{ type: "dots" }}
        >
          Add
        </Button>
      </Flex>
    </Modal>
  );
};

export default AddSnippetModal;
