/* eslint-disable */
/* eslint-disable padded-blocks */
/* eslint-disable indent */
/* eslint-disable camelcase */
import axios, { AxiosRequestHeaders, AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";

// interface Header extends AxiosRequestHeaders {
//   Authorization: string | number | boolean ;
// }

interface responseType {
  status: Number;
}

interface errorType {
  response: responseType;
}

const GetAuthorization = (): string | number | boolean => {
  const token = localStorage.getItem("accessToken")?.toString();
  if (token === undefined) {
    return "";
  } else {
    return token;
  }
};

export const headers: AxiosRequestHeaders = {
  Authorization: GetAuthorization(),
};

const logOut = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("idToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("email");
  window.location.reload();
};

const responseHandle = (response: responseType, resolve, reject) => {
  if (response.status === 200) {
    resolve(response);
  } else {
    reject(response);
  }
};

const errorHandle = (error: errorType, reject) => {
  if (error.response.status === 401) {
    logOut();
  } else {
    reject(error);
  }
};

const downloadProcessPost = (
  reqUrl: string,
  body: object,
  onDownloadProgressFunction: any,
  header: any
) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}${reqUrl}`,
      method: "post",
      data: body,
      headers: { Authorization: header.Authorization },
      onDownloadProgress: (progressEvent: any) => {
        onDownloadProgressFunction(progressEvent);
      },
    })
      .then(function (response) {
        responseHandle(response, resolve, reject);
      })
      .catch(function (error) {
        errorHandle(error, reject);
      });
  });
};

const AxiosPostWithToken = async (
  reqUrl: string,
  body: object,
  header: any
): Promise<AxiosResponse> => {
  if (header.headers.Authorization) {
    const decodedHeader: {
      exp: number;
    } = await jwt_decode(header.headers.Authorization);

    if (new Date(decodedHeader.exp * 1000) < new Date()) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/user/refresh-token`, {
            email: localStorage.getItem("email"),
            refreshToken: localStorage.getItem("refreshToken")?.slice(1, -1),
          })
          .then(function (response: AxiosResponse) {
            localStorage.setItem(
              "accessToken",
              JSON.stringify(response.data.data.accessToken)
            );
            axios
              .post(`${process.env.REACT_APP_BASE_URL}${reqUrl}`, body, {
                headers: {
                  Authorization: JSON.stringify(response.data.data.accessToken),
                },
              })
              .then(function (response: AxiosResponse) {
                responseHandle(response, resolve, reject);
              })
              .catch(function (error) {
                errorHandle(error, reject);
              });
          })
          .catch(function (error) {
            errorHandle(error, reject);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}${reqUrl}`, body, header)
          .then(function (response: AxiosResponse) {
            responseHandle(response, resolve, reject);
          })
          .catch(function (error) {
            errorHandle(error, reject);
          });
      });
    }
  } else {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}${reqUrl}`, body, header)
        .then(function (response: AxiosResponse) {
          responseHandle(response, resolve, reject);
        })
        .catch(function (error) {
          errorHandle(error, reject);
        });
    });
  }
};

const AxiosPutWithToken = async (
  reqUrl: string,
  body: object,
  header: any
): Promise<AxiosResponse> => {
  if (header.headers.Authorization) {
    const decodedHeader: {
      exp: number;
    } = await jwt_decode(header.headers.Authorization);

    if (new Date(decodedHeader.exp * 1000) < new Date()) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/user/refresh-token`, {
            email: localStorage.getItem("email"),
            refreshToken: localStorage.getItem("refreshToken")?.slice(1, -1),
          })
          .then(function (response: AxiosResponse) {
            localStorage.setItem(
              "accessToken",
              JSON.stringify(response.data.data.accessToken)
            );
            axios
              .put(`${process.env.REACT_APP_BASE_URL}${reqUrl}`, body, {
                headers: {
                  Authorization: JSON.stringify(response.data.data.accessToken),
                },
              })
              .then(function (response) {
                responseHandle(response, resolve, reject);
              })
              .catch(function (error) {
                errorHandle(error, reject);
              });
          })
          .catch(function (error) {
            errorHandle(error, reject);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.REACT_APP_BASE_URL}${reqUrl}`, body, header)
          .then(function (response) {
            responseHandle(response, resolve, reject);
          })
          .catch(function (error) {
            errorHandle(error, reject);
          });
      });
    }
  } else {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}${reqUrl}`, body, header)
        .then(function (response) {
          responseHandle(response, resolve, reject);
        })
        .catch(function (error) {
          errorHandle(error, reject);
        });
    });
  }
};

const AxiosGetWithToken = async (
  reqUrl: string,
  header: any
): Promise<AxiosResponse> => {
  if (header.headers && header.headers.Authorization) {
    const decodedHeader: {
      exp: number;
    } = await jwt_decode(header.headers.Authorization);

    if (new Date(decodedHeader.exp * 1000) < new Date()) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/user/refresh-token`, {
            email: localStorage.getItem("email"),
            refreshToken: localStorage.getItem("refreshToken")?.slice(1, -1),
          })
          .then(function (response: AxiosResponse) {
            localStorage.setItem(
              "accessToken",
              JSON.stringify(response.data.data.accessToken)
            );
            axios
              .get(`${process.env.REACT_APP_BASE_URL}${reqUrl}`, {
                headers: {
                  Authorization: JSON.stringify(response.data.data.accessToken),
                },
              })
              .then(function (response: AxiosResponse) {
                responseHandle(response, resolve, reject);
              })
              .catch(function (error) {
                errorHandle(error, reject);
              });
          })
          .catch(function (error) {
            errorHandle(error, reject);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}${reqUrl}`, header)
          .then(function (response: AxiosResponse) {
            responseHandle(response, resolve, reject);
          })
          .catch(function (error) {
            errorHandle(error, reject);
          });
      });
    }
  } else {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}${reqUrl}`, header)
        .then(function (response: AxiosResponse) {
          responseHandle(response, resolve, reject);
        })
        .catch(function (error) {
          errorHandle(error, reject);
        });
    });
  }
};

const AxiosDeleteWithToken = async (
  reqUrl: string,
  header: any
): Promise<AxiosResponse> => {
  if (header.headers.Authorization) {
    const decodedHeader: {
      exp: number;
    } = await jwt_decode(header.headers.Authorization);

    if (new Date(decodedHeader.exp * 1000) < new Date()) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/user/refresh-token`, {
            email: localStorage.getItem("email"),
            refreshToken: localStorage.getItem("refreshToken")?.slice(1, -1),
          })
          .then(function (response: AxiosResponse) {
            localStorage.setItem(
              "accessToken",
              JSON.stringify(response.data.data.accessToken)
            );
            axios
              .delete(`${process.env.REACT_APP_BASE_URL}${reqUrl}`, {
                headers: {
                  Authorization: JSON.stringify(response.data.data.accessToken),
                },
              })
              .then(function (response: AxiosResponse) {
                responseHandle(response, resolve, reject);
              })
              .catch(function (error) {
                errorHandle(error, reject);
              });
          })
          .catch(function (error) {
            errorHandle(error, reject);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}${reqUrl}`, header)
          .then(function (response: AxiosResponse) {
            responseHandle(response, resolve, reject);
          })
          .catch(function (error) {
            errorHandle(error, reject);
          });
      });
    }
  } else {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_BASE_URL}${reqUrl}`, header)
        .then(function (response: AxiosResponse) {
          responseHandle(response, resolve, reject);
        })
        .catch(function (error) {
          errorHandle(error, reject);
        });
    });
  }
};

export {
  AxiosPostWithToken,
  AxiosGetWithToken,
  AxiosPutWithToken,
  AxiosDeleteWithToken,
  downloadProcessPost,
};
