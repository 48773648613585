/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  Text,
  Loader,
  Center,
  Button,
  Divider,
  Badge,
  Modal,
  Flex,
  TextInput,
  Group,
  SegmentedControl,
  Popover,
  Checkbox,
  ActionIcon,
} from "@mantine/core";
import { AxiosGetWithToken, AxiosPutWithToken } from "../../Utils/Api";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
import {
  IconArrowLeft,
  IconCirclePlus,
  IconArrowsMaximize,
  IconTrashX,
  IconFilter,
  IconHistory,
  IconStack2,
  IconAlertCircle,
  IconAlertTriangle,
  IconPencil,
} from "@tabler/icons-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ManageSnippetModal from "../../Components/Bookmarks/Modal/ManageSnippetModal";
import { motion } from "framer-motion";
import { format } from "date-fns";
import AddSnippetModal from "../../Components/Bookmarks/Modal/AddSnippet";
import { toast } from "react-toastify";
import EditBookmarkModal from "../../Components/Bookmarks/Modal/EditBookmark";

interface Header {
  Authorization: string | null;
}

interface Snippet {
  _id: string;
  title: string;
  type: string;
  content: string;
  createdAt?: string;
  updatedAt?: string;
}

interface Bookmark {
  _id: string;
  name: string;
  projectId: string;
  createdAt: string;
  updatedAt: string;
}

interface LocationState {
  bookmarkName: string;
  projectId: string | null;
  projectName: string | null;
}

const BookmarkContent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { bookmarkName, projectId, projectName } =
    location.state as LocationState;
  const [fetchedBookmark, setFetchedBookmark] = useState<Bookmark>();
  const [snippets, setSnippets] = useState<Snippet[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [addModalOpened, setAddModalOpened] = useState<boolean>(false);
  const [selectedSnippet, setSelectedSnippet] = useState<Snippet | null>(null);
  const [filter, setFilter] = useState<string>("recents");
  const [opened, setOpened] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([
    "alphabetical",
  ]);
  const [sortOrder, setSortOrder] = useState<string[]>(["alphabetical"]);
  const navigate = useNavigate();
  const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [editBookmarkModalOpened, setEditBookmarkModalOpened] =
    useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [editingBookmarkName, setEditingBookmarkName] = useState<string>("");
  const [editingProjectname, setEditingProjectName] = useState<string>("");

  useEffect(() => {
    if (bookmarkName) {
      setEditingBookmarkName(bookmarkName);
    }
  }, [bookmarkName]);

  const formatDate = (date: string) =>
    format(new Date(date), "MMM dd, yyyy KK:mm a");

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  useEffect(() => {
    fetchSnippets();
  }, [id]);

  const fetchBookmark = async () => {
    try {
      const response = await AxiosGetWithToken(`/bookmarks/${id}`, {
        headers,
      });
      setFetchedBookmark(response.data.data);
    } catch (err) {
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const fetchSnippets = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await AxiosGetWithToken(`/bookmarks/${id}/snippets`, {
        headers,
      });
      setSnippets(response.data.data.snippets);
    } catch (err) {
      setError("Failed to fetch snippets");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteBookmark = async () => {
    setIsDeleting(true);
    try {
      await AxiosPutWithToken(`/bookmarks/delete/${id}`, {}, { headers });
      toast.success("Bookmark deleted successfully!");
      setIsDeleting(false);
      navigate("/bookmarks");
    } catch (err) {
      console.error("Failed to delete bookmark", err);
      toast.error("Failed to delete bookmark");
      setIsDeleting(false);
    }
  };

  const openModal = (snippet: Snippet) => {
    setSelectedSnippet(snippet);
    setModalOpened(true);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  const handleFilterApply = () => {
    setSortOrder(selectedFilters);
    setOpened(false);
  };

  useEffect(() => {
    if (filter === "all") {
      let sortedData = [...snippets];
      if (sortOrder.includes("alphabetical")) {
        sortedData = sortedData.sort((a, b) => a.title.localeCompare(b.title));
      }
      if (sortOrder.includes("recent")) {
        sortedData = sortedData.sort(
          (a, b) =>
            new Date(b.updatedAt ?? "").getTime() -
            new Date(a.updatedAt ?? "").getTime()
        );
      }
      if (sortOrder.includes("created")) {
        sortedData = sortedData.sort(
          (a, b) =>
            new Date(b.createdAt ?? "").getTime() -
            new Date(a.createdAt ?? "").getTime()
        );
      }
      if (sortOrder.includes("updated")) {
        sortedData = sortedData.sort(
          (a, b) =>
            new Date(b.updatedAt ?? "").getTime() -
            new Date(a.updatedAt ?? "").getTime()
        );
      }
      setSnippets(sortedData);
    }
  }, [sortOrder, filter, snippets]);

  const filteredData = snippets.filter(
    (item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
      (item.content &&
        item.content.toLowerCase().includes(searchValue.toLowerCase()))
  );

  const displayedData =
    filter === "recents"
      ? filteredData
          .sort(
            (a, b) =>
              new Date(b.updatedAt ?? "").getTime() -
              new Date(a.updatedAt ?? "").getTime()
          )
          .slice(0, 5)
      : filteredData;

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.7 },
  };

  return (
    <div className="bookmark-content-page">
      <CommonHeader />
      <div className="bg-light-yellow py-4 full-width">
        <Container>
          <Row>
            <Col md={12} className="mx-auto">
              <Button
                leftSection={<IconArrowLeft size={15} />}
                variant="light"
                color="gray"
                radius="xl"
                size="xs"
                onClick={() => navigate("/bookmarks")}
              >
                Bookmarks
              </Button>
              {fetchedBookmark?.name ? (
                <h2 className="text-left mt-2">{fetchedBookmark?.name}</h2>
              ) : (
                <h2 className="text-left mt-2">{bookmarkName}</h2>
              )}
              <Row className="actions mt-2">
                <Col xs={4} md={2} className="text-center ">
                  <Card
                    className="bookmarks-option-card bookmarks-select-option d-flex flex-column justify-content-center align-items-center"
                    onClick={() => setAddModalOpened(true)}
                  >
                    <IconCirclePlus
                      size={40}
                      stroke={1}
                      className="icon mb-2"
                    />
                    <Card.Text className="label">Add a snippet</Card.Text>
                  </Card>
                </Col>
                <Col xs={4} md={2} className="text-center ">
                  <Card
                    className="bookmarks-option-card bookmarks-select-option d-flex flex-column justify-content-center align-items-center"
                    onClick={() => setEditBookmarkModalOpened(true)}
                  >
                    <IconPencil size={40} stroke={1} className="icon mb-2" />
                    <Card.Text className="label">Edit bookmark</Card.Text>
                  </Card>
                </Col>
                <Col xs={4} md={2} className="text-center ">
                  <Card
                    className="bookmarks-option-card bookmarks-select-option d-flex flex-column justify-content-center align-items-center"
                    onClick={() => setDeleteModalOpened(true)}
                  >
                    <IconTrashX
                      color="red"
                      size={40}
                      stroke={1}
                      className="icon mb-2"
                    />
                    <Card.Text className="label">Delete bookmark</Card.Text>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <motion.div {...fadeInUp}>
          <Row className="filters my-4">
            <Col md={12} className="mx-auto d-flex align-items-center">
              <Col xs={3} md={1} className="text-center p-0">
                <SegmentedControl
                  value={filter}
                  onChange={setFilter}
                  data={[
                    {
                      value: "recents",
                      label: (
                        <Center style={{ gap: 5 }}>
                          <IconHistory size={18} stroke={1} />
                          <span>Recents</span>
                        </Center>
                      ),
                    },
                    {
                      value: "all",
                      label: (
                        <Center style={{ gap: 5 }}>
                          <IconStack2 size={18} stroke={1} />
                          <span>All</span>
                        </Center>
                      ),
                    },
                  ]}
                  className="filter-segmented-control"
                />
              </Col>
              <Col
                xs={filter === "all" ? 5 : 5}
                md={filter === "all" ? 7 : 7}
              />

              <Col xs={4} md={4} className="p-0">
                <Group>
                  <TextInput
                    placeholder="Search"
                    value={searchValue}
                    onChange={handleSearch}
                    rightSection={
                      filter === "all" && (
                        <Popover
                          width={250}
                          trapFocus
                          position="bottom"
                          withArrow
                          shadow="md"
                        >
                          <Popover.Target>
                            <ActionIcon
                              variant="subtle"
                              color="gray"
                              aria-label="Settings"
                              onClick={() => setOpened((o) => !o)}
                            >
                              <IconFilter size={18} stroke={1} />
                            </ActionIcon>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <Text fw={500} className="mb-2">
                              Sort by
                            </Text>
                            <Checkbox.Group
                              value={selectedFilters}
                              onChange={setSelectedFilters}
                            >
                              <Checkbox
                                className="mb-2"
                                value="alphabetical"
                                label="Alphabetical Order"
                              />
                              <Checkbox
                                className="mb-2"
                                value="recent"
                                label="Most Recent"
                              />
                              <Checkbox
                                className="mb-2"
                                value="created"
                                label="Created Date"
                              />
                              <Checkbox
                                className="mb-3"
                                value="updated"
                                label="Updated Date"
                              />
                            </Checkbox.Group>
                            <Divider className="mb-1" variant="dashed" />
                            <Text fw={500} className="mb-2">
                              Advanced
                            </Text>
                            <Button
                              fullWidth
                              color="blue"
                              mt="sm"
                              onClick={handleFilterApply}
                            >
                              Apply Filters
                            </Button>
                          </Popover.Dropdown>
                        </Popover>
                      )
                    }
                    style={{ width: "100%" }}
                  />
                </Group>
              </Col>
            </Col>
          </Row>
          {loading ? (
            <Center style={{ height: "400px" }}>
              <Loader color="blue" type="bars" />
            </Center>
          ) : error ? (
            <Center
              style={{ flexDirection: "column", height: "400px", gap: "10px" }}
            >
              <IconAlertTriangle size={48} stroke={1.5} color="#f03e3e" />
              <Text color="red" size="lg">
                {error}
              </Text>
            </Center>
          ) : snippets.length === 0 ? (
            <Center
              style={{ flexDirection: "column", height: "400px", gap: "10px" }}
            >
              <IconAlertCircle size={48} stroke={1.5} color="#adb5bd" />
              <Text color="dimmed" size="lg">
                No data found
              </Text>
            </Center>
          ) : (
            <Row className="mt-4">
              {displayedData.map((snippet, index) => (
                <Col md={6} key={index} className="mb-4">
                  <Card
                    style={{
                      height: "300px",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <Card.Body>
                      <Card.Title>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{snippet.title}</span>
                          <Center>
                            <Badge variant="default" size="sm">
                              {snippet.type === "plain-text"
                                ? "Plain Text"
                                : "Rich Text"}
                            </Badge>
                          </Center>
                        </div>
                        <Divider className="mt-3" />
                      </Card.Title>

                      <Card.Text
                        style={{ height: "180px", overflow: "hidden" }}
                      >
                        {snippet.type === "plain-text" ? (
                          snippet.content
                        ) : (
                          <ReactQuill
                            value={snippet.content}
                            readOnly={true}
                            theme="bubble"
                          />
                        )}
                      </Card.Text>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Center>
                          <Badge
                            tt={"none"}
                            variant="light"
                            color="grey"
                            size="md"
                          >
                            {snippet.updatedAt
                              ? `Last Updated: ${formatDate(snippet.updatedAt)}`
                              : `Last Updated: N/A`}
                          </Badge>
                        </Center>
                        <Button
                          variant="subtle"
                          color="blue"
                          size="xs"
                          leftSection={<IconArrowsMaximize size={16} />}
                          onClick={() => openModal(snippet)}
                        >
                          Expand
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </motion.div>
      </Container>
      <AddSnippetModal
        opened={addModalOpened}
        onClose={() => setAddModalOpened(false)}
        refreshSnippets={fetchSnippets}
        bookmarkId={id!}
      />
      <ManageSnippetModal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        refreshSnippets={fetchSnippets}
        snippet={selectedSnippet}
        bookmarkId={id!}
      />
      {/* Delete bookmark modal */}
      <Modal
        opened={deleteModalOpened}
        onClose={() => {
          setDeleteModalOpened(false);
          setIsDeleting(false);
        }}
        title={
          <Text size="xl" fw={700} c={"#333"}>
            Confirm Delete
          </Text>
        }
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        centered
        radius={15}
      >
        <Text size="sm">
          Are you sure you want to delete this bookmark? This action cannot be
          undone.
        </Text>
        <Flex mt="md" justify="flex-end" gap="xs" align="center">
          <Button
            variant="default"
            radius="md"
            onClick={() => {
              setDeleteModalOpened(false);
              setIsDeleting(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="red"
            radius="md"
            loading={isDeleting}
            loaderProps={{ type: "dots" }}
            onClick={() => {
              handleDeleteBookmark();
            }}
          >
            Confirm
          </Button>
        </Flex>
      </Modal>
      {/* Edit bookmark modal */}
      <EditBookmarkModal
        opened={editBookmarkModalOpened}
        onClose={() => setEditBookmarkModalOpened(false)}
        refreshBookmark={fetchBookmark}
        bookmarkName={bookmarkName}
        bookmarkId={id!}
        projectId={projectId!}
        projectName={projectName!}
      />
    </div>
  );
};

export default BookmarkContent;
