/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { FiChevronLeft, FiEdit } from "react-icons/fi";
import { BsDownload } from "react-icons/bs";
import DropDown from "../../Components/DropdownBox/index";
import { AxiosGetWithToken } from "../../Utils/Api";
import SearchBar from "../Search";
import addFileIcon from "../../assets/images/addfile_icon.svg";
import { setProjectDetails } from "../../Redux/Action/projectDetails";
import "./SubHeader.css";

const SubHeader = (props: any) => {
  const {
    sortSearchResult,
    showAdd,
    buttons,
    setShow,
    navigateUrl,
    searchProjectName,
    setSearchProjectName,
    disableDownload,
    setShowDownloadModal,
    brand,
    showSearch,
    showAdd1,
    showStatusButtons,
    supportTicketStatusButtons,
    onClickStatus,
    SelectedStatusButtonIndex,
    showDropDown,
    disableDropdown,
    showBackButton,
  } = props;

  const headers: any = { Authorization: localStorage.getItem("accessToken") };
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState<any>(false);

  const wrapperRef: any = useRef(null);

  const [showSearchButton, setShowSearchButton] = useState(false);
  const [projectName, setProjectName] = useState(
    useSelector((state: any) => state.ProjectDetailsReducer.projectDetails.NAME)
  );

  useEffect(() => {
    if (projectName === undefined) {
      if (headers?.Authorization && projectId !== undefined) {
        AxiosGetWithToken(`/project/${projectId}`, { headers }).then(
          (response: any) => {
            dispatch(setProjectDetails(response.data.data.project));
            setProjectName(response.data.data.project.NAME);
          }
        );
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        className="mb-4 flex-row flex-wrap mt-0 "
        expanded={expanded}
        ref={wrapperRef}
      >
        <Container fluid className="px-4">
          {props.showNavbar ? ( // when true project will display
            <>
              {showBackButton && (
                <Link
                  to={navigateUrl}
                  className="btn btn-light btn-circle btn-back  flex-shrink-1 mx-2"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Click to go back"
                >
                  <FiChevronLeft size={20} />
                </Link>
              )}
              <Navbar.Brand>
                <b>{brand || projectName}</b>
              </Navbar.Brand>
              {showSearch && (
                <SearchBar
                  showSearchButton={showSearchButton}
                  setShowSearchButton={setShowSearchButton}
                  searchText={searchProjectName}
                  setSearchText={setSearchProjectName}
                  setExpanded={setExpanded}
                  // ShowsearchName={true}
                  {...props}
                />
              )}
              {showAdd && (
                <Button
                  type="button"
                  className="btn-circle ms-2 float-end create-project"
                  onClick={(e) => {
                    e.preventDefault();
                    setExpanded(false);
                    setShow(true);
                  }}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Create new project"
                >
                  <img
                    src={addFileIcon}
                    alt="Create new project"
                    title="Create new project"
                  />
                </Button>
              )}

              <Navbar.Collapse
                id="navbarScroll"
                onClick={(e) => e.stopPropagation()}
              >
                <Nav
                  className="me-auto my-2 my-lg-0 navStyle"
                  navbarScroll
                ></Nav>

                <div className="sub-button-group">
                  {buttons?.length > 0 &&
                    buttons.map((radio: any, index: number) => {
                      return (
                        <Button
                          variant={radio.class}
                          name="radio"
                          className="btn-filters mx-2"
                          value={radio.value}
                          key={index}
                          onClick={(e) => {
                            e.preventDefault();
                            radio.selectedField(radio.value);
                            setExpanded(false);
                          }}
                        >
                          {radio.name}
                        </Button>
                      );
                    })}
                </div>

                {showAdd1 && (
                  <Button
                    type="button"
                    className="btn-circle ms-2 float-end"
                    onClick={(e) => {
                      e.preventDefault();
                      setExpanded(false);
                      setShow(true);
                    }}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Create new project"
                  >
                    <img
                      src={addFileIcon}
                      alt="Create new template"
                      title="Create new template"
                    />
                  </Button>
                )}

                {showStatusButtons && (
                  <>
                    {supportTicketStatusButtons.map((statusType, index) => {
                      return (
                        <Button
                          key={index}
                          className="btn-filters mx-2 "
                          variant={
                            SelectedStatusButtonIndex === index
                              ? statusType.selectedVariant
                              : statusType.variant
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            onClickStatus(index);
                          }}
                        >
                          {statusType.name}
                        </Button>
                      );
                    })}
                  </>
                )}
              </Navbar.Collapse>
            </>
          ) : (
            <>
              {/* when false */}
              {/* <div className='d-flex flex-row flex-fill'> */}
              <Link
                to={navigateUrl}
                className="btn btn-light btn-circle btn-back  flex-shrink-1 mx-2"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Click to go back"
              >
                <FiChevronLeft size={20} />
              </Link>
              <Navbar.Brand className="mx-2 nav-band projectName">
                <b>{brand || projectName}</b>
              </Navbar.Brand>
              <Navbar.Collapse id="navbarScroll" className="flex-shrink-1">
                <Nav
                  className="me-auto my-2 my-lg-0 navStyle"
                  navbarScroll
                ></Nav>
                {props.showEdit && (
                  <Button
                    type="button"
                    className="btn btn-primary btn-circle mx-2 "
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Edit project"
                    onClick={() => {
                      setExpanded(false);
                      setShow(true);
                    }}
                  >
                    <FiEdit />
                  </Button>
                )}
                {showDropDown && !disableDropdown && (
                  <>
                    <DropDown sortSearchResult={sortSearchResult} />
                  </>
                )}
                {props.showDownload && (
                  <Button
                    type="button"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Download search results"
                    className="btn btn-primary btn-circle mx-2"
                    disabled={disableDownload}
                    onClick={() => {
                      setExpanded(false);
                      setShowDownloadModal(true);
                    }}
                  >
                    <BsDownload />
                  </Button>
                )}
              </Navbar.Collapse>
              {/* </div> */}
            </>
          )}
          {(props.showNavbar || props.showEdit || props.showDownload) && (
            <Navbar.Toggle
              aria-controls="navbarScroll"
              onClick={() => setExpanded(expanded ? false : !expanded)}
            />
          )}
        </Container>
      </Navbar>
    </>
  );
};

export default SubHeader;
