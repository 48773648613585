/* eslint-disable */
import React, { useState, useEffect, Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Popover from "@mui/material/Popover";
import { Navbar } from "react-bootstrap";
import { AxiosResponse } from "axios";
import { AxiosGetWithToken } from "../../Utils/Api";
import { toast } from "react-toastify";
import { SupportRequest } from "../Modal/SupportRequest";
import { userData } from "../../Redux/Action/user";
import AdminSettingsMenu from "./AdminSettingsMenu";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import ChangePassword from "../Modal/ChangePassword";
import MyProfile from "../Modal/MyProfile";
import { BiHome, BiUserCircle, BiSupport } from "react-icons/bi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
// import { TbTemplate } from 'react-icons/tb';
import "./CommonHeader.css";
import logo from "../../assets/images/logo.jpg";

const CommonHeader = () => {
  const dispatch = useDispatch();

  interface data {
    message?: string;
    status?: number;
    FIRST_NAME: string;
    EMAIL: string;
    LAST_NAME: string;
    TYPE?: string;
    IS_SUBSCRIBE?: string;
    PLAN_AMOUNT?: number;
  }

  // eslint-disable-next-line no-unused-vars
  enum status {
    // eslint-disable-next-line no-unused-vars
    Unsubscribe = "Unsubscribe",
    // eslint-disable-next-line no-unused-vars
    Resubscribe = "Re-subscribe",
  }
  interface userDetailsResponse extends AxiosResponse {
    data: { data: data };
  }

  interface Header {
    Authorization: string | null;
  }

  interface Event {
    preventDefault: Function;
  }
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const navigate = useNavigate();

  const [showSupportModal, setShowSupportModal] = useState<boolean>(false);
  const [name, setName] = useState<string>("");

  const userDetail = useSelector(
    (state: { UserReducer: { userDetail: data } }) =>
      state.UserReducer.userDetail
  );

  const getUserDetails = () => {
    AxiosGetWithToken("/user", { headers }).then(
      (response: userDetailsResponse) => {
        localStorage.setItem("email", response.data.data.EMAIL);
        dispatch(userData(response.data.data));
        setName(
          `${response.data.data.FIRST_NAME} ${response.data.data.LAST_NAME}`
        );
      }
    );
  };

  useEffect(() => {
    if (headers?.Authorization && !userDetail?.FIRST_NAME) {
      getUserDetails();
    } else {
      setName(`${userDetail?.FIRST_NAME} ${userDetail?.LAST_NAME}`);
    }
  }, []);

  const logOut = (event: Event) => {
    event.preventDefault();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("idToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("email");
    navigate("/login");
    toast.info("You are Logged out!");
  };

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showMyProfileModal, setShowMyProfileModal] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleShow = (event: Event) => {
    event.preventDefault();
    setShowSupportModal(!showSupportModal);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (openAdmin && ref.current && !ref.current.contains(e.target)) {
        setOpenAdmin(false);
      }
      if (openSetting && ref.current && !ref.current.contains(e.target)) {
        setOpenSetting(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [openAdmin || openSetting]);

  const updateSubscription = () => {
    AxiosGetWithToken(
      `/stripe/${
        userDetail?.IS_SUBSCRIBE ? "cancelSubscription" : "resumeSubscription"
      }`,
      { headers }
    ).then((response) => {
      getUserDetails();

      toast.success(response.data.message);
    });
  };

  return (
    <>
      <Navbar bg="white" className="flex-wrap">
        <div
          className="flex-fill"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/home");
          }}
        >
          <img
            src={logo}
            width="180"
            className="d-inline-block align-top mx-4"
          />
        </div>

        <Navbar.Brand></Navbar.Brand>
        <div className="float-end ">
          <div className="d-flex flex-fill">
            {name && (
              <Fragment>
                <div className="mx-2 lbl-username">{name}</div>
                <div className="mx-2">|</div>
              </Fragment>
            )}
            <div className="mx-2">
              <button
                className="btn-header-menu"
                aria-controls="dropdown-home"
                onClick={() => {
                  window.open("/user-guide");
                }}
                data-toggle="tooltip"
                data-placement="bottom"
                title="User guide"
              >
                <AiOutlineQuestionCircle size={18} />
              </button>
            </div>
            <div className="mx-2">
              {/* <TbGridDots /> */}
              <button
                className="btn-header-menu"
                // onClick={() => handleClickAdmin()}
                aria-controls="dropdown-home"
                aria-expanded={openAdmin}
                onClick={() => {
                  navigate("/home");
                }}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Home"
              >
                <BiHome size={18} />
              </button>
            </div>
            {userDetail?.TYPE === "admin" && (
              <div className="mx-2">
                <AdminSettingsMenu />
              </div>
            )}
            <div className="mx-2">
              <button
                className="btn-header-menu"
                onClick={handleShow}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Support"
              >
                <BiSupport size={18} color="black" />
              </button>
            </div>
            <div className="mx-2">
              <button
                className="btn-header-menu"
                onClick={handleClick}
                aria-controls="dropdown-user"
                aria-expanded={openAdmin}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Profile"
              >
                <BiUserCircle size={18} />
              </button>
            </div>
          </div>
        </div>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div id="dropdown-user">
            <div className="d-flex flex-column">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setShowMyProfileModal(true);
                  handleClose();
                }}
                className="pointer"
              >
                My account
              </a>
              {userDetail.PLAN_AMOUNT !== 0 && userDetail?.TYPE !== "admin" && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setShowConfirmModal(true);
                    handleClose();
                  }}
                  className="pointer"
                >
                  {userDetail?.IS_SUBSCRIBE
                    ? status.Unsubscribe
                    : status.Resubscribe}
                </a>
              )}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setShowChangePasswordModal(true);
                  handleClose();
                }}
                className="pointer"
              >
                Change password
              </a>
              <a onClick={logOut} className="pointer">
                Log out
              </a>
            </div>
          </div>
        </Popover>
      </Navbar>
      <SupportRequest
        showSupportModal={showSupportModal}
        setShowSupportModal={setShowSupportModal}
      />

      {showChangePasswordModal && (
        <ChangePassword
          show={showChangePasswordModal}
          setShow={setShowChangePasswordModal}
        />
      )}

      {showMyProfileModal && (
        <MyProfile
          setName={setName}
          show={showMyProfileModal}
          setShow={setShowMyProfileModal}
        />
      )}

      {showConfirmModal && (
        <ConfirmationDialog
          confirmButtonText={
            userDetail?.IS_SUBSCRIBE ? status.Unsubscribe : status.Resubscribe
          }
          confirmDialogTitle={
            userDetail?.IS_SUBSCRIBE ? status.Unsubscribe : status.Resubscribe
          }
          show={showConfirmModal}
          setShow={setShowConfirmModal}
          onConfirm={updateSubscription}
          message={
            userDetail?.IS_SUBSCRIBE
              ? "Are you sure you want to cancel your subscription ?"
              : "Are you sure you want to re-subscribe with the current plan?"
          }
          isConfirmButtonVisible={true}
        />
      )}
    </>
  );
};

export default CommonHeader;
