/* eslint-disable */
import React, { useState } from "react";
import { RiSortDesc, RiSortAsc } from "react-icons/ri";
import Dropdown from "react-bootstrap/Dropdown";
import "./dropdownbox.scss";

interface propTypes {
  sortSearchResult: Function;
}
// eslint-disable-next-line no-unused-vars
enum sortingOrder {
  // eslint-disable-next-line no-unused-vars
  ascending = "asc",
  // eslint-disable-next-line no-unused-vars
  descending = "desc",
}
const dropDownValues = ["Sort By", "Title", "Publication date"];

const index = (props: propTypes) => {
  const { sortSearchResult } = props;
  const [selectedValue, setSelectedValue] = useState(dropDownValues[0]);
  const [order, setOrder] = useState<sortingOrder>(sortingOrder.ascending);

  return (
    <>
      <Dropdown className="dropdown ">
        <Dropdown.Toggle className="sortSearchResult" as="span">
          {selectedValue}&nbsp; &nbsp;
          {selectedValue !== "None" && order === sortingOrder.ascending ? (
            <RiSortAsc />
          ) : (
            selectedValue !== "None" &&
            order === sortingOrder.descending && <RiSortDesc />
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {dropDownValues.map((value, index) => {
            return (
              <Dropdown.Item
                key={index}
                onClick={() => {
                  setOrder(
                    order === sortingOrder.ascending && selectedValue === value
                      ? sortingOrder.descending
                      : sortingOrder.ascending
                  );
                  sortSearchResult(
                    index,
                    order === sortingOrder.ascending && selectedValue === value
                      ? sortingOrder.descending
                      : sortingOrder.ascending
                  );
                  setSelectedValue(value);
                }}
              >
                {value}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
export default index;
