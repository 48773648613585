/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import SubHeader from "../../Components/SubHeader/SubHeader";
import ConfirmationDialog from "../../Components/ConfirmationDialog/ConfirmationDialog";
import { AxiosGetWithToken, AxiosPutWithToken } from "../../Utils/Api";
import AddPredefinedCategory from "../../Components/Modal/AddPredefinedCategory";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
import addFileIcon from "../../assets/images/addfile_icon.svg";
import deleteIcon from "../../assets/images/delete_icon.svg";
import Pagination from "../../Components/Pagination";

interface Header {
  Authorization: string | null;
}

interface predefinedCategory {
  _id: string;
  TITLE: string;
}

function PredefinedCategory() {
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const [show, setShow] = useState(false);

  const [predefinedCategoriesData, setPredefinedCategoriesData] = useState([]);
  const [predefinedCategoryName, setPredefinedCategoryName] =
    useState<string>("");
  const [predefinedCategoryId, setPredefinedCategoryId] = useState<
    string | null
  >();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [showDelateModal, setShowDelateModal] = useState(false);

  useEffect(() => {
    if (headers?.Authorization) {
      getPredefinedCategories();
    }
  }, []);

  function deleteCategory() {
    if (headers?.Authorization) {
      AxiosPutWithToken(
        `/predefinedCategories/delete/${predefinedCategoryId}`,
        {},
        { headers }
      )
        .then((response) => {
          const updatedList = predefinedCategoriesData.filter(
            (predefinedCategory: predefinedCategory) =>
              predefinedCategory._id !== predefinedCategoryId
          );

          const renderData = updatedList.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          );

          if (renderData.length === 0) {
            setPage(page - 1);
          }
          setPredefinedCategoriesData(updatedList);
          setPredefinedCategoryId(null);

          toast.success(response.data.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  }

  const getPredefinedCategories = () => {
    AxiosGetWithToken("/predefinedCategoriesList", { headers }).then(
      (response) => {
        setPredefinedCategoriesData(response.data.data.allPredefinedCategories);
      }
    );
  };

  return (
    <Fragment>
      <CommonHeader />

      <SubHeader
        setShow={setShow}
        showNavbar={false}
        navigateUrl={"/home"}
        brand={"Predefined categories"}
        showSearch={false}
        showEdit={false}
      />

      <div className="card border-0 shadow rounded m-4">
        <div className="card-body pt-4">
          <div className="d-flex flex-wrap w-100">
            <h4 className="flex-fill flex-shrink-1">Category list</h4>
            <div className="d-flex flex-fill flex-grow-2">
              <div className="flex-fill"></div>
              <div className="flex-shrink-1">
                <button
                  type="button"
                  className="btn btn-primary btn-circle mx-2"
                  onClick={() => setShow(true)}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Create new predefined category"
                >
                  <img src={addFileIcon} alt="Create new category" />
                </button>
              </div>
            </div>
          </div>

          <Table responsive="sm" className="table-general w-100 mt-3">
            <thead>
              <tr>
                <th>Category name</th>
                <th></th>
              </tr>
            </thead>

            {predefinedCategoriesData.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan={4}>No category found</td>
                </tr>
              </tbody>
            ) : (
              predefinedCategoriesData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((PCategoryName: predefinedCategory, index) => (
                  <tbody key={index}>
                    <tr>
                      <td
                        data-label="Category name"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Edit predefined category "
                      >
                        <span
                          className="predefined"
                          onClick={() => {
                            setShow(true);
                            setPredefinedCategoryName(PCategoryName.TITLE);
                            setPredefinedCategoryId(PCategoryName._id);
                          }}
                        >
                          <a href="#" className="fw-bold">
                            {PCategoryName.TITLE}
                          </a>
                        </span>
                      </td>
                      <td data-label="">
                        <span
                          className="float-lg-end float-md-end"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Delete"
                        >
                          <button
                            className="btn-del"
                            onClick={() => {
                              setPredefinedCategoryId(PCategoryName._id);
                              setShowDelateModal(true);
                            }}
                          >
                            <img src={deleteIcon} />
                          </button>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                ))
            )}
          </Table>
        </div>

        {predefinedCategoriesData.length > 10 && (
          <Pagination
            data={predefinedCategoriesData}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>

      {show && (
        <AddPredefinedCategory
          predefinedCategoryName={predefinedCategoryName}
          setPredefinedCategoryName={setPredefinedCategoryName}
          predefinedCategoryId={predefinedCategoryId}
          setPredefinedCategoryId={setPredefinedCategoryId}
          show={show}
          setShow={setShow}
          list={predefinedCategoriesData}
          setList={setPredefinedCategoriesData}
        />
      )}

      {showDelateModal && (
        <ConfirmationDialog
          confirmButtonText="Delete"
          confirmDialogTitle="Delete predefined category"
          show={showDelateModal}
          setShow={setShowDelateModal}
          onConfirm={deleteCategory}
          message="you want to delete the selected predefined category?"
          isConfirmButtonVisible={true}
        />
      )}
    </Fragment>
  );
}

export default PredefinedCategory;
