/* eslint-disable */

import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AxiosPostWithToken, AxiosPutWithToken } from "../../Utils/Api";

interface Header {
  Authorization: string | null;
}

interface predefinedCategory {
  ID: number;
  TITLE: string;
}

interface Props {
  show: boolean;
  setShow: Function;
  list: predefinedCategory[];
  setList: Function;
  predefinedCategoryName: string;
  setPredefinedCategoryName: Function;
  predefinedCategoryId: number | string | undefined | null;
  setPredefinedCategoryId: Function;
}

export default function AddPredefinedCategory(props: Props) {
  const {
    show,
    setShow,
    list,
    setList,
    predefinedCategoryName,
    setPredefinedCategoryName,
    predefinedCategoryId,
    setPredefinedCategoryId,
  } = props;

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  function handleClose() {
    setShow(!show);
    setPredefinedCategoryName("");
  }

  useEffect(() => {
    if (predefinedCategoryName) {
      setValue("TITLE", predefinedCategoryName);
    }
  }, []);

  const handleCategory = (data) => {
    if (data.TITLE.trim() === "") {
      setError("TITLE", {
        type: "custom",
        message: "Please enter a valid pre-defined Category name.",
      });
    }
    if (data.TITLE.trim() !== "") {
      if (!predefinedCategoryName) {
        createCategory(data);
      } else {
        updateCategory(data);
      }
    }
  };

  function createCategory(data) {
    if (headers?.Authorization) {
      AxiosPostWithToken(
        "/predefinedCategories/create",
        { TITLE: data.TITLE },
        { headers }
      )
        .then((response) => {
          list.push({ TITLE: data.TITLE, ID: response.data.data.data.ID });

          setList([...list]);

          toast.success(response.data.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });

      handleClose();
    }
  }

  function updateCategory(data) {
    if (headers?.Authorization) {
      AxiosPutWithToken(
        `/predefinedCategories/update/${predefinedCategoryId}`,
        { TITLE: data.TITLE },
        { headers }
      )
        .then((response) => {
          list.forEach((predefinedCategory) => {
            if (predefinedCategory.ID === predefinedCategoryId) {
              predefinedCategory.TITLE = data.TITLE;
            }
          });

          setList([...list]);

          setPredefinedCategoryId("");

          toast.success(response.data.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
      handleClose();
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton className="px-3 border-0">
              <Modal.Title>
                {!predefinedCategoryName
                  ? "Add pre-defined category"
                  : "Update pre-defined category"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-3 pt-2 pb-3">
              <Form onSubmit={handleSubmit(handleCategory)}>
                <Col>
                  <div className="col-12 textfield">
                    <Form.Group
                      className="mb-4 textfield'"
                      controlId="First_name"
                    >
                      <Form.Label>Predefined category name</Form.Label>
                      <span className="text-danger">*</span>

                      <Form.Control
                        type="text"
                        placeholder="Enter pre-defined category name"
                        maxLength={50}
                        {...register("TITLE", {
                          required:
                            "Please enter a valid pre-defined category name.",
                          // maxLength: {
                          //   value: 51,
                          //   message: 'Please enter less than 50 words'
                          // }
                        })}
                      />

                      <p className="error invalid-feedback d-block h-40 ">
                        <span className="text-danger">
                          <ErrorMessage errors={errors} name="TITLE" />
                        </span>
                      </p>
                    </Form.Group>
                  </div>
                </Col>

                <Button
                  className="px-3 mt-3 momBotTextButton"
                  variant="primary"
                  type="submit"
                >
                  {!predefinedCategoryName ? "Add" : "Update"}
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}
