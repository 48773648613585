/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
import ConfirmationDialog from "../../Components/ConfirmationDialog/ConfirmationDialog";
import { SupportRequestType } from "../../Components/Modal/SupportRequestType";
import { AxiosGetWithToken, AxiosPutWithToken } from "../../Utils/Api";
import { toast } from "react-toastify";
import Pagination from "../../Components/Pagination";
import SubHeader from "../../Components/SubHeader/SubHeader";
import addFileIcon from "../../assets/images/addfile_icon.svg";
import deleteIcon from "../../assets/images/delete_icon.svg";

interface Header {
  Authorization: string | null;
}

interface supportRequest {
  REQUEST_TYPE: string;
  DETAILS: string;
  _id: string;
}

function AddSupportRequestType() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };
  const [requestTypeData, setRequestTypeData] = useState([]);
  const [sendDataForUpdate, setState] = useState<any>({});
  const [showSupportRequestTypeModal, setShowSupportRequestTypeModal] =
    useState<boolean>(false);

  const [supportRequestTypeId, setSupportRequestTypeId] = useState<
    string | null
  >();

  const [showDelateModal, setShowDelateModal] = useState(false);

  useEffect(() => {
    if (headers?.Authorization) {
      getSupportRequests();
    }
  }, []);

  const getSupportRequests = () => {
    if (headers?.Authorization) {
      AxiosGetWithToken("/supportRequestTypeList", { headers }).then(
        (response) => {
          setRequestTypeData(response.data.data.allSupportRequestTypes);
        }
      );
    }
  };

  const updateRequestType = (requestType, requestDetail, Id) => {
    setState({ ...sendDataForUpdate, requestType, requestDetail, Id });
    setShowSupportRequestTypeModal(!showSupportRequestTypeModal);
  };

  const deleteRequestType = () => {
    AxiosPutWithToken(
      `/supportRequestType/delete/${supportRequestTypeId}`,
      {},
      { headers }
    )
      .then((response) => {
        toast.success(response.data.message);
        const updatedList = requestTypeData.filter(
          (supportRequest: supportRequest) =>
            supportRequest._id !== supportRequestTypeId
        );

        const renderData = updatedList.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );

        if (renderData.length === 0) {
          setPage(page - 1);
        }

        setRequestTypeData(updatedList);

        setSupportRequestTypeId(null);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <Fragment>
      <CommonHeader />

      <SubHeader
        showStatusButtons={false}
        showNavbar={false}
        navigateUrl={"/home"}
        brand={"Support request type"}
        showSearch={false}
        showEdit={false}
      />

      <div className="card border-0 shadow rounded m-4">
        <div className="card-body pt-4">
          <div className="d-flex flex-wrap w-100">
            <h4 className="flex-fill flex-shrink-1">Request type list</h4>
            <div className="d-flex flex-fill flex-grow-2">
              <div className="flex-fill"></div>
              <div className="flex-shrink-1">
                <button
                  type="button"
                  className="btn btn-primary btn-circle mx-2"
                  onClick={() =>
                    setShowSupportRequestTypeModal(!showSupportRequestTypeModal)
                  }
                >
                  <img
                    src={addFileIcon}
                    alt="Add new request type"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Add new request type"
                  />
                </button>
              </div>
            </div>
          </div>

          <Table responsive="sm" className="table-general w-100 mt-3">
            <thead>
              <tr>
                <th>Request type</th>
                <th></th>
              </tr>
            </thead>
            {requestTypeData.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan={4}>No request type found</td>
                </tr>
              </tbody>
            ) : (
              requestTypeData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((SRequestName: supportRequest, index) => (
                  <tbody key={index}>
                    <tr>
                      <td data-label="Request type">
                        <span>
                          <a
                            href=""
                            className="fw-bold"
                            onClick={(e) => {
                              e.preventDefault();
                              updateRequestType(
                                SRequestName?.REQUEST_TYPE,
                                SRequestName?.DETAILS,
                                SRequestName?._id
                              );
                            }}
                          >
                            {SRequestName?.REQUEST_TYPE}
                          </a>
                        </span>
                      </td>
                      <td data-label="">
                        <span
                          className="float-lg-end float-md-end"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Delete"
                        >
                          <button
                            onClick={() => {
                              setSupportRequestTypeId(SRequestName?._id);
                              setShowDelateModal(true);
                            }}
                            className="btn-del"
                          >
                            <img src={deleteIcon} />
                          </button>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                ))
            )}
          </Table>
        </div>
        {requestTypeData.length > 10 && (
          <Pagination
            data={requestTypeData}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>

      <SupportRequestType
        setState={setState}
        updateData={sendDataForUpdate}
        showSupportRequestTypeModal={showSupportRequestTypeModal}
        setShowSupportRequestTypeModal={setShowSupportRequestTypeModal}
        getSupportRequests={getSupportRequests}
      />

      {showDelateModal && (
        <ConfirmationDialog
          confirmButtonText="Delete"
          confirmDialogTitle="Delete Support request type?"
          show={showDelateModal}
          setShow={setShowDelateModal}
          onConfirm={deleteRequestType}
          message="Are you sure you want to delete the selected Support request type ?"
          isConfirmButtonVisible={true}
        />
      )}
    </Fragment>
  );
}

export default AddSupportRequestType;
