/* eslint-disable */
import React from "react";
import { AiFillStar } from "react-icons/ai";

export const RatingStarButton = (props: any) => {
  const {
    id,
    starIconCount,
    onClickStar,
    isActiveButton,
    commentCount,
    onMouseOver,
    onMouseOut,
  } = props;
  const starIcons: Array<any> = [];

  for (let starCntr = starIconCount; starCntr > 0; starCntr--) {
    starIcons.push(<AiFillStar key={starCntr} />);
  }
  return (
    <div>
      <button
        type="button"
        id={id}
        data-toggle="tooltip"
        data-placement="bottom"
        title={
          commentCount === undefined
            ? `${0} comment`
            : commentCount === 0 || commentCount === 1
            ? `${commentCount} comment`
            : `${commentCount} comments`
        }
        className={
          isActiveButton === starIconCount
            ? " btn btn-outline-ratings rating-active my-2 me-3"
            : "btn btn-outline-ratings my-2 me-3"
        }
        onClick={() => {
          onClickStar(starIconCount);
        }}
        onMouseOver={() => {
          onMouseOver && onMouseOver(starIconCount);
        }}
        onMouseOut={() => {
          onMouseOut && onMouseOut();
        }}
      >
        <div className="d-flex">{starIcons}</div>
      </button>
    </div>
  );
};

export default RatingStarButton;
