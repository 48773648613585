/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { AxiosResponse } from "axios";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import {
  AxiosPutWithToken,
  AxiosGetWithToken,
  AxiosPostWithToken,
} from "../../Utils/Api";
import Loader from "../../Components/Loader/loader";
import SubHeader from "../../Components/SubHeader/SubHeader";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
import addIcon from "../../assets/images/add_icon.svg";
import deleteIcon from "../../assets/images/delete_icon.svg";
import "../Home/projectList.scss";
import "./template.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import classicConfiguration from "../../Components/Artifacts/richTextConfiguration";

export default function CreateNewTemplate(props: any) {
  interface Header {
    Authorization: string | null;
  }

  interface FormValues {
    TITLE: string;
    DESCRIPTION: string;
    CONTENT: string;
    EXAMPLES: string;
    templateMessage: string;
    // templateStructureData: {
    //   TITLE: string;
    //   DESCRIPTION: string;
    //   INPUT_TYPE: string;
    // }[];
    prompts: {
      promptname: string;
      promptdescription: string;
      prompt: string;
    }[];
  }

  interface updateData {
    data: object;
    message: string;
    status: number;
  }

  interface TemplateResponse extends AxiosResponse {
    data: updateData;
  }

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const { templateId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [editorData, setEditorData] = useState("");
  const [mainEditorData, setMainEditorData] = useState("");

  const [subEditorData, setSubEditorData] = useState("");
  const [editorSubData, setEditorSubData] = useState("");

  const {
    register,
    setValue,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      // templateStructureData: [
      //   {
      //     TITLE: "",
      //     DESCRIPTION: "",
      //     INPUT_TYPE: "",
      //   },
      // ],
      prompts: [
        {
          promptname: "",
          promptdescription: "",
          prompt: "",
        },
      ],
    },
  });

  const {
    fields: promptFields,
    append: appendPrompt,
    remove: removePrompt,
  } = useFieldArray({
    control,
    name: "prompts",
  });

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "templateStructureData",
  // });

  const handleClose = () => {
    navigate("/template");
  };

  const createTemplate = (data: FormValues) => {
    if (data.TITLE.trim() === "") {
      setError("TITLE", {
        type: "custom",
        message: "Please enter a valid Template name.",
      });
    }
    if (headers?.Authorization && data.TITLE.trim() !== "") {
      setLoading(true);

      AxiosPostWithToken("/template/create", data, { headers })
        .then((response: TemplateResponse) => {
          setLoading(false);
          toast.success(response.data.message);
          handleClose();
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message);
        });
    }
  };

  const updateTemplate = (data, e) => {
    e.preventDefault();
    if (headers?.Authorization && templateId) {
      AxiosPutWithToken(`/template/update/${templateId}`, data, {
        headers,
      }).then((response: TemplateResponse) => {
        toast.success(response.data.message);
        handleClose();
      });
    }
  };

  //idk
  useEffect(() => {
    if (headers?.Authorization && templateId) {
      setLoading(true);
      AxiosGetWithToken(`/template/${templateId}`, { headers }).then(
        (response: any) => {
          setLoading(false);
          setValue("TITLE", response?.data?.data?.template?.TITLE);
          setValue("DESCRIPTION", response?.data?.data?.template?.DESCRIPTION);

          setValue("prompts", response?.data?.data?.template?.PROMPTS);
          setMainEditorData(
            response?.data?.data?.template?.CONTENT
              ? response?.data?.data?.template?.CONTENT
              : ""
          );
          setSubEditorData(
            response?.data?.data?.template?.EXAMPLES
              ? response?.data?.data?.template?.EXAMPLES
              : ""
          );
        }
      );
    }

    const interval = setInterval(() => {
      handleOnChange();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleOnChange = () => {
    clearErrors("templateMessage");
  };

  return (
    <Fragment>
      {loading && <Loader />}
      <CommonHeader />

      <SubHeader
        showNavbar={!templateId && false}
        brand={`${templateId ? "Edit Template" : "Create Template"}`}
        showSearch={false}
        navigateUrl={
          templateId ? "/template" : "" || !templateId ? "/template" : ""
        }
      />

      {loading ? (
        <Loader />
      ) : (
        <Row className="px-4 mb-2">
          <Col>
            <Form
              onSubmit={
                templateId
                  ? handleSubmit(updateTemplate)
                  : handleSubmit(createTemplate)
              }
            >
              <Col>
                <Form.Group className="mb-4" controlId="First_name">
                  <div className="col-12 textfield">
                    <Form.Label>
                      Template name
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Template Name"
                      maxLength={255}
                      {...register("TITLE", {
                        onChange: () => handleOnChange(),
                        required: "Template name is required",
                        minLength: {
                          value: 1,
                          message: "",
                        },
                        // maxLength: {
                        //   value: 255,
                        //   message: 'Please enter less than 256 words'
                        // }
                      })}
                    />
                    <p className="error invalid-feedback d-block h-50">
                      <span className="text-danger">
                        <ErrorMessage errors={errors} name="TITLE" />
                      </span>
                    </p>
                  </div>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-4" controlId="First_name">
                  <div className="col-12 textfield">
                    <Form.Label>Description</Form.Label>
                    <textarea
                      className="form-control"
                      rows={3}
                      cols={100}
                      placeholder="Enter Description"
                      maxLength={255}
                      {...register("DESCRIPTION", {
                        onChange: () => handleOnChange(),
                        // maxLength: {
                        //   value: 255,
                        //   message: ' Please enter less than 256 words'
                        // }
                      })}
                    />
                    <p className="error invalid-feedback d-block h-50">
                      <span className="text-danger">
                        <ErrorMessage errors={errors} name="DESCRIPTION" />
                      </span>
                    </p>
                  </div>
                </Form.Group>
              </Col>

              <Col>
                <div className="mb-4">
                  <div className="col-12">
                    <Form.Label>Content</Form.Label>
                    {/* <textarea
                      className="form-control"
                      rows={3}
                      cols={100}
                      placeholder="Enter Description"
                      maxLength={255}
                      {...register("DESCRIPTION", {
                        onChange: () => handleOnChange(),
                        // maxLength: {
                        //   value: 255,
                        //   message: ' Please enter less than 256 words'
                        // }
                      })}
                    /> */}
                    <CKEditor
                      className="ckEditorScrollBar"
                      editor={ClassicEditor}
                      // {...register(`${template.ID}`)}
                      //name={`${template.ID}`}
                      config={classicConfiguration}
                      data={mainEditorData}
                      onChange={(value, editor) => {
                        setValue("CONTENT", editor.getData());
                        setEditorData(editor.getData());
                      }}
                    />
                  </div>
                </div>
              </Col>

              <Col>
                <div className="mb-4">
                  <div className="col-12">
                    <Form.Label>Examples</Form.Label>
                    {/* <textarea
                      className="form-control"
                      rows={3}
                      cols={100}
                      placeholder="Enter Description"
                      maxLength={255}
                      {...register("DESCRIPTION", {
                        onChange: () => handleOnChange(),
                        // maxLength: {
                        //   value: 255,
                        //   message: ' Please enter less than 256 words'
                        // }
                      })}
                    /> */}
                    <CKEditor
                      className="ckEditorScrollBar"
                      editor={ClassicEditor}
                      // {...register(`${template.ID}`)}
                      //name={`${template.ID}`}
                      config={classicConfiguration}
                      data={subEditorData}
                      onChange={(value, editor) => {
                        setValue("EXAMPLES", editor.getData());
                        setEditorSubData(editor.getData());
                      }}
                    />
                  </div>
                </div>
              </Col>

              {/* New Prompts Section */}

              <Col>
                <div className="col-12 mt-4 mb-4 w-full">
                  <h6>Prompts</h6>
                  <Card
                    className="p-3 prompt-card"
                    style={{ border: "1px solid #f1c40f" }}
                  >
                    <h6 className="mb-3 text-secondary">{`Total prompts: ${promptFields.length}`}</h6>
                    {promptFields.map((prompt, index) => (
                      <div
                        key={index}
                        className="card mb-3"
                        style={{ border: "1px dashed #c4c4c4" }}
                      >
                        <div className="card-body">
                          <Row className="mb-3">
                            <Col lg="6">
                              <Form.Group
                                controlId={`prompts.${index}.promptname`}
                              >
                                <Form.Label>Prompt Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Prompt Name"
                                  {...register(`prompts.${index}.promptname`, {
                                    onChange: handleOnChange,
                                    required: "Prompt name is required",
                                  })}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name={`prompts.${index}.promptname`}
                                  as={<p className="text-danger" />}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              <Form.Group
                                controlId={`prompts.${index}.promptdescription`}
                              >
                                <Form.Label>Prompt Description</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Prompt Description"
                                  {...register(
                                    `prompts.${index}.promptdescription`,
                                    {
                                      onChange: handleOnChange,
                                      required:
                                        "Prompt description is required",
                                    }
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name={`prompts.${index}.promptdescription`}
                                  as={<p className="text-danger" />}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <Form.Group controlId={`prompts.${index}.prompt`}>
                                <Form.Label>Prompt</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={7}
                                  placeholder="Enter Prompt"
                                  {...register(`prompts.${index}.prompt`, {
                                    onChange: handleOnChange,
                                    required: "Prompt is required",
                                  })}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name={`prompts.${index}.prompt`}
                                  as={<p className="text-danger" />}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <button
                            className="btn-del mt-2 float-end"
                            onClick={(e) => {
                              e.preventDefault();
                              if (promptFields.length > 1) {
                                removePrompt(index);
                              } else {
                                setError("templateMessage", {
                                  type: "custom",
                                  message: "At least one prompt is required.",
                                });
                              }
                            }}
                          >
                            <img src={deleteIcon} />
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="text-center">
                      <Button
                        type="button"
                        className="btn-circle me-5 "
                        onClick={(e) => {
                          e.preventDefault();
                          clearErrors("templateMessage");
                          appendPrompt({
                            promptname: "",
                            promptdescription: "",
                            prompt: "",
                          });
                        }}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Add New Prompt"
                      >
                        <img
                          src={addIcon}
                          alt="Add New Prompt"
                          title="Add New Prompt"
                        />
                      </Button>
                    </div>
                  </Card>
                </div>
              </Col>

              {/* Prompts Section
              <Row className="templateAdd">
                <Col lg="1">
                  <Form.Group className="mb-4">
                    <div className="col-12 textfield">
                      <Form.Label>Add Prompt</Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="4">
                  <Form.Group className="mb-4">
                    <div className="col-12 textfield">
                      <Form.Label>
                        Name
                        <span className="text-danger">*</span>
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="4">
                  <Form.Group className="mb-4">
                    <div className="col-12 textfield">
                      <Form.Label>
                        Description
                        <span className="text-danger">*</span>
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {promptFields.map((prompt, index) => (
                <Row key={index} className="border-top py-3">
                  <Col lg="1" data-label="Add Prompt" className="label">
                    <Button
                      type="button"
                      className="btn-circle me-5 float-end"
                      onClick={(e) => {
                        e.preventDefault();
                        clearErrors("templateMessage");
                        appendPrompt({
                          promptname: "",
                          promptdescription: "",
                          prompt: "",
                        });
                      }}
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Add New Prompt"
                    >
                      <img
                        src={addIcon}
                        alt="Add New Prompt"
                        title="Add New Prompt"
                      />
                    </Button>
                  </Col>
                  <Col lg="4" data-label="Name" className="label">
                    <Form.Group className="mb-4" controlId="promptname">
                      <div className="col-12 textfield">
                        <Form.Control
                          type="text"
                          placeholder="Enter Prompt Name"
                          maxLength={255}
                          defaultValue={prompt.promptname}
                          {...register(`prompts.${index}.promptname`, {
                            onChange: () => handleOnChange(),
                            required: "Prompt name is required",
                          })}
                        />
                        <p className="error invalid-feedback d-block h-50">
                          <span className="text-danger">
                            <ErrorMessage
                              errors={errors}
                              name={`prompts.${index}.promptname`}
                            />
                          </span>
                        </p>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="4" data-label="Description" className="label">
                    <Form.Group className="mb-4" controlId="promptdescription">
                      <div className="col-12 textfield">
                        <Form.Control
                          type="text"
                          placeholder="Enter Prompt Description"
                          maxLength={255}
                          defaultValue={prompt.promptdescription}
                          {...register(`prompts.${index}.promptdescription`, {
                            onChange: () => handleOnChange(),
                            required: "Prompt description is required",
                          })}
                        />
                        <p className="error invalid-feedback d-block h-50">
                          <span className="text-danger">
                            <ErrorMessage
                              errors={errors}
                              name={`prompts.${index}.promptdescription`}
                            />
                          </span>
                        </p>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="11" data-label="Prompt" className="label">
                    <Form.Group className="mb-4" controlId="prompt">
                      <div className="col-12 textfield">
                        <Form.Label>Prompt</Form.Label>
                        <textarea
                          className="form-control"
                          rows={3}
                          placeholder="Enter Prompt"
                          defaultValue={prompt.prompt}
                          {...register(`prompts.${index}.prompt`, {
                            onChange: () => handleOnChange(),
                            required: "Prompt is required",
                          })}
                        />
                        <p className="error invalid-feedback d-block h-50">
                          <span className="text-danger">
                            <ErrorMessage
                              errors={errors}
                              name={`prompts.${index}.prompt`}
                            />
                          </span>
                        </p>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="1">
                    <button
                      className="btn-del"
                      onClick={(e) => {
                        e.preventDefault();
                        if (promptFields.length > 1) {
                          removePrompt(index);
                        } else {
                          setError("templateMessage", {
                            type: "custom",
                            message: "At least one prompt is required.",
                          });
                        }
                      }}
                    >
                      <img src={deleteIcon} />
                    </button>
                  </Col>
                </Row>
              ))} */}

              {/* <div className='templateAdd'> */}
              {/* <Row className="templateAdd">
                <Col lg="1">
                  <Form.Group className="mb-4">
                    <div className="col-12 textfield">
                      <Form.Label>Add Row</Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="4">
                  <Form.Group className="mb-4">
                    <div className="col-12 textfield">
                      <Form.Label>
                        Title
                        <span className="text-danger">*</span>
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="4">
                  <Form.Group className="mb-4">
                    <div className="col-12 textfield">
                      <Form.Label>
                        Description
                        <span className="text-danger">*</span>
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="1" className="label">
                  <Form.Group className="mb-4">
                    <div data-label="Input type" className="col-12 textfield">
                      <Form.Label>
                        Input type
                        <span className="text-danger">*</span>
                      </Form.Label>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {fields.map((temp: any, index: any) => {
                return (
                  // <Container fluid key={index}>
                  <Row key={index} className="border-top py-3">
                    <Col lg="1" data-label="Add Row" className="label">
                      <Button
                        type="button"
                        className="btn-circle me-5 float-end"
                        onClick={(e) => {
                          e.preventDefault();
                          clearErrors("templateMessage");
                          append({
                            TITLE: "",
                            DESCRIPTION: "",
                            INPUT_TYPE: "",
                          });
                        }}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Add New Row"
                      >
                        <img
                          src={addIcon}
                          alt="Add New Row"
                          title="Add New Row"
                        />
                      </Button>
                    </Col>
                    <Col lg="4" data-label="TITLE" className="label">
                      <Form.Group className="mb-4" controlId="TITLE">
                        <div className="col-12 textfield">
                          <Form.Control
                            type="text"
                            placeholder="Enter Closing Line"
                            maxLength={255}
                            defaultValue={`${temp.TITLE}`}
                            {...register(
                              `templateStructureData.${index}.TITLE`,
                              {
                                onChange: () => handleOnChange(),
                                required: "Title is required",
                                // maxLength: {
                                //   value: 255,
                                //   message: ' Please enter less than 256 words'
                                // }
                              }
                            )}
                          />
                          <p className="error invalid-feedback d-block h-50">
                            <span className="text-danger">
                              <ErrorMessage
                                errors={errors}
                                name={`templateStructureData.${index}.TITLE`}
                              />
                            </span>
                          </p>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="4" data-label="DESCRIPTION" className="label">
                      <Form.Group className="mb-4" controlId="DESCRIPTION">
                        <div className="col-12 textfield">
                          <Form.Control
                            type="text"
                            placeholder="Enter Description"
                            maxLength={255}
                            defaultValue={`${temp.DESCRIPTION}`}
                            {...register(
                              `templateStructureData.${index}.DESCRIPTION`,
                              {
                                onChange: () => handleOnChange(),
                                required: "Description is required",
                                // maxLength: {
                                //   value: 255,
                                //   message: ' Please enter less than 256 words'
                                // }
                              }
                            )}
                          />
                          <p className="error invalid-feedback d-block h-50">
                            <span className="text-danger">
                              <ErrorMessage
                                errors={errors}
                                name={`templateStructureData.${index}.DESCRIPTION`}
                              />
                            </span>
                          </p>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="2" data-label="INPUT TYPE" className="label">
                      <Form.Group className="mb-4" controlId="INPUT_TYPE">
                        <div className="col-12 textfield">
                          <Form.Select
                            className="form-control"
                            placeholder="Select"
                            maxLength={255}
                            defaultValue={`${temp.INPUT_TYPE}`}
                            {...register(
                              `templateStructureData.${index}.INPUT_TYPE`,
                              {
                                onChange: () => handleOnChange(),
                                required: "Input Type is required",
                                // maxLength: {
                                //   value: 255,
                                //   message: ' Please enter less than 256 words'
                                // }
                              }
                            )}
                          >
                            <option value="">Select type</option>
                            <option value="Input Box">Input box</option>
                            <option value="Rich Text Editor">
                              Richtext box
                            </option>
                          </Form.Select>
                          <p className="error invalid-feedback d-block h-50">
                            <span className="text-danger">
                              <ErrorMessage
                                errors={errors}
                                name={`templateStructureData.${index}.INPUT_TYPE`}
                              />
                              {errors.templateStructureData && <br />}
                              <ErrorMessage
                                errors={errors}
                                name="templateMessage"
                              />
                            </span>
                          </p>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="1">
                      <button
                        className={
                          errors.templateMessage && errors.templateStructureData
                            ? "btn-del templateRowDel"
                            : "btn-del"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          if (fields?.length > 1) {
                            remove(index);
                          } else {
                            setError("templateMessage", {
                              type: "custom",
                              message:
                                "First created template-row must be required.",
                            });
                          }
                        }}
                      >
                        <img src={deleteIcon} />
                      </button>
                    </Col>
                  </Row>
                  // </Container>
                );
              })} */}
              {/* </div> */}
              <div className="col-12">
                <Button
                  type="submit"
                  className="px-3 mt-2 me-3 momBotTextButton"
                  variant="primary"
                >
                  {!templateId ? "Create" : "Update"}
                </Button>
                <button
                  type="button"
                  className="btn btn-secondary border-0 mt-2"
                  onClick={() => navigate("/template")}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      )}
    </Fragment>
  );
}
