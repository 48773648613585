/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Row, Container, Col, Card } from "react-bootstrap";

import {
  collection,
  query,
  where,
  getDocs,
  limit,
  updateDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import BookmarkAccordion from "../../../Components/Bookmarks/BookmarkSideComponent";
import { Button, Text } from "@mantine/core";
import CreateNewProject from "../../Home/CreateNewProject";
import CommonHeader from "../../../Components/CommonHeader/CommonHeader";
import { IconArrowLeft, IconCirclePlus, IconPencil } from "@tabler/icons-react";
import { AxiosGetWithToken } from "../../../Utils/Api";
import FormateClipBoardData from "../../../Components/Clipboard/FormateClipBoardData";
import KeyWord from "../../../Components/Keyword/index";
import ArtiFact from "../../../Components/Artifacts/index";

interface LocationState {
  projName: string;
}

function Dashboard() {
  interface Header {
    Authorization: string | null;
  }

  const location = useLocation();
  const { projName } = location.state as LocationState;

  const [list, setList] = useState({});

  const [show, setShow] = useState(false);

  const [isUpdated, setIsUpdate] = useState("");

  const [expandClipboard, setExpandClipboard] = useState(false);

  const [hide, setHide] = useState(true);

  const [projectName, setProjectName] = useState("");

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const { projectId } = useParams();

  useEffect(() => {
    if (headers?.Authorization) {
      AxiosGetWithToken(`/clipboard/${projectId}`, { headers }).then(
        async (response: any) => {
          console.log(response.data.data.category);
          setList({});
          const listFinalData = FormateClipBoardData(
            response.data.data.category
          );

          console.log(listFinalData);
          setList(listFinalData);
        }
      );
    }
  }, []);
  useEffect(() => {
    if (isUpdated) {
      AxiosGetWithToken(`/project/${projectId}`, { headers }).then(
        (response: any) => {
          setProjectName(response.data.data.project.NAME);
        }
      );
    }
  }, [isUpdated]);

  const navigate = useNavigate();

  function expandCollapse() {
    setExpandClipboard(!expandClipboard);
    setHide(!hide);
  }
  return (
    <Fragment>
      <CommonHeader />

      <div className="bg-light-yellow py-4 full-width">
        <Container>
          <Row>
            <Col md={12} className="mx-auto">
              <Button
                leftSection={<IconArrowLeft size={15} />}
                variant="light"
                color="gray"
                radius="xl"
                size="xs"
                onClick={() => navigate("/project")}
              >
                Projects
              </Button>
              <h2 className="text-left mt-2">{projName}</h2>

              <Row className="actions mt-2">
                <Col xs={4} md={2} className="text-center ">
                  <Card
                    className="bookmarks-option-card bookmarks-select-option d-flex flex-column justify-content-center align-items-center"
                    onClick={() => setShow(true)}
                  >
                    <IconPencil size={40} stroke={1} className="icon mb-2" />
                    <Card.Text className="label">Edit project</Card.Text>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row className="my-4">
          <Col md={3}>
            {/* <div className="clipboard-section">
              <Clipboard
                showDescription={true}
                hideCheckbox={false}
                list={list}
                setList={setList}
                projectId={projectId}
                hideExpandButton={true}
                expandCollapse={expandCollapse}
                hide={hide}
                expandClipboard={expandClipboard}
                visibleButton={true}
              />
            </div> */}
            <Card className="p-3 mb-3">
              <Text size="xl" fw={700} c={"#333"}>
                Bookmarks
              </Text>
              <BookmarkAccordion />
            </Card>
          </Col>
          {hide && (
            <Col md={9}>
              <Row>
                <Col lg={12}>
                  <div className="card border-1 rounded mb-4 keyword-section">
                    <div className="card-body ">
                      <KeyWord />
                    </div>
                  </div>
                  <div className="card border-1  rounded mb-4 artifact-section">
                    <div className="card-body pt-4">
                      <ArtiFact />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Container>
      {show && (
        <CreateNewProject
          show={show}
          setShow={setShow}
          projectId={projectId}
          setIsUpdate={setIsUpdate}
        />
      )}
    </Fragment>
  );
}

export default Dashboard;
