/* eslint-disable */
/* eslint-disable multiline-ternary */
import React, { Fragment } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import "./search.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";

import { BsSearch } from "react-icons/bs";

import { useNavigate } from "react-router-dom";

export default function index(props) {
  const {
    insideKeyword,
    onSearchClick,
    projectId,
    showSearchButton,
    setShowSearchButton,
    setSearchText,
    searchText,
    setExpanded,
    ShowsearchName,
    insideUser,
    onSearchUserClick,
    insideProject,
  } = props;
  const {
    register,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const navigate = useNavigate();
  const onChange = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  const KeyPressed = (e) => {
    if (e.key === "Enter") {
      if (searchText && searchText.trim() !== "") {
        searchText &&
          projectId &&
          navigate(`/project/${projectId}/performSearch/${searchText}`);
      } else {
        if (searchText?.length === 0 || searchText.trim() === "") {
          if (insideUser) {
            setError(insideUser && "searchUserlist", {
              type: "custom",
              message: "Please enter a text.",
            });
          }
          if (insideKeyword) {
            setError(insideKeyword && "searchKeyword", {
              type: "custom",
              message: "Please enter a keyword.",
            });
          }
          if (insideProject) {
            toast.error("Please add project name.");
          }
        }
      }
    }
    if (e.key === "Escape") {
      setSearchText("");
      setShowSearchButton(false);
    }
  };

  const SearchButton = () => {
    return (
      <Button
        type="button"
        data-toggle="tooltip"
        data-placement="bottom"
        title={
          insideKeyword
            ? "Search for books using new keywords"
            : insideUser
            ? "Search user"
            : "Search projects"
        }
        className={`${
          (insideUser || ShowsearchName) && "btn-circle"
        } mx-2 float-end search`}
        onClick={() => {
          if (showSearchButton) {
            if (insideKeyword) {
              setError(
                insideKeyword && searchText.trim() === "" && "searchKeyword",
                { type: "custom", message: "Please enter a keyword." }
              );
              onSearchClick();
            }
            if (insideUser) {
              onSearchUserClick();
            }
            if (insideProject) {
              toast.error("Please add project name.");
            }
          } else {
            setShowSearchButton(!showSearchButton);
            reset();
          }
          if (searchText) {
            setExpanded(false);
          }
        }}
        aria-controls="example-collapse-text"
        aria-expanded={showSearchButton}
      >
        <BsSearch className="me-2" />

        {!ShowsearchName && !insideUser ? <>Search</> : null}
      </Button>
    );
  };

  return (
    <>
      {showSearchButton && (
        <Fragment>
          {insideKeyword ? (
            <Fragment>
              <div className="flex-fill">
                <div className="col-12 textfield">
                  <div className="searchBox">
                    <Form.Control
                      placeholder="Search"
                      className={`${!insideKeyword ? "rounded-pill" : ""}`}
                      {...register("searchKeyword", {})}
                      onChange={(e) => {
                        onChange(e);
                        reset();
                      }}
                      aria-label="Close"
                      type="Search projects"
                      value={searchText}
                      onKeyDown={KeyPressed}
                      autoFocus
                      required
                    />
                    <GrClose
                      className="searchClose"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Cancel search"
                      onClick={() => {
                        setSearchText("");
                        setShowSearchButton(false);
                      }}
                    />
                    <p className="error invalid-feedback d-block h-30">
                      <span className="text-danger">
                        <ErrorMessage errors={errors} name="searchKeyword" />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Col xs={12} lg={4} className="my-2">
                <div className="searchBox">
                  <Form.Control
                    placeholder="Search"
                    className={`${!insideKeyword ? "rounded-pill" : ""}`}
                    {...register("searchUserlist", {})}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    aria-label="Close"
                    type="Search projects"
                    value={searchText}
                    onKeyDown={KeyPressed}
                    autoFocus
                    required
                  />
                  <GrClose
                    className="searchClose"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Cancel search"
                    onClick={() => {
                      setSearchText("");
                      setShowSearchButton(false);
                    }}
                  />
                </div>
                {/* <p className='error invalid-feedback d-block h-30'>
                    <span className='text-danger'>
                      <ErrorMessage errors={errors} name="searchUserlist" />
                    </span>
                  </p> */}
              </Col>
            </Fragment>
          )}
        </Fragment>
      )}

      <Fragment>
        {insideKeyword ? (
          <Fragment>
            <div className="flex-shrink-1">
              <SearchButton />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <SearchButton />
          </Fragment>
        )}
      </Fragment>
    </>
  );
}
