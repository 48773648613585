/* eslint-disable */
import React, { useState } from "react";
import {
  Modal,
  TextInput,
  Text,
  Group,
  Divider,
  ActionIcon,
  Center,
  ScrollArea,
  Button,
  Badge,
  Flex,
} from "@mantine/core";
import SnippetEntry from "../../SnippetEntry";
import { IconPlus } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { toast } from "react-toastify";

import { useForm } from "react-hook-form"; // Import useForm for form handling
import { AxiosPostWithToken } from "../../../../Utils/Api";

interface CreateBookmarkModalProps {
  opened: boolean;
  onClose: () => void;
  refreshBookmarks: () => void;
}

const CreateBookmarkModal: React.FC<CreateBookmarkModalProps> = ({
  opened,
  onClose,
  refreshBookmarks,
}) => {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [snippets, setSnippets] = useState([
    { title: "", type: "plain-text", content: "" },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const handleSnippetUpdate = (index: number, updatedSnippet: any) => {
    const newSnippets = snippets.map((snippet, i) =>
      i === index ? updatedSnippet : snippet
    );
    setSnippets(newSnippets);
  };

  const handleSnippetDelete = (index: number) => {
    setSnippets(snippets.filter((_, i) => i !== index));
  };

  const addNewSnippet = () => {
    setSnippets([...snippets, { title: "", type: "plain-text", content: "" }]);
  };

  const onSubmit = async (data: any) => {
    if (
      snippets.length === 0 ||
      snippets.every((snippet) => !snippet.content.trim())
    ) {
      toast.error("Please add and fill at least one snippet.");
      return;
    }

    setLoading(true);

    const bookmarkData = {
      name: data.name,
      snippets: snippets.filter((snippet) => snippet.content.trim()), // Filter out empty snippets
    };

    try {
      await AxiosPostWithToken("/bookmarks/create", bookmarkData, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      toast.success("Bookmark created successfully!");
      refreshBookmarks();
      onClose();
    } catch (error) {
      toast.error("Failed to create bookmark.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Text size="xl" fw={700} c={"#333"}>
          Create New Bookmark
        </Text>
      }
      size="70%"
      fullScreen={isMobile}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      centered
      radius={15}
    >
      <Divider />
      <ScrollArea
        mt="xs"
        style={{ height: isMobile ? "78vh" : "65vh" }}
        type="auto"
      >
        <TextInput
          label={
            <Text size="lg" mb={3} fw={600} c={"#555"}>
              Bookmark Name
            </Text>
          }
          placeholder="Enter bookmark name"
          size="md"
          {...register("name", { required: "Name is required" })}
        />
        {errors.name && <Text color="red">{String(errors.name.message)}</Text>}
        <Flex justify="flex-start" gap="xs" align="center" mt="md" mb="xs">
          <Text size="lg" fw={600} c={"#555"}>
            Snippets
          </Text>
          <Badge variant="default" circle size="sm">
            {snippets.length}
          </Badge>
        </Flex>
        {snippets.map((snippet, index) => (
          <SnippetEntry
            key={index}
            index={index}
            entry={snippet}
            onUpdate={handleSnippetUpdate}
            onDelete={handleSnippetDelete}
          />
        ))}
        <Group mt="md">
          <Center w={"100%"}>
            <ActionIcon
              size="xl"
              radius="xl"
              variant="light"
              onClick={addNewSnippet}
            >
              <IconPlus size={24} />
            </ActionIcon>
          </Center>
        </Group>
      </ScrollArea>
      <Divider my="sm" />
      <Flex mt="md" justify="flex-end" gap="xs" align="center">
        <Button variant="default" radius={"md"} onClick={onClose}>
          Cancel
        </Button>
        <Button
          radius="md"
          onClick={handleSubmit(onSubmit)}
          loading={loading}
          loaderProps={{ type: "dots" }}
        >
          Create
        </Button>
      </Flex>
    </Modal>
  );
};

export default CreateBookmarkModal;
