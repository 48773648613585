/* eslint-disable */
import Pagination from "../../Components/Pagination";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
import SubHeader from "../../Components/SubHeader/SubHeader";
import { AxiosGetWithToken } from "../../Utils/Api";
import { EditSupportTicket } from "../../Components/Modal/EditSupportTicket";
import { RiSortDesc, RiSortAsc } from "react-icons/ri";
import "./index.css";

interface Header {
  Authorization: string | null;
}
// eslint-disable-next-line no-unused-vars
enum StatusType {
  // eslint-disable-next-line no-unused-vars
  All = "All",
  // eslint-disable-next-line no-unused-vars
  Open = "Open",
  // eslint-disable-next-line no-unused-vars
  Closed = "Closed",
}
interface supportTicketDataTypes {
  EMAIL: string;
  ID: string;
  REQUEST_TYPE: string;
  REQUEST_DETAIL: string;
  STATUS: string;
  CREATED_ON: string;
  NOTES: string;
  UPDATED_ON: string;
}
interface supportTicketDataAsProps {
  email: string;
  id: string;
  requestType: string;
  requestDetails: string;
  status: string;
  createdOn: string;
  notes: string;
  updatedOn: string;
}

const ManagesSupportRequest = () => {
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const [data, setData] = useState<Array<supportTicketDataTypes>>([]);
  const [supportRequests, setSupportRequests] = useState<
    Array<supportTicketDataTypes>
  >([]);

  // modal open close state
  const [showEditSupportTickets, setEditSupportTickets] =
    useState<boolean>(false);

  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // filter all,closed,open status state
  const [filterIndex, setFilterIndex] = useState<number>();

  // props state
  const [supportTicketData, setSupportTicketData] =
    useState<supportTicketDataAsProps>({
      email: "",
      id: "",
      requestType: "",
      requestDetails: "",
      status: "",
      createdOn: "",
      notes: "",
      updatedOn: "",
    });

  // sorting index and column state
  const [sortState, setSortState] = useState({
    ascTicket: true,
    ascEmail: true,
    ascRequestType: true,
    ascStatus: true,
    ascCreatedOn: true,
  });
  const [index, setIndex] = useState<number>(4);

  useEffect(() => {
    if (headers?.Authorization) {
      getAllSupportRequests();
    }
  }, []);

  const getAllSupportRequests = () => {
    AxiosGetWithToken("/getAllSupportRequests", { headers }).then(
      (response) => {
        setData(response.data.data);
        setSupportRequests(response.data.data);
      }
    );
  };

  const updateSupportRequest = (
    id,
    email,
    requestType,
    status,
    createdOn,
    requestDetails,
    updatedOn,
    notes
  ) => {
    setSupportTicketData({
      id,
      email,
      requestType,
      status,
      createdOn,
      requestDetails,
      updatedOn,
      notes,
    });
    setEditSupportTickets(!showEditSupportTickets);
  };

  // sorting
  const handleSort = (sortIndex) => {
    setPage(0);

    if (sortIndex === 0) {
      setSortState((prevState) => ({
        ...prevState,
        ascTicket: index !== sortIndex ? false : !sortState.ascTicket,
      }));
      supportRequests.sort(
        (a: supportTicketDataTypes, b: supportTicketDataTypes) =>
          a.ID > b.ID && (index !== sortIndex || sortState.ascTicket) ? 1 : -1
      );
      setSupportRequests([...supportRequests]);
    }

    if (sortIndex === 1) {
      setSortState((prevState) => ({
        ...prevState,
        ascEmail: index !== sortIndex ? false : !sortState.ascEmail,
      }));
      supportRequests.sort(
        (a: supportTicketDataTypes, b: supportTicketDataTypes) =>
          a.EMAIL.toLowerCase() > b.EMAIL.toLowerCase() &&
          (index !== sortIndex || sortState.ascEmail)
            ? 1
            : -1
      );
      setSupportRequests([...supportRequests]);
    }

    if (sortIndex === 2) {
      setSortState((prevState) => ({
        ...prevState,
        ascRequestType: index !== sortIndex ? false : !sortState.ascRequestType,
      }));
      supportRequests.sort(
        (a: supportTicketDataTypes, b: supportTicketDataTypes) =>
          a.REQUEST_TYPE.toLowerCase() > b.REQUEST_TYPE.toLowerCase() &&
          (index !== sortIndex || sortState.ascRequestType)
            ? 1
            : -1
      );
      setSupportRequests([...supportRequests]);
    }

    if (sortIndex === 4) {
      setSortState((prevState) => ({
        ...prevState,
        ascCreatedOn: index !== sortIndex ? false : !sortState.ascCreatedOn,
      }));
      supportRequests.sort(
        (a: supportTicketDataTypes, b: supportTicketDataTypes) =>
          a.CREATED_ON > b.CREATED_ON &&
          (index !== sortIndex || sortState.ascCreatedOn)
            ? 1
            : -1
      );
      setSupportRequests([...supportRequests]);
    }
    if (sortIndex === 3) {
      setSortState((prevState) => ({
        ...prevState,
        ascStatus: index !== sortIndex ? false : !sortState.ascStatus,
      }));
      supportRequests.sort(
        (a: supportTicketDataTypes, b: supportTicketDataTypes) =>
          a.STATUS > b.STATUS && (index !== sortIndex || sortState.ascStatus)
            ? 1
            : -1
      );
      setSupportRequests([...supportRequests]);
    }

    setIndex(sortIndex);
  };

  // for display status wise request types

  const onClick = (index) => {
    let selectedIndex;

    index === filterIndex ? (selectedIndex = "") : (selectedIndex = index);

    setFilterIndex(selectedIndex);
    setPage(0);

    const filteredSupportRequests = data.filter(
      (supportRequest: supportTicketDataTypes) => {
        if (selectedIndex === 0) {
          return supportRequest.STATUS === StatusType.Open;
        } else if (selectedIndex === 1) {
          return supportRequest.STATUS === StatusType.Closed;
        } else {
          return supportRequest.STATUS;
        }
      }
    );
    setSupportRequests(filteredSupportRequests);
  };

  const supportTicketStatusButtons = [
    {
      name: StatusType.Open,
      variant: "outline-red",
      selectedVariant: "red",
      index,
    },
    {
      name: StatusType.Closed,
      variant: "outline-green",
      selectedVariant: "green",
      index,
    },
  ];
  return (
    <Fragment>
      <CommonHeader />

      <SubHeader
        supportTicketStatusButtons={supportTicketStatusButtons}
        showStatusButtons={true}
        setShow={false}
        showNavbar={true}
        navigateUrl={"/home"}
        brand={"Support tickets "}
        showSearch={false}
        showEdit={false}
        onClickStatus={onClick}
        SelectedStatusButtonIndex={filterIndex}
        showBackButton={true}
      />

      <div className="card border-0 shadow rounded m-4">
        <div className="card-body pt-4">
          <div className="d-flex flex-wrap w-100 mb-4">
            <h4 className="flex-fill flex-shrink-1">Support tickets list</h4>
          </div>

          <Table className="table-general  mt-3 table-bordered">
            <thead>
              <tr>
                <th className="py-3" scope="row">
                  <span
                    className={index === 0 ? "sorting active" : "sorting"}
                    onClick={() => {
                      handleSort(0);
                    }}
                  >
                    TICKET
                    <button className="btn-sort">
                      {index !== 0 ? (
                        <RiSortDesc />
                      ) : !sortState.ascTicket ? (
                        <RiSortDesc />
                      ) : (
                        <RiSortAsc />
                      )}
                    </button>
                  </span>
                </th>
                <th className="py-3" scope="col">
                  <span
                    className={index === 1 ? "sorting active" : "sorting"}
                    onClick={() => {
                      handleSort(1);
                    }}
                  >
                    USER EMAIL
                    <button className="btn-sort">
                      {index !== 1 ? (
                        <RiSortDesc />
                      ) : !sortState.ascEmail ? (
                        <RiSortDesc />
                      ) : (
                        <RiSortAsc />
                      )}
                    </button>
                  </span>
                </th>
                <th className="py-3" scope="col">
                  <span
                    className={index === 2 ? "sorting active" : "sorting"}
                    onClick={() => {
                      handleSort(2);
                    }}
                  >
                    REQUEST TYPE
                    <button className="btn-sort">
                      {index !== 2 ? (
                        <RiSortDesc />
                      ) : !sortState.ascRequestType ? (
                        <RiSortDesc />
                      ) : (
                        <RiSortAsc />
                      )}
                    </button>
                  </span>
                </th>
                <th className="py-3" scope="col">
                  <span
                    className={index === 3 ? "sorting active" : "sorting"}
                    onClick={() => {
                      handleSort(3);
                    }}
                  >
                    STATUS
                    <button className="btn-sort">
                      {index !== 3 ? (
                        <RiSortDesc />
                      ) : !sortState.ascStatus ? (
                        <RiSortDesc />
                      ) : (
                        <RiSortAsc />
                      )}
                    </button>
                  </span>
                </th>
                <th className="py-3" scope="col">
                  <span
                    className={index === 4 ? "sorting active" : "sorting"}
                    onClick={() => {
                      handleSort(4);
                    }}
                  >
                    CREATED ON
                    <button className="btn-sort">
                      {index !== 4 ? (
                        <RiSortDesc />
                      ) : !sortState.ascCreatedOn ? (
                        <RiSortDesc />
                      ) : (
                        <RiSortAsc />
                      )}
                    </button>
                  </span>
                </th>
              </tr>
            </thead>

            {supportRequests.length === 0 && (
              <tbody>
                <tr>
                  <td colSpan={2}>No support tickets Found</td>
                </tr>
              </tbody>
            )}
            {supportRequests
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((supportRequest: supportTicketDataTypes, index) => (
                <tbody key={index}>
                  <tr>
                    <td data-label="TICKET" className="fw-bold">
                      <span>
                        <a
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            updateSupportRequest(
                              supportRequest.ID,
                              supportRequest.EMAIL,
                              supportRequest.REQUEST_TYPE,
                              supportRequest.STATUS,
                              supportRequest.CREATED_ON,
                              supportRequest.REQUEST_DETAIL,
                              supportRequest.UPDATED_ON,
                              supportRequest.NOTES
                            );
                          }}
                        >
                          {supportRequest.ID}
                        </a>
                      </span>
                    </td>
                    <td data-label="USER EMAIL" className="fw-bold">
                      <span>
                        <a
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            updateSupportRequest(
                              supportRequest.ID,
                              supportRequest.EMAIL,
                              supportRequest.REQUEST_TYPE,
                              supportRequest.STATUS,
                              supportRequest.CREATED_ON,
                              supportRequest.REQUEST_DETAIL,
                              supportRequest.UPDATED_ON,
                              supportRequest.NOTES
                            );
                          }}
                        >
                          {supportRequest.EMAIL}
                        </a>
                      </span>
                    </td>
                    <td data-label="REQUEST TYPE">
                      <span>{supportRequest.REQUEST_TYPE}</span>
                    </td>
                    <td
                      data-label="STATUS"
                      className={
                        supportRequest.STATUS === "Open"
                          ? "openTickets  "
                          : "closedTickets "
                      }
                    >
                      <span>{supportRequest.STATUS}</span>
                    </td>
                    <td data-label="CREATED ON">
                      <span>
                        {moment(supportRequest.CREATED_ON).format(
                          "MMM DD, YYYY hh:mm a"
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              ))}
          </Table>
        </div>

        {supportRequests.length > 10 && (
          <Pagination
            data={supportRequests}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>
      <EditSupportTicket
        showEditSupportTickets={showEditSupportTickets}
        setEditSupportTickets={setEditSupportTickets}
        supportTicketData={supportTicketData}
        setSupportTicketData={setSupportTicketData}
        getAllSupportRequests={getAllSupportRequests}
      />
    </Fragment>
  );
};

export default ManagesSupportRequest;
