import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AxiosPostWithToken } from '../../Utils/Api';

interface propsTypes
{
  showVIPUserModal: boolean;
  setShowVIPUserModal:Function;
  getAllUsersList:Function;
  setLoading:Function;
}

interface Header { Authorization: string | null }

export const VIPUser = (props:propsTypes) => {
  const { showVIPUserModal, setShowVIPUserModal, getAllUsersList, setLoading } = props;

  const headers: Header = { Authorization: localStorage.getItem('accessToken') };

  const { register, handleSubmit, clearErrors, formState: { errors }, reset } = useForm();

  const handleClose = () => {
    clearErrors();
    reset();
    setShowVIPUserModal(!showVIPUserModal);
  };

  const vipUserCreateApi = (data) => {
    if (headers?.Authorization) {
      AxiosPostWithToken('/user/admin-create-user', { email: data.email, password: data.password, firstName: data.firstName, lastName: data.lastName }, { headers })
        .then((response) => {
          setShowVIPUserModal(!showVIPUserModal);
          getAllUsersList();
          toast.success(response.data.message);
        })
        .catch(() => {
          toast.error('Already user signup with this email.');
          getAllUsersList();
        });
    }
  };

  const createVIPUser = handleSubmit((data) => {
    handleClose();
    setLoading(true);
    vipUserCreateApi(data);
  });

  return (
    <>
      <Modal show={showVIPUserModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{ 'Create VIP user'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>First Name<span className='text-danger'>*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter first name"
                maxLength={25}
                {...register('firstName', {
                  required: 'First name is required.',
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message: 'special characters not allowed'
                  },
                  minLength: {
                    value: 1,
                    message: 'Please enter a value.'
                  }
                  // maxLength: {
                  //   value: 25,
                  //   message: 'Please enter a value lesser than 26 characters.'
                  // }
                })}
                autoFocus
              />
              <div className='col-12 textfield'>
                <p className='error invalid-feedback d-block h-30'>
                  <span className='text-danger'>
                    <ErrorMessage errors={errors} name="firstName" />
                  </span>
                </p>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label className='mt-2'>Last Name<span className='text-danger'>*</span></Form.Label>
              <Form.Control placeholder="Enter last name"
                type="text"
                maxLength={25}
                {...register('lastName', {
                  required: 'Last name is required.',
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message: 'special characters not allowed'
                  },
                  minLength: {
                    value: 1,
                    message: 'Please enter a value.'
                  }
                  // maxLength: {
                  //   value: 25,
                  //   message: 'Please enter a value lesser than 26 characters.'
                  // }
                })}
              />
              <div className='col-12 textfield'>
                <p className='error invalid-feedback d-block h-30'>
                  <span className='text-danger'>
                    <ErrorMessage errors={errors} name="lastName" />
                  </span>
                </p>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label className='mt-2'>Email<span className='text-danger'>*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email"
                {...register('email', {
                  required: 'Email  is required.',
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email.'
                  }
                })}
              />
              <div className='col-12 textfield'>
                <p className='error invalid-feedback d-block h-30'>
                  <span className='text-danger'>
                    <ErrorMessage errors={errors} name="email" />
                  </span>
                </p>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label className='mt-2'>Password<span className='text-danger'>*</span></Form.Label>
              <Form.Control
                type='password'
                placeholder="Enter password"
                {...register('password', {
                  required: 'Password is required.',
                  minLength: {
                    value: 8,
                    message: 'Please enter at least 8 character password.'
                  },

                  pattern: {
                    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
                    message: 'Password must have at least one lowercase, uppercase, special, and numeric character.'
                  }
                })}

              />
              <div className='col-12 textfield'>
                <p className='error invalid-feedback d-block h-30'>
                  <span className='text-danger'>
                    <ErrorMessage errors={errors} name="password" />
                  </span>
                </p>
              </div>
            </Form.Group>
            <div className="mt-5 border-0">

              <button onClick={(e) => { e.preventDefault(); createVIPUser(); }}className="float-start btn btn-primary border-0 mt-1" type='button'>
                Create
              </button>

            </div>
          </Form>
        </Modal.Body>

      </Modal></>
  );
};
