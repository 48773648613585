import React, { useEffect } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { dateFormat } from '../../Utils/helper';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import { AxiosPutWithToken } from '../../Utils/Api';

// eslint-disable-next-line no-unused-vars
enum StatusType {
  // eslint-disable-next-line no-unused-vars
  Open = 'Open',
  // eslint-disable-next-line no-unused-vars
  Closed = 'Closed',

};
interface supportTicketDataTypes
{
  email:string;
  id:string;
  requestType:string;
  requestDetails:string;
  status:string;
  createdOn:string;
  notes:string
  updatedOn:string

}
interface propsTypes{
  showEditSupportTickets:boolean
  setEditSupportTickets:Function
  supportTicketData: supportTicketDataTypes
  setSupportTicketData:Function
  getAllSupportRequests:Function
}

interface Header { Authorization: string | null }

export const EditSupportTicket = (props: propsTypes) => {
  const headers: Header = { Authorization: localStorage.getItem('accessToken') };

  const { showEditSupportTickets, setEditSupportTickets, supportTicketData, getAllSupportRequests } = props;

  const handleClose = () => { setEditSupportTickets(!showEditSupportTickets); clearErrors(); };

  const { register, control, formState: { errors }, handleSubmit, setValue, reset, clearErrors } = useForm();

  const userDetail = useSelector((state: { UserReducer: { userDetail: { FIRST_NAME: string; } } }) => state.UserReducer.userDetail);

  const saveRequestTicket = handleSubmit((data, event) => {
    event?.preventDefault();
    handleClose();
    if (headers?.Authorization) {
      // delete data.updatedBy;
      AxiosPutWithToken(`/updateSupportRequest/${supportTicketData?.id}`, { data }, { headers })
        .then((response) => {
          toast.success(response.data.message);
          getAllSupportRequests();
          setEditSupportTickets(!showEditSupportTickets);
          clearErrors();
          reset();
        }).catch((error) => {
          toast.error(error.data.message);
          setEditSupportTickets(!showEditSupportTickets);
        });
    }
  });

  useEffect(() => {
    setValue('email', supportTicketData?.email);
    setValue('ticket_id', supportTicketData?.id);
    setValue('request_type', supportTicketData?.requestType);
    setValue('requestDetails', supportTicketData.requestDetails);
    setValue('createdOn', moment(supportTicketData.createdOn).format(dateFormat));
    setValue('status', supportTicketData.status);
    setValue('updatedBy', userDetail.FIRST_NAME);
    setValue('notes', supportTicketData.notes);
  }, [supportTicketData]);

  return (
    <>
      <Modal show={showEditSupportTickets} onHide={handleClose} size="lg" centered >
        <Modal.Header closeButton>
          <Modal.Title>Edit ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Ticket#</Form.Label>
                  <Form.Control type="text" placeholder="Enter Ticket Id" disabled {...register('ticket_id', { required: 'Request type is required.' })} />
                </Form.Group></Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>User email</Form.Label>
                  <Form.Control disabled type="email" placeholder="Enter Email"
                    {...register('email', {
                      required: 'Please enter a valid email.',
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Please enter a valid email.'
                      }
                    })}
                  />
                  <div className='col-12 textfield'>
                    <p className='error invalid-feedback d-block h-30'>
                      <span className='text-danger'>
                        <ErrorMessage errors={errors} name="email" />
                      </span>
                    </p>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Request Type</Form.Label>
                  <Form.Control disabled type="text" placeholder="Enter request type"
                    maxLength={255}
                    {...register('request_type', {
                      required: 'Request type is required.',
                      minLength: {
                        value: 1,
                        message: 'Please enter a value.'
                      }
                    // maxLength: { value: 256, message: ' Please enter less than 256 words' }
                    })} />
                  <div className='col-12 textfield'>
                    <p className='error invalid-feedback d-block h-30'>
                      <span className='text-danger'>
                        <ErrorMessage errors={errors} name="request_type" />
                      </span>
                    </p>
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select className='form-control' placeholder="Select" {...register('status', { required: 'status is required' })}>
                    <option value={StatusType.Open}>{StatusType.Open}</option>
                    <option value={StatusType.Closed}>{StatusType.Closed}</option>

                  </Form.Select>
                  <div className='col-12 textfield'>
                    <p className='error invalid-feedback d-block h-30'>
                      <span className='text-danger'>
                        <ErrorMessage errors={errors} name="status" />
                      </span>
                    </p>
                  </div>
                </Form.Group>

              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Request details</Form.Label>
                  <Form.Control disabled as="textarea" rows={3} {...register('requestDetails', { maxLength: { value: 1024, message: ' Please enter less than 1025 words' } })} />
                  <div className='col-12 textfield'>
                    <p className='error invalid-feedback d-block h-30'>
                      <span className='text-danger'>
                        <ErrorMessage errors={errors} name="requestDetails" />
                      </span>
                    </p>
                  </div>
                </Form.Group></Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control as="textarea" rows={3}
                    maxLength={1024}
                    {...register('notes'
                      // { maxLength: { value: 1024, message: ' Please enter less than 1025 words' } }
                    )}
                  />
                  <div className='col-12 textfield'>
                    <p className='error invalid-feedback d-block h-30'>
                      <span className='text-danger'>
                        <ErrorMessage errors={errors} name="notes" />
                      </span>
                    </p>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className='col-4'>
                <Form.Group className="mb-3">
                  <Form.Label>Created on</Form.Label>
                  <Controller
                    control={control}
                    name='createdOn'
                    // rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          disabled
                          dateFormate={dateFormat}
                          placeholderText={dateFormat}
                          onKeyDown={(e) => { e.preventDefault(); }}
                          value={supportTicketData?.createdOn ? moment(supportTicketData?.createdOn).format(dateFormat) : null}
                          className={'form-control '}
                        />);
                    }}
                  />
                </Form.Group>
              </Col>

              <Col className='col-4'>
                <Form.Group
                  className="mb-3" >
                  <Form.Label>Updated on</Form.Label>
                  <Controller
                    control={control}
                    name='updatedOn'
                    // rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          disabled
                          dateFormate={dateFormat}
                          placeholderText={dateFormat}
                          onKeyDown={(e) => { e.preventDefault(); }}
                          value={supportTicketData?.updatedOn ? moment(supportTicketData?.updatedOn).format(dateFormat) : null}
                          className={'form-control'}
                        />);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col className='col-4'>
                <Form.Group className="mb-3" >
                  <Form.Label>Updated by</Form.Label>
                  <Form.Control type="text" disabled placeholder="Enter Updated by" {...register('updatedBy', { required: 'Updated by is required.' })} />
                </Form.Group>
              </Col>
            </Row>

          </Form>
          <button onClick={saveRequestTicket} className="float-start btn btn-primary border-0 mt-1" type='button'>
            Save
          </button>
        </Modal.Body>

      </Modal>
    </>
  );
};
