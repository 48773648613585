interface keywordDetail
{
  keywordList: Array<string>,
  keywordDetail: object;
  type:string;
  payload
}
const initialState = {
  keywordList: [],
  keywordDetail: {}
};

export const KeywordListReducer = (state = initialState, action: keywordDetail) => {
  switch (action.type) {
  case 'KEYWORD_LIST':
    return {
      keywordList: action.payload
    };
  case 'KEYWORD_DETAIL':
    return {
      keywordDetail: action.payload
    };
  default:
    return state;
  }
};
