import React, { useEffect, useState } from 'react';
import {
  connect, useSelector
} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { AxiosGetWithToken, AxiosPostWithToken } from '../../Utils/Api';
import '../../assets/css/style.scss';

interface Header { Authorization: string | null }

interface propsType { showSupportModal: boolean; setShowSupportModal: Function }

interface supportRequestResponse extends AxiosResponse { ID?: number };

interface supportRequestTypeList { DETAILS: string | undefined, ID: string, REQUEST_TYPE: string }

interface supportRequestsArray{ allSupportRequestTypes: supportRequestTypeList[]; }

interface supportRequestTypesData { data: supportRequestsArray; message: string; status: number; }

interface supportRequestTypeResponse extends AxiosResponse { data: supportRequestTypesData; }

export const SupportRequest = (props: propsType) => {
  const { showSupportModal, setShowSupportModal } = props;

  const headers: Header = { Authorization: localStorage.getItem('accessToken') };

  const userDetail = useSelector((state: { UserReducer: { userDetail: { EMAIL: string; }}}) => state.UserReducer.userDetail);

  const { register, handleSubmit, control, formState: { errors }, reset, setValue, resetField, clearErrors } = useForm();

  const [requestTypes, setRequestTypes] = useState<Array<supportRequestTypeList>>([]);

  const [requestTypeId, setRequestTypeId] = useState<string>();

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [confirmationDialogTitle, setConfirmationDialogTitle] = useState('');
  const [confirmationDialogText, setConfirmationDialogText] = useState('');

  useEffect(() => {
    // Get all request types
    if (showSupportModal) {
      AxiosGetWithToken('/supportRequestTypeList', {})
        .then((response: supportRequestTypeResponse) => {
          setRequestTypes(response.data.data.allSupportRequestTypes);
          if (!location.pathname.includes('login') && !location.pathname.includes('signup') && !location.pathname.includes('forgot-password')) {
            setValue('email', userDetail?.EMAIL);
          }
        });
    }
  }, [showSupportModal]);

  const setSupportRequestMessage = (responseData, responseDataMessage) => {
    setConfirmationDialogTitle(`Ticket ${responseData}`);
    setConfirmationDialogText(responseDataMessage);
    setShowConfirmationDialog(true);
  };

  const createPreLoginSupportRequest = (bodyData) => {
    if (!location.pathname.includes('login') && !location.pathname.includes('signup') && !location.pathname.includes('forgot-password')) {
      AxiosPostWithToken('/supportRequestPostLogin/create', { bodyData }, { headers })
        .then((response: supportRequestResponse) => {
          setSupportRequestMessage(response.data.data, response.data.message);
        }).catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      AxiosPostWithToken('/supportRequestPreLogin/create', { bodyData }, { headers })
        .then((response: supportRequestResponse) => {
          setSupportRequestMessage(response.data.data, response.data.message);
        }).catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const addSupportRequest = handleSubmit((data, event) => {
    event?.preventDefault();
    const postData = { ...data, requestTypeId };
    createPreLoginSupportRequest(postData);
    setShowSupportModal(!showSupportModal);
    setRequestTypeId('');
    if (!location.pathname.includes('login') && !location.pathname.includes('signup') && !location.pathname.includes('forgot-password')) {
      setValue('email', userDetail?.EMAIL); resetField('requestType'); resetField('requestDetail'); clearErrors('email');
    } else { reset(); }
  });

  const handleClose = () => {
    setShowSupportModal(!showSupportModal);
    setRequestTypeId('');
    if (!location.pathname.includes('login') && !location.pathname.includes('signup') && !location.pathname.includes('forgot-password')) {
      setValue('email', userDetail?.EMAIL); resetField('requestType'); resetField('requestDetail'); clearErrors('email');
    } else { reset(); }
  };

  const findDetailsFromSelectedValue = (value:string) => {
    if (!value) {
      setValue('requestDetail', '');
      setValue('requestType', '');
    } else {
      const getDetails: any = requestTypes.find((requestType: supportRequestTypeList) => requestType.REQUEST_TYPE === value);
      setRequestTypeId(getDetails.DETAILS.ID);
      setValue('requestDetail', getDetails.DETAILS);
      setRequestTypeId(getDetails.ID);
    }
  };

  const pressedEnter = (e: any) => {
    if (e.key === 'Enter') {
      addSupportRequest();
      e.preventDefault();
    }
  };

  return (
    <>
      <Modal show={showSupportModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Request support</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0'>
          <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>User email<span className='text-danger'>*</span></Form.Label>
              <Form.Control
                type="email"
                onKeyPress={pressedEnter}
                placeholder="Enter Email"
                {...register('email', {
                  required: 'Please enter a valid email.',
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email.'
                  }
                })}
                autoFocus
                disabled={!location.pathname.includes('login') && !location.pathname.includes('signup') && !location.pathname.includes('forgot-password') && true}
              />
              <div className='col-12 textfield'>
                <p className='error invalid-feedback d-block h-30'>
                  <span className='text-danger'>
                    <ErrorMessage errors={errors} name="email" />
                  </span>
                </p>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label className='mt-2'>Request type<span className='text-danger'>*</span></Form.Label>
              <Controller
                control={control}
                name='requestType'
                rules={{ required: 'Request type is required' }}
                render={({ field }) => {
                  return (
                    <select className="form-select"
                      onChange={(e) => { findDetailsFromSelectedValue(e.target.value); field.onChange(e); }} >
                      <option value='' >Select request type</option>
                      {requestTypes.map((requestType: supportRequestTypeList, index:number) => (
                        <option key={index} value={requestType.REQUEST_TYPE}>{requestType.REQUEST_TYPE}</option>
                      ))}
                    </select>
                  );
                }}
              />
              <div className='col-12 textfield'>
                <p className='error invalid-feedback d-block h-30'>
                  <span className='text-danger'>
                    <ErrorMessage errors={errors} name="requestType" />
                  </span>
                </p>
              </div>
            </Form.Group>
            <Form.Group className='mt-3' >
              <Form.Label className='mt-2'>Request details</Form.Label>
              <Form.Control as="textarea" rows={5}
                maxLength={1024}
                {...register('requestDetail'
                  // { maxLength: { value: 1024, message: ' Please enter less than 1025 words' } }
                )}
                id='textarea-resize'/>
              <div className=' textfield'>
                <p className='error invalid-feedback d-block h-30'>
                  <span className='text-danger'>
                    <ErrorMessage errors={errors} name="requestDetail" />
                  </span>
                </p>
              </div>
              <button className="float-start btn btn-primary border-0 mt-1"
                onClick={addSupportRequest} type='button'>
                Send
              </button>
            </Form.Group>
          </Form>
        </Modal.Body>

      </Modal>

      {showConfirmationDialog && <ConfirmationDialog
        confirmButtonText='Delete'
        confirmDialogTitle={confirmationDialogTitle}
        show={showConfirmationDialog}
        setShow={setShowConfirmationDialog}
        onConfirm={setShowConfirmationDialog}
        message={confirmationDialogText}
        isConfirmButtonVisible={false}/>}
    </>
  );
};

function mapStateToProps (state) {
  return state;
}
export default connect(mapStateToProps)(SupportRequest);
