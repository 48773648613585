/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Text,
  Modal,
  ScrollArea,
  Divider,
  Flex,
  Button,
  Grid,
  TextInput,
  Select,
  Textarea,
  Card,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AxiosDeleteWithToken, AxiosPutWithToken } from "../../../../Utils/Api";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

interface Header {
  Authorization: string | null;
}

interface Snippet {
  _id: string;
  title: string;
  type: string;
  content: string;
  isBook?: boolean | undefined;
  bookName?: string | undefined;
  bookAuthor?: string | undefined;
  bookURL?: string | undefined;
}

interface SnippetModalProps {
  opened: boolean;
  onClose: () => void;
  refreshSnippets: () => void;
  snippet: Snippet | null;
  bookmarkId: string;
}

const ManageSnippetModal: React.FC<SnippetModalProps> = ({
  opened,
  onClose,
  refreshSnippets,
  snippet,
  bookmarkId,
}) => {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [editMode, setEditMode] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [contentType, setContentType] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [isBook, setIsBook] = useState<boolean>(false);
  const [bookName, setBookName] = useState<string>("");
  const [bookAuthor, setBookAuthor] = useState<string>("");
  const [bookURL, setBookURL] = useState<string>("");
  const [updating, setIsUpdating] = useState<boolean>(false);

  const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [editBookName, setEditBookName] = useState<string>("");
  const [editBookAuthor, setEditBookAuthor] = useState<string>("");
  const [editBookURL, setEditBookURL] = useState<string>("");

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  useEffect(() => {
    if (snippet) {
      setTitle(snippet.title);
      setContentType(snippet.type);
      setContent(snippet.content);
      if (snippet.isBook === true) {
        console.log("is book true");
        snippet.isBook === true ? setIsBook(snippet.isBook) : null;
        setEditBookName(snippet.bookName!);
        setEditBookAuthor(snippet.bookAuthor!);
        setEditBookURL(snippet.bookURL!);
      }
      console.log(snippet);
    }
  }, [snippet]);

  const handleContentChange = (newContent: string) => {
    setContent(newContent);
  };

  const renderContentInput = () => {
    switch (snippet?.type) {
      case "rich-text":
        return (
          <>
            {
              <Text size="md" mt="md" mb={3} fw={500} c={"#666"}>
                CONTENT
              </Text>
            }
            <ReactQuill
              className="react-quill-container"
              value={content}
              onChange={(value) => handleContentChange(value)}
            />
          </>
        );
      default:
        return (
          <Textarea
            mt="md"
            label={
              <Text size="md" mb={3} fw={500} c={"#666"}>
                CONTENT
              </Text>
            }
            placeholder="Enter content"
            autosize
            minRows={4}
            value={content}
            onChange={(e) => handleContentChange(e.currentTarget.value)}
          />
        );
    }
  };

  const handleUpdate = async () => {
    if (!snippet) return;
    const updatedSnippet = {
      title,
      type: contentType,
      content: content,
      bookName: editBookName,
      bookAuthor: editBookAuthor,
      bookURL: editBookURL,
      isBook: true,
    };

    setIsUpdating(true);

    try {
      await AxiosPutWithToken(
        `/bookmarks/${bookmarkId}/snippets/${snippet._id}`,
        updatedSnippet,
        { headers }
      );
      toast.success("Snippet updated successfully!");
      refreshSnippets();
      setIsUpdating(false);
      onClose();
      setEditMode(false);
    } catch (err) {
      console.error("Failed to update snippet", err);
      toast.error("Failed to update snippet!");
      setIsUpdating(false);
    }
  };

  const handleDeleteSnippet = async () => {
    setIsDeleting(true);
    try {
      await AxiosDeleteWithToken(
        `/bookmarks/${bookmarkId}/snippets/${snippet!._id}`,
        { headers }
      );
      toast.success("Snippet deleted successfully!");
      refreshSnippets();
      setIsDeleting(false);
      setDeleteModalOpened(false);
      onClose();
    } catch (err) {
      console.error("Failed to delete snippet", err);
      toast.error("Failed to delete snippet");
    }
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.3 },
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
        setIsUpdating(false);
        setEditMode(false);
      }}
      title={
        <Text size="xl" fw={700} c={"#333"}>
          {editMode ? `Edit snippet` : snippet?.title}
        </Text>
      }
      size="70%"
      fullScreen={isMobile}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      centered
      radius={15}
    >
      <Divider />
      <ScrollArea
        style={{ height: isMobile ? "78vh" : "65vh" }}
        mt={editMode ? "" : "xs"}
      >
        {editMode ? (
          <>
            <motion.div {...fadeInUp}>
              <Card p="md">
                <Grid>
                  <Grid.Col span={6}>
                    <TextInput
                      label={
                        <Text size="md" mb={3} fw={500} c={"#666"}>
                          SNIPPET TITLE
                        </Text>
                      }
                      placeholder="Enter title"
                      value={title}
                      onChange={(e) => setTitle(e.currentTarget.value)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Select
                      label={
                        <Text size="md" mb={3} fw={500} c={"#666"}>
                          CONTENT TYPE
                        </Text>
                      }
                      placeholder="Select type"
                      data={[
                        {
                          value: snippet?.type || "",
                          label:
                            snippet?.type === "rich-text"
                              ? "Rich Text"
                              : "Plain Text",
                        },
                      ]}
                      value={snippet?.type}
                    />
                  </Grid.Col>
                  {isBook == true && (
                    <>
                      <Grid.Col span={6}>
                        <TextInput
                          label={
                            <Text size="md" mb={3} fw={500} c={"#666"}>
                              BOOK NAME
                            </Text>
                          }
                          placeholder="Enter book name"
                          value={editBookName}
                          onChange={(e) =>
                            setEditBookName(e.currentTarget.value)
                          }
                        />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <TextInput
                          label={
                            <Text size="md" mb={3} fw={500} c={"#666"}>
                              BOOK AUTHOR
                            </Text>
                          }
                          placeholder="Enter book author"
                          value={editBookAuthor}
                          onChange={(e) =>
                            setEditBookAuthor(e.currentTarget.value)
                          }
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <TextInput
                          label={
                            <Text size="md" mb={3} fw={500} c={"#666"}>
                              BOOK URL
                            </Text>
                          }
                          placeholder="Enter book URL"
                          value={editBookURL}
                          onChange={(e) =>
                            setEditBookURL(e.currentTarget.value)
                          }
                        />
                      </Grid.Col>
                    </>
                  )}
                </Grid>
                {renderContentInput()}
              </Card>
            </motion.div>
          </>
        ) : (
          <>
            {isBook && (
              <span>{`Bookname: ${editBookName} | Author: ${editBookAuthor}`}</span>
            )}
            {snippet?.type === "plain-text" ? (
              snippet.content
            ) : (
              <ReactQuill
                value={snippet?.content}
                readOnly={true}
                theme="bubble"
              />
            )}
          </>
        )}
      </ScrollArea>
      <Divider my="sm" />
      <Flex mt="md" justify="flex-end" gap="xs" align="center">
        {editMode ? (
          <>
            <Button
              variant="default"
              radius="md"
              onClick={() => {
                setEditMode(false);
                setIsUpdating(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="filled"
              radius="md"
              onClick={handleUpdate}
              loading={updating}
              loaderProps={{ type: "dots" }}
            >
              Update
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="subtle"
              color="red"
              radius="md"
              onClick={() => setDeleteModalOpened(true)}
            >
              Delete
            </Button>
            <Button
              variant="default"
              radius="md"
              onClick={() => setEditMode(true)}
            >
              Edit
            </Button>
          </>
        )}
      </Flex>
      {/* Delete bookmark modal */}
      <Modal
        opened={deleteModalOpened}
        onClose={() => {
          setDeleteModalOpened(false);
          setIsDeleting(false);
        }}
        title={
          <Text size="xl" fw={700} c={"#333"}>
            Confirm Delete
          </Text>
        }
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        centered
        radius={15}
      >
        <Text size="sm">
          Are you sure you want to delete this snippet? This action cannot be
          undone.
        </Text>
        <Flex mt="md" justify="flex-end" gap="xs" align="center">
          <Button
            variant="default"
            radius="md"
            onClick={() => {
              setDeleteModalOpened(false);
              setIsDeleting(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="red"
            radius="md"
            loading={isDeleting}
            loaderProps={{ type: "dots" }}
            onClick={() => {
              handleDeleteSnippet();
            }}
          >
            Confirm
          </Button>
        </Flex>
      </Modal>
    </Modal>
  );
};

export default ManageSnippetModal;
