import React, { useEffect, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { AxiosPostWithToken } from '../../Utils/Api';
import Loader from '../../Components/Loader/loader';

interface propsTypes{
  showChangePasswordModal:boolean,
  setShowChangePasswordModal:Function
  userEmail:string | undefined,
}

interface Header {Authorization: string | null }

// eslint-disable-next-line no-unused-vars
enum StatusType {
  // eslint-disable-next-line no-unused-vars
  success = 'success',
  // eslint-disable-next-line no-unused-vars
  failed = 'failed',

};

export const PasswordReSet = (props: propsTypes) => {
  const { showChangePasswordModal, setShowChangePasswordModal, userEmail } = props;

  const headers: Header = { Authorization: localStorage.getItem('accessToken') };

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setValue('password', '');
    clearErrors();
    setShowChangePasswordModal(!showChangePasswordModal);
  };

  const { register, handleSubmit, clearErrors, setValue, formState: { errors }, reset } = useForm();

  useEffect(() => {
    clearErrors();
    setValue('password', '');
  }, []);

  const onPasswordReset = handleSubmit((data) => {
    if (headers?.Authorization) {
      setLoading(true);
      AxiosPostWithToken('/admin/user/change-password', { email: userEmail, password: data.password }, { headers })
        .then((response) => {
          setLoading(false);
          handleClose();
          toast.success(response.data.message);
        })
        .catch((error: Error) => {
          toast.error(error.message);
        });
    }
    clearErrors();
    reset();
  });

  return (
    <>
      {loading && (<Loader />)}
      <Modal show={showChangePasswordModal} onHide={handleClose} size="sm">
        <Modal.Header closeButton>
          <Modal.Title> Reset user password</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0'>
          <Form>
            <Form.Group>
              <Form.Label className='mt-2'>Email<span className='text-danger'>*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email"
                value={userEmail}
                disabled
              />
              <div className='col-12 textfield'>
                <p className='error invalid-feedback d-block h-30'>
                  <span className='text-danger'>
                    <ErrorMessage errors={errors} name="email" />
                  </span>
                </p>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label className='mt-2'>Password<span className='text-danger'>*</span></Form.Label>
              <Form.Control
                type='password'
                placeholder="Enter password"
                {...register('password', {
                  required: 'Password is required.',
                  minLength: {
                    value: 8,
                    message: 'Please enter at least 8 character password.'
                  },
                  pattern: {
                    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
                    message: 'Password must have at least one lowercase, uppercase, special, and numeric character.'
                  }
                })}

              />
              <div className='col-12 textfield'>
                <p className='error invalid-feedback d-block h-30'>
                  <span className='text-danger'>
                    <ErrorMessage errors={errors} name="password" />
                  </span>
                </p>
              </div>
            </Form.Group>
            <div className="mt-5 border-0">

              <button onClick={(e) => { e.preventDefault(); onPasswordReset(); }}className="float-start btn btn-primary border-0 mt-3" type='button'>
                Reset password
              </button>

            </div>
          </Form>
        </Modal.Body>

      </Modal>
    </>
  );
};
