import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import store from './Redux/store';
import App from './App';
import './assets/css/style.scss';
import '../src/assets/css//index.css';
import 'react-toastify/dist/ReactToastify.css';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <Provider store={store}>
      <CookiesProvider>
        <ToastContainer theme="colored" />
        <App />
      </CookiesProvider>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
