
const Initialstate = {
  userDetail: {}
};

export const UserReducer = (state = Initialstate, action) => {
  switch (action.type) {
  case 'USER_DETAILS':
    return {
      userDetail: action.payload
    };
  default:
    return state;
  }
};
