/* eslint-disable */
import React, { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import { AxiosPostWithToken, AxiosPutWithToken } from "../../Utils/Api";

interface passAsProps {
  requestType: string;
  requestDetail: string;
  Id: string;
}

interface propsTypeOfSupportRequestType {
  getSupportRequests: Function;
  showSupportRequestTypeModal: boolean;
  setShowSupportRequestTypeModal: Function;
  updateData: passAsProps;
  setState: Function;
}

interface Header {
  Authorization: string | null;
}

export const SupportRequestType = (props: propsTypeOfSupportRequestType) => {
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const {
    showSupportRequestTypeModal,
    setShowSupportRequestTypeModal,
    updateData,
    setState,
    getSupportRequests,
  } = props;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    setError,
  } = useForm();

  const userDetail = useSelector(
    (state: { UserReducer: { userDetail: { _id: string } } }) =>
      state.UserReducer.userDetail
  );

  const handleClose = () => {
    setState({});
    reset();
    setShowSupportRequestTypeModal(!showSupportRequestTypeModal);
  };

  useEffect(() => {
    if (updateData) {
      setValue("request_type", updateData?.requestType);
      setValue("details", updateData?.requestDetail);
    }
  }, [updateData]);

  const createRequestTypeApi = (data, headers) => {
    handleClose();
    if (headers?.Authorization) {
      AxiosPostWithToken(
        "/supportRequestType/create",
        {
          request_type: data.request_type,
          details: data.details,
          createdBy: userDetail._id,
        },
        { headers }
      )
        .then((response) => {
          toast.success(response.data.message);
          getSupportRequests();
          setState({});
          setShowSupportRequestTypeModal(!showSupportRequestTypeModal);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const updateRequestTypeApi = (data, headers) => {
    handleClose();
    if (headers?.Authorization) {
      AxiosPutWithToken(
        `/supportRequestType/update/${updateData?.Id}`,
        { request_type: data.request_type, details: data.details },
        { headers }
      )
        .then((response) => {
          toast.success(response.data.message);
          setState({});
          setShowSupportRequestTypeModal(!showSupportRequestTypeModal);
          getSupportRequests();
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const createRequestType = handleSubmit((data) => {
    if (data.request_type.trim() === "") {
      setError("request_type", {
        type: "custom",
        message: "Please enter a valid support request type.",
      });
    } else {
      if (
        Object.entries(updateData).length === 0 ||
        updateData?.requestType === "" ||
        updateData?.Id === "" ||
        updateData?.requestDetail === ""
      ) {
        createRequestTypeApi(data, headers);
      }
    }
  });

  const updateRequestType = handleSubmit((data) => {
    updateRequestTypeApi(data, headers);
  });

  return (
    <>
      <Modal show={showSupportRequestTypeModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {Object.entries(updateData).length === 0
              ? "Add support request type"
              : "Edit support request type"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>
                Request type<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Request type"
                maxLength={50}
                {...register("request_type", {
                  required: "Request type is required.",
                  minLength: {
                    value: 1,
                    message: "Please enter a value.",
                  },
                })}
                autoFocus
              />
              <div className="col-12 textfield">
                <p className="error invalid-feedback d-block h-30">
                  <span className="text-danger">
                    <ErrorMessage errors={errors} name="request_type" />
                  </span>
                </p>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label className="mt-2">Request details</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                maxLength={1024}
                {...register(
                  "details"
                  // { maxLength: { value: 1024, message: ' Please enter less than 1025 words' } }
                )}
                id="textarea-resize"
              />
              <div className="col-12 textfield mb-3">
                <p className="error invalid-feedback d-block h-30">
                  <span className="text-danger">
                    <ErrorMessage errors={errors} name="details" />
                  </span>
                </p>
              </div>
            </Form.Group>
            <div className="mt-4 border-0">
              {Object.entries(updateData).length === 0 ? (
                <button
                  onClick={createRequestType}
                  className="float-start btn btn-primary border-0 mt-1"
                  type="button"
                >
                  Save
                </button>
              ) : (
                <button
                  onClick={updateRequestType}
                  className="float-start btn btn-primary border-0 mt-1"
                  type="button"
                >
                  Update
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
