/* eslint-disable */
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader/loader";
import momLogo from "../../../assets/images/momlogo-transperant.png";
import abrahamLogo from "../../../assets/images/abrahamLogo-transperant.png";

import { AxiosGetWithToken, AxiosPostWithToken } from "../../../Utils/Api";
import { useLocation, useNavigate } from "react-router-dom";
interface data2 {
  status: number;
  message: string;
  data: [];
}

interface response extends AxiosResponse {
  data: data2;
}

interface data3 {
  status: number;
  message: string;
  data: string;
}
interface Header {
  Authorization: string | null;
}

interface responseCheckout extends AxiosResponse {
  data: data3;
}

export default function index() {
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const search = useLocation().search;

  const success = new URLSearchParams(search).get("success");
  const sessionId = new URLSearchParams(search).get("session_id");
  const canceled = new URLSearchParams(search).get("canceled");

  const [productList, setProductList] = useState([]);
  const [showPlan, setShowPlan] = useState(!success);

  const [stripeId, setStripeId] = useState<string>("");
  const [trial, setTrial] = useState<string>("");

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const isSignUp: string = sessionStorage.getItem("isSignUp") || "";

  const registerData: string = sessionStorage.getItem("registerUser") || "";

  const sessionUserData = registerData && JSON.parse(registerData);

  const createPaymentHistory = () => {
    const selectedPlanID: string = sessionStorage.getItem("PLAN_ID") || "";

    AxiosPostWithToken(
      "/payment-history/create",
      {
        PLAN_ID: parseInt(selectedPlanID),
        SESSION_ID: sessionId,
        USER_ID: sessionUserData?.ID,
        STATUS: "SUCCESS",
        AMOUNT: parseInt("0"),
      },
      { headers }
    )
      .then((response) => {
        if (response.status === 200) {
          setShowPlan(false);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!registerData) {
      navigate("/login");
    } else {
      if (productList?.length === 0 && !success) {
        AxiosGetWithToken("/plan/list", {}).then((response: response) => {
          const productList = response.data.data.filter(function (plan: any) {
            return plan.PLAN_NAME !== "VIP";
          });
          setLoading(false);
          setProductList(productList);
        });
      }

      if (success === "true" && sessionId) {
        createPaymentHistory();
      }

      if (canceled === "true") {
        setShowPlan(true);
      }
    }
  }, []);

  const onCheckoutSession = () => {
    setLoading(true);

    if (stripeId) {
      AxiosPostWithToken(
        "/stripe/create-checkout-session",
        { priceId: stripeId },
        { headers }
      )
        .then((response: responseCheckout) => {
          if (response.status === 200) {
            window.open(response.data.data, "_self");
          }
        })
        .catch((error: any) => {
          toast.error(error.response.data.message);

          setLoading(false);
        });
    } else {
      createPaymentHistory();
    }
  };

  const planList = (price, ind) => {
    return (
      <>
        <label key={`${ind}label`} className="label-title">
          {price?.PLAN_NAME}
        </label>
        <div
          key={`${ind}key`}
          className={`membership-block ${trial === price.ID && "active"}`}
          onClick={(e) => {
            e.preventDefault();
            setTrial(price.ID);
            setStripeId(price.STRIPE_PLAN_ID);
          }}
        >
          <div className="radio radio-primary">
            <input
              name="radio"
              value={price.ID}
              id={price.ID}
              type="radio"
              checked={trial === price.ID}
              onChange={(e) => {
                e.preventDefault();
                setTrial(price.ID);
              }}
            />
            <label htmlFor={price.ID}>
              {price?.STRIPE_PLAN_ID === undefined
                ? "14 Day free trial"
                : `$${price?.PLAN_AMOUNT}/${price.PLAN_NAME}`}
            </label>
            {price?.STRIPE_PLAN_ID !== undefined && (
              <p>
                ${price?.PLAN_AMOUNT}/{price.PLAN_NAME}
              </p>
            )}
          </div>
          {price?.PLAN_NAME === "Weekly" && (
            <label className="membership-tag">Recommended</label>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="memberShip-page bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="bg-light-md signup-block shadow rounded">
                  <div className="row">
                    <div className="col-md-4 d-none d-md-block">
                      <div className="form-left h-100 bg-primary brand-block text-white text-center py-5">
                        <div className="d-flex flex-column">
                          <div className="logo">
                            <img src={momLogo} alt="" />
                          </div>
                          <div className="gp-logo align-items-end py-2">
                            <img src={abrahamLogo} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="signup-card bg-white">
                        <div
                          className={`form-right membership-section ${
                            showPlan ? "d-block" : "d-none"
                          } h-100 py-4 px-4`}
                        >
                          <form className="row g-3">
                            <h2 className="mt-1 mb-3">Membership plans</h2>

                            <>
                              {productList?.length > 0 &&
                                productList.map(
                                  (
                                    price: {
                                      ID: string;
                                      PLAN_NAME: string;
                                      PLAN_AMOUNT: number;
                                      STRIPE_PLAN_ID: string;
                                    },
                                    ind: Number
                                  ) => {
                                    return (
                                      <div
                                        className="col-12 mb-2"
                                        key={`${ind}`}
                                      >
                                        {isSignUp === "true"
                                          ? planList(price, ind)
                                          : price?.STRIPE_PLAN_ID !== null &&
                                            planList(price, ind)}
                                      </div>
                                    );
                                  }
                                )}
                            </>

                            <div className="col-12">
                              <button
                                type="button"
                                className="btn btn-primary border-0 float-start mt-2 me-3"
                                disabled={trial === ""}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sessionStorage.setItem("PLAN_ID", trial);
                                  onCheckoutSession();
                                }}
                              >
                                {" "}
                                Continue{" "}
                              </button>
                            </div>
                          </form>
                        </div>
                        <div
                          className={`form-right login-section ${
                            !showPlan ? "d-block" : "d-none"
                          } h-100 py-4 px-4`}
                        >
                          <form
                            className="row g-3"
                            onSubmit={(e) => {
                              e.preventDefault();

                              sessionStorage.removeItem("registerUser");
                              sessionStorage.removeItem("PLAN_ID");
                              sessionStorage.removeItem("isSignUp");

                              navigate("/login");
                            }}
                          >
                            <h2 className="mt-1 mb-3">
                              {isSignUp === "true" ? "Welcome" : "Welcome back"}
                            </h2>
                            <div className="col-md-12 col-12 textfield">
                              <label>
                                {`${sessionUserData?.firstName} ${sessionUserData?.lastName}`}
                                , Welcome to Mind of the Market !
                                <br />
                                You are an official member and wish you
                                adventurous and fun journey ahead.
                              </label>
                            </div>
                            <div className="col-12">
                              <button
                                type="submit"
                                className="btn btn-primary border-0 float-start mt-2 me-3"
                              >
                                Continue
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
