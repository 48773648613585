/* eslint-disable */
import React, { useEffect, useState, useCallback } from "react";
import { Table, Button } from "react-bootstrap";
import { RiSortDesc, RiSortAsc } from "react-icons/ri";
import moment from "moment";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
import Pagination from "../../Components/Pagination";
import { PaymentDetails } from "../../Components/Modal/PaymentDetails";
import { PasswordReSet } from "../../Components/Modal/PasswordReSet";
import UserDetail from "../../Components/Modal/UserDetail";
import { AxiosGetWithToken } from "../../Utils/Api";
import SubHeader from "../../Components/SubHeader/SubHeader";
import payment from "../../assets/images/payment.png";
import lockIcon from "../../assets/images/lockIcon.png";
import addFileIcon from "../../assets/images/addfile_icon.svg";
import Loader from "../../Components/Loader/loader";
import { VIPUser } from "../../Components/Modal/VIPUser";
import "./UserList.css";

interface Header {
  Authorization: string | null;
}

// eslint-disable-next-line no-unused-vars
enum sortingOrder {
  // eslint-disable-next-line no-unused-vars
  ascending = "asc",
  // eslint-disable-next-line no-unused-vars
  descending = "desc",
}

// eslint-disable-next-line no-unused-vars
enum sortingOrderBy {
  // eslint-disable-next-line no-unused-vars
  firstName = "FIRST_NAME",
  // eslint-disable-next-line no-unused-vars
  lastName = "LAST_NAME",
  // eslint-disable-next-line no-unused-vars
  email = "EMAIL",
  // eslint-disable-next-line no-unused-vars
  status = "STATUS",
  // eslint-disable-next-line no-unused-vars
  selectedPlan = "PLAN_NAME",
  // eslint-disable-next-line no-unused-vars
  planExpirationDate = "PLAN_END_DATE",
}

// eslint-disable-next-line no-unused-vars
enum isSortingActive {
  // eslint-disable-next-line no-unused-vars
  active = "sorting active",
  // eslint-disable-next-line no-unused-vars
  inactive = "sorting",
}

interface plan {
  PLAN_NAME: string;
}

interface user {
  ID: number;
  FIRST_NAME: string;
  LAST_NAME: string;
  EMAIL: string;
  STATUS: number;
  PLAN_END_DATE: Date;
  plan: plan;
  TYPE: string;
}

interface userDetails {
  email: string;
  firstName: string;
  lastName: string;
  status: number;
  id: number;
}

export default function index() {
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const [userList, setUserList] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [order, setOrder] = useState<sortingOrder>(sortingOrder.ascending);
  const [orderBy, setOrderBy] = useState<sortingOrderBy>(
    sortingOrderBy.firstName
  );

  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [userDetails, setUserDetails] = useState<userDetails>();

  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
  const [showVIPUserModal, setShowVIPUserModal] = useState(false);

  const [userId, setUserID] = useState<string | undefined>();
  const [userEmail, setUserEmail] = useState<string | undefined>();
  const [searchUserName, setSearchUserName] = useState<string>("");

  const [filterData, setFilterData] = useState([]);

  const getAllUsersList = () => {
    setLoading(true);
    AxiosGetWithToken("/users/findAll", { headers }).then((response) => {
      setLoading(false);
      setFilterData(response.data.data.userList);
      setUserList(response.data.data.userList);
      setOrderBy(sortingOrderBy.firstName);
    });
  };

  useEffect(() => {
    if (headers?.Authorization) {
      getAllUsersList();
    }
  }, []);

  const sorting = (arr) => {
    return arr.sort((a: user, b: user) =>
      a[orderBy].toLocaleLowerCase() > b[orderBy].toLocaleLowerCase() &&
      order === sortingOrder.ascending
        ? 1
        : -1
    );
  };

  const sortingFromPlanName = (arr) => {
    return arr.sort((a: user, b: user) =>
      a?.plan?.PLAN_NAME.toLocaleLowerCase() >
        b?.plan?.PLAN_NAME.toLocaleLowerCase() &&
      order === sortingOrder.ascending
        ? 1
        : -1
    );
  };

  useEffect(() => {
    if (orderBy) {
      setPage(0);

      if (orderBy === sortingOrderBy.selectedPlan) {
        const notNullPlanList: user[] = [];
        const nullPlanList: user[] = [];
        const adminPlanList: user[] = [];

        userList.filter((user: user) => {
          user.TYPE === "admin"
            ? adminPlanList.push(user)
            : user.plan === null
            ? nullPlanList.push(user)
            : notNullPlanList.push(user);
          return null;
        });

        const sortedNotNullPlanList = sortingFromPlanName(notNullPlanList);
        // notNullPlanList.sort((a:user, b:user) => (a?.plan?.PLAN_NAME.toLocaleLowerCase() > b?.plan?.PLAN_NAME.toLocaleLowerCase()) && order === sortingOrder.ascending ? 1 : -1);

        setUserList(
          order === sortingOrder.ascending
            ? [...adminPlanList, ...sortedNotNullPlanList, ...nullPlanList]
            : [...sortedNotNullPlanList, ...adminPlanList, ...nullPlanList]
        );
      } else if (orderBy === sortingOrderBy.status) {
        userList.sort((a: user, b: user) =>
          a[orderBy] < b[orderBy] && order === sortingOrder.ascending ? 1 : -1
        );
        setUserList([...userList]);
      } else if (orderBy === sortingOrderBy.planExpirationDate) {
        const notNullPlanExpDate: user[] = [];
        const nullPlanExpDate: user[] = [];

        userList.filter((user: any) => {
          user.PLAN_END_DATE === null
            ? nullPlanExpDate.push(user)
            : notNullPlanExpDate.push(user);
          return null;
        });

        const sortedNotNullPlanExpDate = sorting(notNullPlanExpDate);

        setUserList([...sortedNotNullPlanExpDate, ...nullPlanExpDate]);
      } else {
        const sortedUserList = sorting(userList);
        setUserList([...sortedUserList]);
      }
    }
  }, [orderBy, order, loading]);

  const openPaymentDetailModal = (userId) => {
    setUserID(userId);
    setShowPaymentDetailsModal(!showPaymentDetailsModal);
  };

  const openChangePasswordModal = (email) => {
    setUserEmail(email);
    setShowChangePasswordModal(!showChangePasswordModal);
  };

  const openUserDetailModal = (firstName, lastName, status, email, id) => {
    setUserDetails({ firstName, lastName, status, email, id });
    setShowUserDetailsModal(!showUserDetailsModal);
  };

  const handleSearch = () => {
    if (searchUserName?.length <= 0 && filterData?.length > 0) {
      setUserList(filterData);
      return;
    }
    if (searchUserName?.length > 0) {
      const filteredRows = filterData.filter((row: user) => {
        return (
          row.FIRST_NAME.toString()
            .toLowerCase()
            .includes(searchUserName.toString().toLowerCase()) ||
          row.LAST_NAME.toString()
            .toLowerCase()
            .includes(searchUserName.toString().toLowerCase()) ||
          row.EMAIL.toString()
            .toLowerCase()
            .includes(searchUserName.toString().toLowerCase())
        );
      });
      setPage(0);
      setUserList(filteredRows);
    }
  };

  // Use of denouncer
  const delayedSearch = useCallback(debounce(handleSearch, 500), [
    searchUserName,
  ]);

  const onSearchUserClick = () => {
    if (searchUserName.trim() === "" || searchUserName.length === 0) {
      setPage(0);
      toast.error("Please enter a text");
    }
  };

  useEffect(() => {
    delayedSearch();
    return delayedSearch.cancel;
  }, [delayedSearch]);
  return (
    <>
      <CommonHeader />

      <SubHeader
        showNavbar={true}
        navigateUrl={"/home"}
        brand={"Users"}
        showSearch={true}
        showEdit={false}
        searchProjectName={searchUserName}
        setSearchProjectName={setSearchUserName}
        ShowsearchName={false}
        onSearchUserClick={onSearchUserClick}
        insideUser={true}
        showBackButton={true}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="card border-0 shadow rounded m-4 ">
          <div className="card-body pt-4">
            <div className="d-flex flex-wrap w-100 mb-4">
              <h4 className="flex-fill flex-shrink-1">Users list</h4>

              <div className="d-flex flex-fill flex-grow-2">
                <div className="flex-fill"></div>
                <div className="flex-shrink-1">
                  <Button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowVIPUserModal(!showVIPUserModal);
                    }}
                    className="btn-circle ms-2 float-end"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Create new user"
                  >
                    <img
                      src={addFileIcon}
                      alt="Create new user"
                      title="Create new user"
                    />
                  </Button>
                </div>
              </div>
            </div>

            <Table className="table-general w-100 mt-3 table-bordered">
              <thead>
                <tr>
                  <th>
                    <span
                      className={
                        orderBy === sortingOrderBy.firstName
                          ? isSortingActive.active
                          : isSortingActive.inactive
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOrder(
                          orderBy === sortingOrderBy.firstName &&
                            order === sortingOrder.ascending
                            ? sortingOrder.descending
                            : sortingOrder.ascending
                        );
                        setOrderBy(sortingOrderBy.firstName);
                      }}
                    >
                      First name
                      {order === sortingOrder.ascending ? (
                        <RiSortDesc className="btn-sort" size={15} />
                      ) : (
                        <RiSortAsc className="btn-sort" size={15} />
                      )}
                    </span>
                  </th>
                  <th>
                    <span
                      className={
                        orderBy === sortingOrderBy.lastName
                          ? isSortingActive.active
                          : isSortingActive.inactive
                      }
                      onClick={() => {
                        setOrder(
                          orderBy === sortingOrderBy.lastName &&
                            order === sortingOrder.ascending
                            ? sortingOrder.descending
                            : sortingOrder.ascending
                        );
                        setOrderBy(sortingOrderBy.lastName);
                      }}
                    >
                      Last name
                      {order === sortingOrder.ascending ? (
                        <RiSortDesc className="btn-sort" size={15} />
                      ) : (
                        <RiSortAsc className="btn-sort" size={15} />
                      )}
                    </span>
                  </th>
                  <th>
                    <span
                      className={
                        orderBy === sortingOrderBy.email
                          ? isSortingActive.active
                          : isSortingActive.inactive
                      }
                      onClick={() => {
                        setOrder(
                          orderBy === sortingOrderBy.email &&
                            order === sortingOrder.ascending
                            ? sortingOrder.descending
                            : sortingOrder.ascending
                        );
                        setOrderBy(sortingOrderBy.email);
                      }}
                    >
                      Email
                      {order === sortingOrder.ascending ? (
                        <RiSortDesc className="btn-sort" size={15} />
                      ) : (
                        <RiSortAsc className="btn-sort" size={15} />
                      )}
                    </span>
                  </th>
                  <th>
                    <span
                      className={
                        orderBy === sortingOrderBy.status
                          ? isSortingActive.active
                          : isSortingActive.inactive
                      }
                      onClick={() => {
                        setOrder(
                          orderBy === sortingOrderBy.status &&
                            order === sortingOrder.ascending
                            ? sortingOrder.descending
                            : sortingOrder.ascending
                        );
                        setOrderBy(sortingOrderBy.status);
                      }}
                    >
                      Status
                      {order === sortingOrder.ascending ? (
                        <RiSortDesc className="btn-sort" size={15} />
                      ) : (
                        <RiSortAsc className="btn-sort" size={15} />
                      )}
                    </span>
                  </th>
                  <th>
                    <span
                      className={
                        orderBy === sortingOrderBy.selectedPlan
                          ? isSortingActive.active
                          : isSortingActive.inactive
                      }
                      onClick={() => {
                        setOrder(
                          orderBy === sortingOrderBy.selectedPlan &&
                            order === sortingOrder.ascending
                            ? sortingOrder.descending
                            : sortingOrder.ascending
                        );
                        setOrderBy(sortingOrderBy.selectedPlan);
                      }}
                    >
                      Selected Plan
                      {order === sortingOrder.ascending ? (
                        <RiSortDesc className="btn-sort" size={15} />
                      ) : (
                        <RiSortAsc className="btn-sort" size={15} />
                      )}
                    </span>
                  </th>
                  <th>
                    <span
                      className={
                        orderBy === sortingOrderBy.planExpirationDate
                          ? isSortingActive.active
                          : isSortingActive.inactive
                      }
                      onClick={() => {
                        setOrder(
                          orderBy === sortingOrderBy.planExpirationDate &&
                            order === sortingOrder.ascending
                            ? sortingOrder.descending
                            : sortingOrder.ascending
                        );
                        setOrderBy(sortingOrderBy.planExpirationDate);
                      }}
                    >
                      Plan expiration date
                      {order === sortingOrder.ascending ? (
                        <RiSortDesc className="btn-sort" size={15} />
                      ) : (
                        <RiSortAsc className="btn-sort" size={15} />
                      )}
                    </span>
                  </th>
                  {/* <th><span className='iconPosition'>Payment details</span></th> */}
                  <th>
                    <span className="iconPosition"> Actions </span>
                  </th>
                </tr>
              </thead>

              {userList.length > 0 ? (
                userList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                  .map((user: user, index) => (
                    <tbody key={index}>
                      <tr>
                        <td
                          data-label="First name"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="View user details "
                        >
                          <span className="predefined">
                            <a
                              href=""
                              className="fw-bold"
                              onClick={(e) => {
                                e.preventDefault();
                                openUserDetailModal(
                                  user.FIRST_NAME,
                                  user.LAST_NAME,
                                  user.STATUS,
                                  user.EMAIL,
                                  user.ID
                                );
                              }}
                            >
                              {user.FIRST_NAME}
                            </a>
                          </span>
                        </td>
                        <td data-label="Last name">
                          <span>{user.LAST_NAME}</span>
                        </td>
                        <td data-label="Email">
                          <span> {user.EMAIL} </span>
                        </td>
                        <td
                          data-label="Status"
                          className={
                            user.STATUS === 1 ? "activeUser" : "inactiveUser"
                          }
                        >
                          <span>
                            <a className="fw-bold">
                              {user.STATUS === 1 ? "Active" : "Inactive"}
                            </a>
                          </span>
                        </td>
                        <td data-label="Selected Plan">
                          <span>
                            {user?.TYPE === "admin"
                              ? "Admin"
                              : user?.plan?.PLAN_NAME || "-"}
                          </span>
                        </td>
                        <td data-label="Plan expiration date">
                          <span>
                            {user?.PLAN_END_DATE
                              ? moment(
                                  new Date(user?.PLAN_END_DATE).toString()
                                ).format("MMM DD, YYYY")
                              : "-"}
                          </span>
                        </td>
                        <td data-label="Payment details">
                          <div>
                            <span
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Payment details"
                            >
                              <button
                                className="btn-del"
                                onClick={(e) => {
                                  e.preventDefault();
                                  openPaymentDetailModal(user.ID);
                                }}
                              >
                                <img src={payment} width={25} height={25} />
                              </button>
                            </span>
                            <span
                              className="margin-left"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Reset password"
                            >
                              <button
                                className="btn-del margin-10 "
                                onClick={(e) => {
                                  e.preventDefault();

                                  openChangePasswordModal(user.EMAIL);
                                }}
                              >
                                <img
                                  className="ml-2 margin-top"
                                  src={lockIcon}
                                  width={25}
                                  height={25}
                                />
                              </button>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={4}>No user found</td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>

          {userList.length > 10 && (
            <Pagination
              data={userList}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
        </div>
      )}

      <PaymentDetails
        showPaymentDetailsModal={showPaymentDetailsModal}
        setShowPaymentDetailsModal={setShowPaymentDetailsModal}
        userId={userId}
        setUserID={setUserID}
      />
      <PasswordReSet
        showChangePasswordModal={showChangePasswordModal}
        setShowChangePasswordModal={setShowChangePasswordModal}
        userEmail={userEmail}
      />
      <UserDetail
        showUserDetailsModal={showUserDetailsModal}
        setShowUserDetailsModal={setShowUserDetailsModal}
        userDetails={userDetails}
        setUserDetails={setUserDetails}
        getAllUsersList={getAllUsersList}
      />
      <VIPUser
        showVIPUserModal={showVIPUserModal}
        setShowVIPUserModal={setShowVIPUserModal}
        getAllUsersList={getAllUsersList}
        setLoading={setLoading}
      />
    </>
  );
}
