/* eslint-disable */
import React, { Fragment, TouchEvent, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Center, Flex, Button as MantineButton } from "@mantine/core";

import { VscFiles } from "react-icons/vsc";
import moment from "moment";

import StarButton from "../../Components/StarButton/RatingStarButton";

import BandN from "../../assets/images/BandN.png";
import "./book.css";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineAmazon } from "react-icons/ai";
import { BsFillReplyAllFill } from "react-icons/bs";
import { FaAudible } from "react-icons/fa";
import AddTextClip from "../../Components/Modal/AddTextClip";
import AnalyzeReviews from "../../Components/Modal/AnalyzeReviews";
import AddSnippetGeneral from "../../Components/Bookmarks/Modal/AddSnippetGeneral";

interface comments {
  description: string;
  rating: number;
  content: string;
  author: string;
  commentedOn: Date;
}

interface bookObject {
  description?: string;
  name: string;
  author: string;
  publicationDate: string;
  reviews: comments[];
  url?: string | undefined;
  site: string;
  lookInside: number | boolean;
  tableOfContent: string;
}

interface star {
  star1: comments[];
  star2: comments[];
  star3: comments[];
  star4: comments[];
  star5: comments[];
}

interface propsType {
  url: string | undefined;
  bookName?: string | undefined;
  bookAuthor?: string | undefined;
  indexOfBook: number;
  list: any;
  setList: Function;
  bookData: bookObject;
  starComments: star;
}

function Book(props: propsType) {
  const {
    url,
    bookAuthor,
    bookName,
    indexOfBook,
    list,
    setList,
    bookData,
    starComments,
  } = props;

  const [show, setShow] = useState<boolean>(false);

  const [content, setContent] = useState<string>("");

  const [addReference, setAddReference] = useState<string>("");

  const [book, setBookData] = useState<bookObject>();

  const [bookUrl, setBookUrl] = useState<string>("");

  const [name, setName] = useState<string>("");

  const [author, setAuthor] = useState<string>("");

  const [showMore, setShowMore] = useState<boolean>(false);

  const [showMoreComment, setShowMoreComments] = useState(false);

  const [showMoreTableOfContent, setShowMoreTableOfContent] = useState(false);

  const [commentIndex, setCommentIndex] = useState<number>();

  const [comments, setComments] = useState<Array<comments>>([]);

  const [isActiveStarButton, setIsActiveStarButton] = useState<number>(5);

  const [selectedCategory, setSelectedCategory] = useState<any>([]);

  const [width, setWidth] = useState<number>(window.innerWidth);

  const [select, setSelect] = useState<any>(window.getSelection());

  const [commentCount, setCommentCount] = useState<number>();

  const [analzeShow, setAnalyzeShow] = useState<boolean>(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 540;

  const isTablet = width > 540 && width <= 991;

  useEffect(() => {
    setBookData(bookData);
    setComments(starComments?.star5);
  }, [bookData]);

  // for display star wise comments
  const onClick = (index) => {
    setIsActiveStarButton(index);
    setComments(starComments[`star${index}`]);
  };

  // Show more book title content
  const showMoreContent = (e) => {
    e.preventDefault();
    setShowMore(!showMore);
  };

  // show more on Star comment content
  const showMoreCommentContent = (index: number) => {
    setCommentIndex(index);
    setShowMoreComments(!showMoreComment);
  };

  // show more on table of content
  const showMoreTableContent = () => {
    setShowMoreTableOfContent(!showMoreTableOfContent);
  };

  let shareButton: HTMLElement | null = document.querySelector("#share-btn");

  function HandleMouseDown(event) {
    event.stopPropagation();
    if (shareButton != null) {
      if (
        event.target.id !== "share-btn" &&
        getComputedStyle(shareButton).display === "block"
      ) {
        shareButton.style.display = "none";
        shareButton.classList.remove("btnEntrance");
      }
    }
  }

  const handleSelection = (event) => {
    let selectionEvent;

    if (isTablet) {
      selectionEvent = event as TouchEvent;
    } else {
      selectionEvent = event as MouseEvent;
    }
    selectionEvent.stopPropagation();
    const selection = window.getSelection();

    setSelect(selection);

    let selectedText: string = "";

    if (selection) {
      selectedText = selection.toString().trim();
    }

    if (selectedText.length) {
      const x = isTablet
        ? selectionEvent.changedTouches[0].pageX
        : selectionEvent.pageX;

      const y = isTablet
        ? selectionEvent.changedTouches[0].pageY
        : selectionEvent.pageY;

      if (shareButton != null) {
        const shareButtonWidth = Number(
          getComputedStyle(shareButton).width.slice(0, -2)
        );
        const shareButtonHeight = Number(
          getComputedStyle(shareButton).height.slice(0, -2)
        );

        shareButton.style.left = isTablet
          ? `${x - shareButtonWidth - 30}px`
          : `${x - shareButtonWidth + 30}px`;

        shareButton.style.top = isTablet
          ? `${y - shareButtonHeight + 35}px`
          : `${y - shareButtonHeight - 8}px`;

        shareButton.style.display = "block";

        shareButton.classList.add("btnEntrance");
      }
    }

    document.addEventListener(
      isTablet ? "touchstart" : "mousedown",
      HandleMouseDown
    );
  };
  useEffect(() => {
    shareButton = document.querySelector("#share-btn");

    const selectDiv: NodeList = document.querySelectorAll(
      ".selectable-text-area"
    );
    selectDiv.forEach((elem: Node) => {
      if (isMobile) {
        document.addEventListener("selectionchange", function (event) {
          event.stopPropagation();
          const selection = window.getSelection();
          if (selection) {
            if (selection.rangeCount === 0) {
              return;
            }
            setSelect(selection);
            const range = selection.getRangeAt(0);
            const position = range.getBoundingClientRect();
            const text = selection.toString();
            if (shareButton != null && text) {
              shareButton.style.left = position.right - 40 + "px";

              shareButton.style.top =
                window.pageYOffset === 0
                  ? position.top + 20 + "px"
                  : position.top + window.pageYOffset + 20 + "px";

              shareButton.style.display = "block";
              shareButton.classList.add("btnEntrance");
            }
            document.addEventListener("touchstart", HandleMouseDown);
          }
        });
      } else {
        elem.addEventListener(
          isTablet ? "touchend" : "mouseup",
          handleSelection
        );
      }
    });
  }, []);

  const showClipboard = () => {
    setShow(true);
    setContent(select.toString().trim());
    setAddReference(select.anchorNode.parentNode.id);
    setBookUrl(select.anchorNode.parentNode.parentNode.parentNode.id);
    setName(select.anchorNode.parentNode.parentNode.parentNode.title);
    setAuthor(
      select.anchorNode.parentNode.parentNode.parentNode.getAttribute(
        "aria-label"
      )
    );
    setSelectedCategory([]);

    console.log(
      select.anchorNode.parentNode.parentNode.parentNode.getAttribute(
        "aria-label"
      )
    );

    console.log(select.anchorNode.parentNode.parentNode.parentNode.title);

    (window as any).getSelection().empty();
  };

  const openBookInNewTab = (url: string | undefined) => {
    window.open(url);
  };

  const TotalCommentCount = (index) => {
    setCommentCount(starComments[`star${index}`].length);
  };

  const ClearTotalCommentCount = () => {
    setCommentCount(0);
  };

  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          showClipboard();
        }}
        id="share-btn"
      >
        <i>
          <VscFiles />
        </i>
      </button>
      <Container key={indexOfBook}>
        <div
          className="card book-card border  rounded mb-3 selectable-text-area"
          id={url}
          title={bookName}
          aria-label={bookAuthor}
        >
          <div
            className="card-header book-header selectable-text-area"
            id={url}
            title={bookName}
            aria-label={bookAuthor}
          >
            <div
              className="d-flex flex-row "
              id={url}
              title={bookName}
              aria-label={bookAuthor}
            >
              <h4
                className="flex-grow-1 flex-fill book-title"
                onClick={(e) => {
                  e.preventDefault();
                  openBookInNewTab(book?.url);
                }}
              >
                <a href="" id="BookName">
                  {book?.name}
                </a>
              </h4>
              <div className="mx-2">
                {(book?.lookInside === 1 || book?.lookInside === true) && (
                  <BsFillReplyAllFill
                    size={30}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Look inside"
                  />
                )}
              </div>

              <div className="ms-2">
                {book?.site === "amazon" && (
                  <AiOutlineAmazon
                    size={30}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Amazon"
                  />
                )}
                {book?.site === "audible" && (
                  <FaAudible
                    size={30}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Audible"
                  />
                )}
                {book?.site === "barnes&nobel" && (
                  <img
                    alt="BandN"
                    src={BandN}
                    width={50}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Barnes & Nobel"
                  ></img>
                )}
              </div>
            </div>
            <p className="mb-0">
              <small id="publication Date">
                Author: {book?.author} | Publication Date:{" "}
                {book?.publicationDate != null
                  ? moment(book?.publicationDate).format("MMM DD, YYYY")
                  : "Not available"}{" "}
              </small>
            </p>
            <p className="maxheight-100 py-2 mb-1" id="Book Description">
              {showMore
                ? book?.description
                : `${book?.description?.substring(0, 150)}`}
              <a href="" onClick={showMoreContent}>
                {book?.description !== undefined &&
                book?.description?.length > 150
                  ? showMore
                    ? "    Show less"
                    : "   Show more"
                  : ""}
              </a>
            </p>
          </div>

          <div
            className="card-body book-body selectable-text-area"
            id={url}
            title={bookName}
            aria-label={bookAuthor}
          >
            <div
              className="row"
              id={url}
              title={bookName}
              aria-label={bookAuthor}
            >
              <div className="col-md-12">
                {book?.tableOfContent !== null &&
                  book?.tableOfContent !== undefined &&
                  book?.site === "barnes&nobel" &&
                  book?.tableOfContent !== "" && (
                    <>
                      <p className="mb-1" id="Table of contents">
                        Table of contents:
                      </p>
                      <p
                        className="border-bottom pt-1 pb-2 mb-1 tableOfContentsPreFormatting tableOfContentScrollBar"
                        id="Table of content"
                      >
                        {showMoreTableOfContent
                          ? book?.tableOfContent
                          : `${book?.tableOfContent?.substring(0, 150)}`}
                        <a
                          href=""
                          onClick={(e) => {
                            showMoreTableContent();
                            e.preventDefault();
                          }}
                        >
                          {book?.tableOfContent !== " " &&
                          book?.tableOfContent !== undefined &&
                          book?.site === "barnes&nobel" &&
                          book?.tableOfContent !== null &&
                          book?.tableOfContent?.length > 150
                            ? showMoreTableOfContent
                              ? "    Show less"
                              : "   Show more"
                            : ""}
                        </a>
                      </p>
                    </>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex flex-wrap">
                  <div className="me-3 my-2 py-1 ">
                    <span className="align-middle">
                      Reviews (
                      {(starComments[`star1`]?.length ?? 0) +
                        (starComments[`star2`]?.length ?? 0) +
                        (starComments[`star3`]?.length ?? 0) +
                        (starComments[`star4`]?.length ?? 0) +
                        (starComments[`star5`]?.length ?? 0)}
                      ):
                    </span>
                  </div>
                  {[5, 4, 3, 2, 1].map((starCounter, index) => {
                    return (
                      <>
                        <StarButton
                          id="btn-rating5"
                          starIconCount={starCounter}
                          key={index}
                          onClickStar={onClick}
                          commentCount={commentCount}
                          onMouseOver={TotalCommentCount}
                          onMouseOut={ClearTotalCommentCount}
                          isActiveButton={isActiveStarButton}
                        />
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className="maxheight-250 pe-2"
              id={url}
              title={bookName}
              aria-label={bookAuthor}
            >
              {comments?.length > 0 ? (
                comments.map((star: comments, index: number) => {
                  return (
                    <Fragment key={index}>
                      <div id={url} title={bookName} aria-label={bookAuthor}>
                        <p className="mb-0 review-label">
                          <small id="publication Date">
                            Review author: {star?.author}| Reviewed on:{" "}
                            {star.commentedOn !== null
                              ? moment(star.commentedOn).format("MMM DD, YYYY")
                              : "Not available"}
                          </small>
                        </p>
                        <p className="mb-1 review-label">
                          <b id="User review">{star?.description}</b>
                        </p>
                        <p
                          key={index}
                          className="border-bottom pt-1 pb-2 mb-1 "
                          id="User review"
                        >
                          {showMoreComment && commentIndex === index
                            ? star?.content
                            : `${star?.content?.substring(0, 150)}`}
                          <a
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              showMoreCommentContent(index);
                            }}
                          >
                            {star?.content.length > 150
                              ? showMoreComment && commentIndex === index
                                ? "    Show less"
                                : "   Show more"
                              : null}
                          </a>
                        </p>
                      </div>
                    </Fragment>
                  );
                })
              ) : (
                <Fragment>
                  <p className="border-bottom py-2 mb-1" id="User review">
                    No comments found
                  </p>
                </Fragment>
              )}
            </div>
          </div>
          <Flex justify="flex-end" gap="xs" align="center">
            <MantineButton
              className="mt-0  m-3 "
              variant="filled"
              color="yellow"
              radius={"md"}
              onClick={() => {
                setAnalyzeShow(true);
                console.log(url);
                console.log(bookName);
                console.log(bookAuthor);
              }}
            >
              Analyze Reviews
            </MantineButton>
          </Flex>
        </div>
        {show && (
          // <AddTextClip
          //   show={show}
          //   setShow={setShow}
          //   content={content}
          //   setContent={setContent}
          //   selectedCategory={selectedCategory}
          //   setSelectedCategory={setSelectedCategory}
          //   list={list}
          //   setList={setList}
          //   bookUrl={bookUrl}
          //   setBookUrl={setBookUrl}
          //   addReference={addReference}
          //   setAddReference={setAddReference}
          // />
          <AddSnippetGeneral
            opened={show}
            bookURL={bookUrl}
            bookName={name}
            bookAuthor={author}
            isBook
            onClose={() => setShow(false)}
            content={content}
            type={"rich-text"}
          />
        )}
        {analzeShow && (
          <AnalyzeReviews
            show={analzeShow}
            setShow={setAnalyzeShow}
            bookUrl={url!}
            bookName={bookName}
            bookAuthor={bookAuthor}
            list={list}
            setList={setList}
            reviews={starComments[`star5`]}
            star5={starComments[`star5`]}
            star4={starComments[`star4`]}
            star3={starComments[`star3`]}
            star2={starComments[`star2`]}
            star1={starComments[`star1`]}
          />
        )}
      </Container>
    </>
  );
}

export default Book;
