/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import SubHeader from "../../Components/SubHeader/SubHeader";
import TemplateList from "./templateList";
import { AxiosGetWithToken, AxiosPutWithToken } from "../../Utils/Api";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
import Loader from "../../Components/Loader/loader";
import ConfirmationDialog from "../../Components/ConfirmationDialog/ConfirmationDialog";

import "../Home/projectList.scss";
import "./template.css";
import { toast } from "react-toastify";

interface Header {
  Authorization: string | null;
}
interface Data {
  ID: Number;
  DESCRIPTION: string;
  TITLE: string;
}

interface templates {
  data: Data[];
}
interface data {
  data: templates;
  message: string;
  status: number;
}

interface templateListResponse extends AxiosResponse {
  data: data;
}

function Template() {
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState<any>(null);

  const [templateId, setTemplateId] = useState<number | null>();

  const [showDelateModal, setShowDelateModal] = useState(false);

  useEffect(() => {
    // GEt all Template list
    if (headers?.Authorization) {
      setLoading(true);
      list();
    }
  }, []);

  useEffect(() => {
    if (show) {
      navigate("/create/template");
    }
  }, [show]);

  const list = () => {
    if (headers?.Authorization) {
      AxiosGetWithToken("/template/findAll", { headers }).then(
        (response: templateListResponse) => {
          setLoading(false);
          setData(response?.data?.data?.data);
        }
      );
    }
  };

  const deleteTemplate = (ID: Number) => {
    if (headers?.Authorization && templateId) {
      setLoading(true);
      AxiosPutWithToken(`/template/delete/${templateId}`, {}, { headers })
        .then((response: templateListResponse) => {
          setLoading(false);
          list();
          toast.success(response.data.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
    // });
  };

  return (
    <Fragment>
      <CommonHeader />

      <SubHeader
        showNavbar={true}
        navigateUrl="/home"
        setShow={setShow}
        brand={"My Templates"}
        showSearch={false}
        showBackButton
        showAdd1
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid mt-5 px-3">
          <div className="row">
            <div className="col-md-12">
              <TemplateList
                loading={loading}
                data={data}
                setShowDelateModal={setShowDelateModal}
                setTemplateId={setTemplateId}
              />
            </div>
          </div>
        </div>
      )}

      {showDelateModal && (
        <ConfirmationDialog
          confirmButtonText="Delete"
          confirmDialogTitle="Delete template?"
          show={showDelateModal}
          setShow={setShowDelateModal}
          onConfirm={deleteTemplate}
          message="Are you sure you want to delete the selected template?"
          isConfirmButtonVisible={true}
        />
      )}
    </Fragment>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(Template);
