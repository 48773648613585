/* eslint-disable */
/* eslint-disable react/no-unknown-property */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
const FileSaver = require("file-saver");

function MarketingVideos() {
  interface userData {
    IS_SUBSCRIBE: number;
    PLAN_ID: number;
  }
  const navigate = useNavigate();

  const userDetail = useSelector(
    (state: { UserReducer: { userDetail: userData } }) =>
      state.UserReducer.userDetail
  );

  const navigateToHome = () => {
    if (userDetail?.PLAN_ID === 1) {
      navigate("/home");
    }
  };
  // useEffect(() => {
  //   navigateToHome();
  // }, []);

  // useEffect(() => {
  //   navigateToHome();
  // }, [userDetail]);

  return (
    <>
      <CommonHeader />
      <div className="container">
        <div className="row mt-4 text-center">
          <div className="d-block mx-auto">
            <h2 className="mb-2"> Welcome to Mind of the Market </h2>
            {/* <video controls style={{ width: '100%' }}>
              <source src='https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/BigBuckBunny.mp4'
                type="video/mp4" />
            </video> */}

            <iframe
              allowFullScreen
              height="350px"
              width={"100%"}
              src="https://player.vimeo.com/video/537972325?h=69f709ebcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              allow="autoplay; fullscreen; picture-in-picture"
              title="Mind Of The Market Copywriting with Jay Abraham"
            />
            {/* <script src="https://player.vimeo.com/api/player.js"></script> */}

            <p className="mt-3 hyper-text">
              <a
                onClick={() => {
                  FileSaver.saveAs(
                    "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/workbook/Mind+of+Marketing+-Copywriting+Book.pdf",
                    "MOM-Copywriting-Book.pdf"
                  );
                }}
              >
                Download Mind of the Market workbook - PDF
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketingVideos;
