/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  IconFolder,
  IconWorldSearch,
  IconBookmark,
  IconAffiliate,
  IconWriting,
  IconPlayerPlay,
  IconReservedLine,
} from "@tabler/icons-react";
import { motion } from "framer-motion";
import "./newhome.scss";
import { useDispatch, useSelector } from "react-redux";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { AxiosGetWithToken } from "../../Utils/Api";
import { userData } from "../../Redux/Action/user";
import { toast } from "react-toastify";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
import {
  Badge,
  Button,
  Dialog,
  Group,
  TextInput,
  Text,
  Flex,
  Paper,
  CloseButton,
} from "@mantine/core";

const NewHome: React.FC = () => {
  const dispatch = useDispatch();

  interface data {
    message?: string;
    status?: number;
    FIRST_NAME: string;
    EMAIL: string;
    LAST_NAME: string;
    TYPE?: string;
    IS_SUBSCRIBE?: string;
    PLAN_AMOUNT?: number;
  }

  // eslint-disable-next-line no-unused-vars
  enum status {
    // eslint-disable-next-line no-unused-vars
    Unsubscribe = "Unsubscribe",
    // eslint-disable-next-line no-unused-vars
    Resubscribe = "Re-subscribe",
  }
  interface userDetailsResponse extends AxiosResponse {
    data: { data: data };
  }

  interface Header {
    Authorization: string | null;
  }

  interface Event {
    preventDefault: Function;
  }
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const navigate = useNavigate();

  const [showSupportModal, setShowSupportModal] = useState<boolean>(false);
  const [name, setName] = useState<string>("");

  const [dialogOpened, setDialogOpened] = useState<boolean>(true);

  const userDetail = useSelector(
    (state: { UserReducer: { userDetail: data } }) =>
      state.UserReducer.userDetail
  );

  const getUserDetails = () => {
    AxiosGetWithToken("/user", { headers }).then(
      (response: userDetailsResponse) => {
        localStorage.setItem("email", response.data.data.EMAIL);
        dispatch(userData(response.data.data));
        setName(
          `${response.data.data.FIRST_NAME} ${response.data.data.LAST_NAME}`
        );
      }
    );
  };

  useEffect(() => {
    if (headers?.Authorization && !userDetail?.FIRST_NAME) {
      getUserDetails();
    } else {
      setName(`${userDetail?.FIRST_NAME} ${userDetail?.LAST_NAME}`);
    }
  }, []);

  const logOut = (event: Event) => {
    event.preventDefault();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("idToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("email");
    navigate("/login");
    toast.info("You are Logged out!");
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.7 },
  };

  return (
    <div className="vh-100 d-flex flex-column">
      <CommonHeader />
      <Container className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <motion.h2 {...fadeInUp}>Where do you want to go?</motion.h2>
        <Row className="mt-4 justify-content-center">
          <Col xs="auto" className="p-2">
            <motion.div {...fadeInUp}>
              <Card
                className="option-card project d-flex flex-column justify-content-center align-items-center"
                onClick={() => {
                  navigate("/project");
                }}
              >
                <Badge variant="dot" size="sm" className="coming-soon-badge">
                  New
                </Badge>
                <IconFolder size={50} stroke={1} className="icon mb-2" />
                <Card.Text className="label">Projects</Card.Text>
              </Card>
            </motion.div>
          </Col>
          <Col xs="auto" className="p-2">
            <motion.div {...fadeInUp}>
              <Card
                className="option-card webscrape d-flex flex-column justify-content-center align-items-center"
                onClick={() => {
                  navigate("/webscrape");
                }}
              >
                <Badge variant="dot" size="sm" className="coming-soon-badge">
                  New
                </Badge>
                <IconWorldSearch size={50} stroke={1} className="icon mb-2" />
                <Card.Text className="label">Market Research</Card.Text>
              </Card>
            </motion.div>
          </Col>
          <Col xs="auto" className="p-2">
            <motion.div {...fadeInUp}>
              <Card
                className="option-card aiwriting d-flex flex-column justify-content-center align-items-center"
                onClick={() => {
                  navigate("/writer");
                }}
              >
                <Badge variant="dot" size="sm" className="coming-soon-badge">
                  New
                </Badge>
                <IconWriting size={50} stroke={1} className="icon mb-2" />
                <Card.Text className="label">Writer</Card.Text>
              </Card>
            </motion.div>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-center">
          <Col xs="auto" className="p-2">
            <motion.div {...fadeInUp}>
              <Card
                className="option-card bookmarks d-flex flex-column justify-content-center align-items-center"
                onClick={() => {
                  navigate("/bookmarks");
                }}
              >
                <Badge variant="dot" size="sm" className="coming-soon-badge">
                  New
                </Badge>
                <IconBookmark size={50} stroke={1} className="icon mb-2" />
                <Card.Text className="label">Bookmarks</Card.Text>
              </Card>
            </motion.div>
          </Col>
          <Col xs="auto" className="p-2">
            <motion.div {...fadeInUp}>
              <Card className="option-card workflows d-flex flex-column justify-content-center align-items-center">
                <Badge
                  variant="default"
                  size="sm"
                  className="coming-soon-badge"
                >
                  Coming Soon
                </Badge>
                <IconAffiliate size={50} stroke={1} className="icon mb-2" />
                <Card.Text className="label">Workflows</Card.Text>
              </Card>
            </motion.div>
          </Col>
          <Col xs="auto" className="p-2">
            <motion.div {...fadeInUp}>
              <Card
                className="option-card jayss d-flex flex-column justify-content-center align-items-center"
                onClick={() => {
                  navigate("/marketing-video-workbook");
                }}
              >
                <Badge variant="dot" size="sm" className="coming-soon-badge">
                  New
                </Badge>
                <IconReservedLine size={50} stroke={1} className="icon mb-2" />
                <Card.Text className="label">Jay's Resources</Card.Text>
              </Card>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewHome;
