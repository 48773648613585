/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { loginUsers } from "../../../Redux/Action/login";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { AxiosPostWithToken } from "../../../Utils/Api";
import { verifyEmail } from "../../../Utils/helper";
import Loader from "../../../Components/Loader/loader";
import { SupportRequest } from "../../../Components/Modal/SupportRequest";
import "./login.scss";
import "react-toastify/dist/ReactToastify.css";
import momlogo from "../../../assets/images/momlogo-transperant.png";
import abrahamLogo from "../../../assets/images/abrahamLogo-transperant.png";
import momhoriLogo from "../../../assets/images/momhorilogo.png";
import TermsAndCondition from "./TermsAndCondition";

interface Header {
  Authorization: string | null;
}

const Login = () => {
  interface loginCredentials {
    email: any;
    password: string;
    rememberMe: any;
  }

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [showSupportModal, setShowSupportModal] = useState<boolean>(false);
  const [showTermsAndC, setTermsAndC] = useState<boolean>(false);

  const [accessToken, setAccessToken] = useState<string>();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<loginCredentials>();

  const cookies = new Cookies();
  const getEmailCookie = cookies.get("email");
  const getRememberMe = cookies.get("rememberMe");

  const dispatch = useDispatch();

  const clearError = () => {
    setTimeout(() => {
      setError("");
    }, 5000);
  };
  useEffect(() => {
    sessionStorage.removeItem("registerUser");
    sessionStorage.removeItem("PLAN_ID");
  }, []);

  const handleLogin = (data: loginCredentials) => {
    const errorMessage = verifyEmail(data.email);
    if (errorMessage === false) {
      setLoading(true);

      setError("Please enter a valid email.");
      clearError();

      setLoading(false);
    }
    if (errorMessage === true) {
      setLoading(true);

      AxiosPostWithToken("/user/login", data, { headers })
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);

            if (response.data.data.isAcceptTermsAndCondition === true) {
              localStorage.setItem(
                "accessToken",
                JSON.stringify(response.data.data.accessToken)
              );
              localStorage.setItem(
                "idToken",
                JSON.stringify(response.data.data.idToken)
              );
              localStorage.setItem(
                "refreshToken",
                JSON.stringify(response.data.data.refreshToken)
              );

              navigate("/home");
            } else if (response.data.data.user) {
              console.log(response);
              sessionStorage.setItem(
                "registerUser",
                JSON.stringify({
                  firstName: response.data.data.user.FIRST_NAME,
                  lastName: response.data.data.user.LAST_NAME,
                  ID: response.data.data.user._id,
                })
              );
              if (response.data.data.user.PLAN_ID === null) {
                sessionStorage.setItem("isSignUp", "true");
              }

              navigate("/subscribe");
            } else {
              setAccessToken(response.data.data.accessToken);
              setTermsAndC(!showTermsAndC);
              // navigate('/termsAndCondition', { state: response.data.data.accessToken });
            }
          }
          if (data.rememberMe === true) {
            cookies.set("email", data.email, { path: "/" });
            cookies.set("rememberMe", data.rememberMe, { path: "/" });
          } else if (data.rememberMe === false) {
            cookies.remove("email", data.email);
            cookies.remove("rememberMe", data.rememberMe);
          }
          dispatch(loginUsers(data));

          setLoading(false);
        })
        .catch((error) => {
          setError(error.response.data.message);
          clearError();

          setLoading(false);
        });
    }
  };

  const handleShow = (e) => {
    setShowSupportModal(!showSupportModal);
    e.preventDefault();
  };

  return (
    <Fragment>
      {loading && <Loader />}

      <div className="login-page bg-white">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 offset-xl-2 offset-lg-1">
              <div className="bg-light-md login-block shadow rounded">
                <div className="row">
                  <div className="col-md-5 d-none d-md-block">
                    <div className="form-left h-100 brand-block text-white text-center pt-5">
                      <div className="d-flex flex-column">
                        <div className="logo">
                          <img src={momlogo} alt="" />
                        </div>
                        <div className="gp-logo align-items-end py-2">
                          <img src={abrahamLogo} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="login-card bg-white">
                      <div className="mob-logo mt-3">
                        <img src={momhoriLogo} />
                      </div>
                      <div className="form-right h-100 py-4 px-4 cardGradient">
                        <form
                          className="row g-3"
                          onSubmit={handleSubmit(handleLogin)}
                        >
                          <p className="mt-3 mb-1">
                            Welcome to Mind of the Market!
                          </p>
                          <h2 className="mt-1 mb-3">Login to your account</h2>
                          <div className="col-12 textfield">
                            <label>
                              Email<span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              autoFocus
                              placeholder="Enter email"
                              defaultValue={getEmailCookie}
                              {...register("email", {
                                required: "Email is required.",
                                pattern: {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: "Please enter a valid email.",
                                },
                              })}
                            />
                            <p className="error invalid-feedback d-block h-30">
                              <span className="text-danger">
                                <ErrorMessage errors={errors} name="email" />
                              </span>
                            </p>
                          </div>
                          <div className="col-12 textfield">
                            <label>
                              Password<span className="text-danger">*</span>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Enter password"
                              {...register("password", {
                                required: "Password is required.",
                                minLength: {
                                  value: 8,
                                  message:
                                    "Please enter at least 8 character password.",
                                },
                              })}
                            />
                            <p className="error invalid-feedback d-block h-40">
                              <span className="text-danger">
                                {error || (
                                  <ErrorMessage
                                    errors={errors}
                                    name="password"
                                  />
                                )}
                              </span>
                            </p>
                          </div>
                          <div className="col-sm-8">
                            <div className="cust-checkbox">
                              <div className="custom-checkbox ">
                                <input
                                  className="checkbox-custom"
                                  id="rememberMe"
                                  type="checkbox"
                                  defaultChecked={getRememberMe}
                                  // onChange={() => { setrememberUser(!rememberUser) }}
                                  {...register("rememberMe", {})}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="rememberMe"
                                >
                                  Remember me
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 px-0 mt-0">
                            <button
                              type="submit"
                              className="btn btn-login border-0 float-start mt-2"
                            >
                              login
                            </button>
                          </div>

                          <div className="col-sm-12 px-0">
                            <div className="text-sm-start d-inline text-lg-end float-sm-start float-lg-end ">
                              <button
                                type="button"
                                className="text-info d-inline-block signup-btn"
                                onClick={() => navigate("/signup")}
                              >
                                Sign up
                              </button>
                              <span className="text-muted"> | </span>
                              <button
                                type="button"
                                className="text-info d-inline-block signup-btn"
                                onClick={() => navigate("/forgot-password")}
                              >
                                Forgot password?
                              </button>
                              <span className="text-muted"> | </span>
                              <a
                                href="/support"
                                className="text-info d-inline-block"
                                onClick={handleShow}
                              >
                                Support
                              </a>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TermsAndCondition
        accessToken={accessToken}
        showTermsAndC={showTermsAndC}
        setTermsAndC={setTermsAndC}
      />
      <SupportRequest
        showSupportModal={showSupportModal}
        setShowSupportModal={setShowSupportModal}
      />
    </Fragment>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(Login);
