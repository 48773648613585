export const allScrappingData = (AllData) => {
  return {
    type: 'ALL_SCRAPPING_DATA',
    payload: AllData
  };
};

export const addAmazonBook = (amazonData) => {
  return {
    type: 'ADD_AMAZON',
    payload: amazonData
  };
};

export const addBarnsBook = (bAndBData) => {
  return {
    type: 'ADD_BARNS',
    payload: bAndBData
  };
};

export const addAudiableBook = (audibleData) => {
  return {
    type: 'ADD_AUDIABLE',
    payload: audibleData
  };
};
