/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Text,
  Modal,
  ScrollArea,
  Divider,
  Flex,
  Button,
  TextInput,
  Card,
  Radio,
  RadioGroup,
  Loader,
  Center,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import "react-quill/dist/quill.snow.css";
import { AxiosGetWithToken, AxiosPostWithToken } from "../../../../Utils/Api";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { motion } from "framer-motion";
import { IconAlertCircle } from "@tabler/icons-react";

interface Header {
  Authorization: string | null;
}

interface SelectTemplateModalProps {
  opened: boolean;
  onClose: () => void;
  refreshDocuments: () => void;
  isProject?: boolean;
  projectObjectId?: string;
}

interface templateResponse extends AxiosResponse {
  _id?: string;
  TITLE?: string;
  DESCRIPTION?: string;
}

interface template {
  _id: string;
  TITLE: string;
  DESCRIPTION: string;
}

const SelectTemplateModal: React.FC<SelectTemplateModalProps> = ({
  opened,
  onClose,
  refreshDocuments,
  isProject,
  projectObjectId,
}) => {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [isTemplateSelected, setIsTemplateSelected] = useState<boolean>(false);
  const [templates, setTemplates] = useState<Array<template>>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<Array<template>>(
    []
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null
  );
  const [documentName, setDocumentName] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  useEffect(() => {
    // Get templates
    if (headers?.Authorization && opened) {
      setLoading(true);
      AxiosGetWithToken("/template/findAll", { headers })
        .then((response: templateResponse) => {
          setTemplates(response.data.data.data);
          setFilteredTemplates(response.data.data.data);
        })
        .catch((error) => {
          toast.error("Failed to fetch templates");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [opened]);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredTemplates(templates);
    } else {
      setFilteredTemplates(
        templates.filter((template) =>
          template.TITLE.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
  }, [searchQuery, templates]);

  const handleNextClick = () => {
    setIsTemplateSelected(true);
  };

  const handleCreateClick = async () => {
    setIsCreating(true);
    const artifactData = {
      artifactName: documentName,
      templateId: selectedTemplateId,
      projectId: isProject ? projectObjectId : undefined,
    };

    try {
      await AxiosPostWithToken("/artifact/create", artifactData, { headers });
      toast.success("Document created successfully!");
      setIsCreating(false);
      setIsTemplateSelected(false);
      setSelectedTemplateId(null);
      setSearchQuery("");
      setDocumentName("");
      refreshDocuments();
      onClose();
    } catch (error) {
      toast.error("Failed to create document.");
      setIsCreating(false);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
        setIsTemplateSelected(false);
        setSelectedTemplateId(null);
        setSearchQuery("");
        setDocumentName("");
      }}
      title={
        <Text size="xl" fw={700} c={"#333"}>
          Select a template
        </Text>
      }
      size="xl"
      fullScreen={isMobile}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      centered
      radius={15}
    >
      <Divider />
      <ScrollArea style={{ height: isMobile ? "78vh" : "65vh" }}>
        <Card p="0" className="pr-5 pt-3">
          {loading ? (
            <Center h={isMobile ? "78vh" : "60vh"}>
              <Loader color="blue" type="bars" />
            </Center>
          ) : isTemplateSelected ? (
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
            >
              <TextInput
                label={
                  <Text size="lg" mb={3} fw={600} c={"#555"}>
                    Document Name
                  </Text>
                }
                placeholder="Enter document name"
                value={documentName}
                onChange={(e) => setDocumentName(e.currentTarget.value)}
              />
            </motion.div>
          ) : (
            <>
              <TextInput
                placeholder="Search templates"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.currentTarget.value)}
              />
              <RadioGroup
                value={selectedTemplateId}
                onChange={setSelectedTemplateId}
              >
                {filteredTemplates.length > 0 ? (
                  filteredTemplates
                    .sort((a, b) => a.TITLE.localeCompare(b.TITLE))
                    .map((template) => (
                      <Card key={template._id} my="sm" withBorder>
                        <Radio
                          value={template._id}
                          label={
                            <div>
                              <Text fw={500}>{template.TITLE}</Text>
                              <Text size="sm" color="dimmed">
                                {template.DESCRIPTION}
                              </Text>
                            </div>
                          }
                        />
                      </Card>
                    ))
                ) : (
                  <Center
                    style={{
                      flexDirection: "column",
                      height: "400px",
                      gap: "10px",
                    }}
                  >
                    <IconAlertCircle size={48} stroke={1.5} color="#adb5bd" />
                    <Text color="dimmed" size="lg">
                      No data found
                    </Text>
                  </Center>
                )}
              </RadioGroup>
            </>
          )}
        </Card>
      </ScrollArea>
      <Divider my="sm" />
      <Flex mt="md" justify="flex-end" gap="xs" align="center">
        {isTemplateSelected ? (
          <>
            <Button
              variant="default"
              radius="md"
              onClick={() => setIsTemplateSelected(false)}
            >
              Back
            </Button>
            <Button
              loading={isCreating}
              loaderProps={{ type: "dots" }}
              variant="filled"
              radius="md"
              onClick={handleCreateClick}
            >
              Create
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="default"
              radius="md"
              onClick={() => {
                onClose();
                setIsTemplateSelected(false);
                setSelectedTemplateId(null);
                setSearchQuery("");
                setDocumentName("");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="filled"
              radius="md"
              onClick={handleNextClick}
              loaderProps={{ type: "dots" }}
              disabled={!selectedTemplateId}
            >
              Next
            </Button>
          </>
        )}
      </Flex>
    </Modal>
  );
};

export default SelectTemplateModal;
