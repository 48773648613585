/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  Text,
  Button,
  Center,
  SegmentedControl,
  Select,
  Loader,
  Card as MantineCard,
  Accordion,
  Modal,
  Flex,
  Divider,
  ScrollArea,
  LoadingOverlay,
} from "@mantine/core";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
import {
  IconArrowLeft,
  IconDeviceFloppy,
  IconFileDownload,
  IconPencil,
  IconTemplate,
  IconTrashX,
} from "@tabler/icons-react";
import { motion } from "framer-motion";
import "../Bookmarks/bookmarks.scss";
import ReactQuill from "react-quill";
import { AxiosGetWithToken, AxiosPutWithToken } from "../../Utils/Api";
import GenerateContentModal from "../../Components/AiWriting/Modal/GenerateContent.tsx";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mantine/hooks";
import HTMLtoDOCX from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import EditDocumentModal from "../../Components/AiWriting/Modal/EditDocument";
import BookmarkAccordion from "../../Components/Bookmarks/BookmarkSideComponent";

interface Header {
  Authorization: string | null;
}

interface LocationState {
  artifactName: string;
  projectId: string | null;
  projectName: string | null;
  backPath: string;
  pathName: string;
}

interface Topic {
  name: string;
  prompt?: string;
}

interface Category {
  name: string;
  topics: Topic[];
}

interface Prompt {
  promptname: string;
  promptdescription: string;
  prompt: string;
}

const AiWritingEditor: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { artifactName, projectId, projectName, backPath, pathName } =
    location.state as LocationState;

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const isMobile = useMediaQuery("(max-width: 50em)");

  const [segmentedControlOption, setSegmentedControlOption] =
    useState<string>("prompts");

  const [selectedLanguage, setSelectedLanguage] = useState<string>("English");
  const [selectedTone, setSelectedTone] = useState<string>("Appreciative");
  const [selectedCreativity, setSelectedCreativity] =
    useState<string>("Optimal");

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const [templateId, setTemplateId] = useState<string>("");
  const [templateContent, setTemplateContent] = useState<string | null>();

  const [editingArtifactName, setEditingArtifactName] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [viewTemplateModalOpened, setViewTemplateModalOpened] =
    useState<boolean>(false);

  useEffect(() => {
    if (artifactName) {
      setEditingArtifactName(artifactName);
    }
  }, [artifactName]);

  const languages = ["English", "Spanish", "French"];
  const tones = [
    "Appreciative",
    "Casual",
    "Formal",
    "Funny",
    "Humble",
    "Worried",
  ];
  const creativities = ["Optimal", "Low", "Medium", "Max"];
  const [categories, setCategories] = useState<Category[]>([]);

  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedTopic, setSelectedTopic] = useState<string>("");

  const [content, setContent] = useState<string>("");
  const [prompt, setPrompt] = useState<string>("");
  const [examples, setExamples] = useState<string>("");
  const [finalPrompt, setFinalPrompt] = useState<string>("");

  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  const [editArtifactModalOpened, setEditArtifactModalOpened] =
    useState<boolean>(false);

  const exampleSection = () => {
    return (
      <Accordion.Item key={templateId} value={"example"}>
        <Accordion.Control>Examples</Accordion.Control>
        <Accordion.Panel>
          <ReactQuill value={examples} readOnly={true} theme="bubble" />
        </Accordion.Panel>
      </Accordion.Item>
    );
  };

  const [openGenerateContentModal, setOpenGenerateContentModal] =
    useState<boolean>(false);

  useEffect(() => {
    fetchArtifact();
  }, [id]);

  const fetchArtifact = async () => {
    setLoading(true);
    setIsPageLoading(true);
    setError(null);
    try {
      const response = await AxiosGetWithToken(`/artifact/${id}`, {
        headers,
      });
      setEditingArtifactName(response.data.data.artifact.NAME);
      setContent(response.data.data.artifact.CONTENT);
      setTemplateId(response.data.data.artifact.TEMPLATE_ID);
      setExamples(response.data.data.artifact.EXAMPLES);
      setTemplateContent(response.data.data.artifact.TEMPLATE_CONTENT);
      fetchPrompts(response.data.data.artifact.TEMPLATE_ID);
      setIsPageLoading(false);
    } catch (err) {
      setError("Failed to fetch snippets");
    } finally {
      setLoading(false);
      setIsPageLoading(false);
    }
  };

  const mergeFetchedPrompts = (fetchedPrompts: Prompt[], title: any) => {
    const specialCategory: Category = {
      name: title,
      topics: fetchedPrompts.map((prompt) => ({
        name: prompt.promptname,
        prompt: prompt.prompt,
      })),
    };

    // setCategories((prevCategories) => [...prevCategories, specialCategory]);
    setCategories([specialCategory]);
    setSelectedCategory(specialCategory.name);
  };

  const fetchPrompts = async (fetchedTemplateId: string) => {
    if (headers?.Authorization) {
      AxiosGetWithToken(`/getPrompts/${fetchedTemplateId}`, { headers }).then(
        (response: any) => {
          mergeFetchedPrompts(
            response?.data?.data?.template?.PROMPTS,
            response?.data?.data?.template?.TITLE
          );
        }
      );
    }
  };

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);
    setSelectedTopic("");
  };

  const handleTopicChange = (value: string) => {
    setSelectedTopic(value);
    const selectedCategoryObj = categories.find(
      (category) => category.name === selectedCategory
    );
    const selectedTopicObj = selectedCategoryObj?.topics.find(
      (topic) => topic.name === value
    );
    const topicPrompt = selectedTopicObj?.prompt || "";
    setPrompt(`${topicPrompt}`);
  };

  const handleDocumentSave = async () => {
    setIsSaving(true);
    try {
      await AxiosPutWithToken(
        `/artifact/update/${id}`,
        { CONTENT: content },
        { headers }
      );
      setIsSaving(false);
      toast.success("Document saved successfully!");
      fetchArtifact();
    } catch (err) {
      console.error("Failed to save document", err);
      setIsSaving(false);
      toast.error("Failed to save document");
    }
  };

  const handleDeleteDocument = async () => {
    setIsDeleting(true);
    try {
      await AxiosPutWithToken(`/artifact/delete/${id}`, {}, { headers });
      toast.success("Document deleted successfully!");
      setIsDeleting(false);
      navigate("/writer");
    } catch (err) {
      console.error("Failed to delete document", err);
      toast.error("Failed to delete document");
      setIsDeleting(false);
    }
  };

  const handleDownloadDocument = async () => {
    let docString = `<h1 style="text-align: center">Artifact</h1>
          <p><b><span>Artifact Name:</span></b>&nbsp;&nbsp;${artifactName}</p>${content}`;

    const data = await HTMLtoDOCX.asBlob(docString);

    saveAs(data, `${artifactName}.docx`);
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.7 },
  };

  return (
    <div className="bookmark-content-page">
      <CommonHeader />
      <div className="bg-light-yellow py-4 full-width">
        <LoadingOverlay
          visible={isPageLoading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
          loaderProps={{ color: "blue", type: "bars" }}
        />
        <Container>
          <Row>
            <Col md={12} className="mx-auto ">
              <Button
                leftSection={<IconArrowLeft size={15} />}
                variant="light"
                color="gray"
                radius="xl"
                size="xs"
                onClick={() =>
                  navigate(backPath!, { state: { projName: projectName } })
                }
              >
                {pathName}
              </Button>
              <h2 className="text-left mt-2">{editingArtifactName}</h2>
              <Row className="actions mt-2">
                <Col xs={4} md={2} className="text-center ">
                  <Card
                    className="bookmarks-option-card bookmarks-select-option d-flex flex-column justify-content-center align-items-center"
                    onClick={() => {
                      setEditArtifactModalOpened(true);
                    }}
                  >
                    <IconPencil size={40} stroke={1} className="icon mb-2" />
                    <Card.Text className="label">Edit</Card.Text>
                  </Card>
                </Col>
                <Col xs={4} md={2} className="text-center ">
                  <Card
                    className="bookmarks-option-card bookmarks-select-option d-flex flex-column justify-content-center align-items-center"
                    onClick={() => {
                      handleDocumentSave();
                    }}
                  >
                    {isSaving ? (
                      <Loader color="blue" type="dots" />
                    ) : (
                      <>
                        <IconDeviceFloppy
                          size={40}
                          stroke={1}
                          className="icon mb-2"
                        />
                        <Card.Text className="label">Save</Card.Text>
                      </>
                    )}
                  </Card>
                </Col>
                <Col xs={4} md={2} className="text-center ">
                  <Card
                    className="bookmarks-option-card bookmarks-select-option d-flex flex-column justify-content-center align-items-center"
                    onClick={() => {
                      handleDownloadDocument();
                    }}
                  >
                    <IconFileDownload
                      size={40}
                      stroke={1}
                      className="icon mb-2"
                    />
                    <Card.Text className="label">Download</Card.Text>
                  </Card>
                </Col>
                {templateContent && (
                  <Col xs={4} md={2} className="text-center ">
                    <Card
                      className="bookmarks-option-card bookmarks-select-option d-flex flex-column justify-content-center align-items-center"
                      onClick={() => {
                        setViewTemplateModalOpened(true);
                      }}
                    >
                      <IconTemplate
                        size={40}
                        stroke={1}
                        className="icon mb-2"
                      />
                      <Card.Text className="label">View template</Card.Text>
                    </Card>
                  </Col>
                )}
                <Col xs={4} md={2} className="text-center ">
                  <Card
                    className="bookmarks-option-card bookmarks-select-option d-flex flex-column justify-content-center align-items-center"
                    onClick={() => {
                      setDeleteModalOpened(true);
                    }}
                  >
                    <IconTrashX
                      color="red"
                      size={40}
                      stroke={1}
                      className="icon mb-2"
                    />
                    <Card.Text className="label">Delete</Card.Text>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <motion.div {...fadeInUp}>
          <Row className="my-4">
            <Col md={3}>
              <Card className="p-3 mb-3">
                <SegmentedControl
                  fullWidth
                  value={segmentedControlOption}
                  onChange={setSegmentedControlOption}
                  data={[
                    {
                      value: "prompts",
                      label: (
                        <Center style={{ gap: 5 }}>
                          <span>Prompts</span>
                        </Center>
                      ),
                    },
                    {
                      value: "bookmarks",
                      label: (
                        <Center style={{ gap: 5 }}>
                          <span>Bookmarks</span>
                        </Center>
                      ),
                    },
                  ]}
                  className="filter-segmented-control"
                />
                {segmentedControlOption === "prompts" ? (
                  <div style={{ marginTop: "10px" }}>
                    <Select
                      label={
                        <Text size="md" mb={3} fw={600} c={"#555"}>
                          Language
                        </Text>
                      }
                      placeholder="Select language"
                      data={languages}
                      value={selectedLanguage}
                      onChange={(value) => setSelectedLanguage(value!)}
                    />
                    {/* <Select
                      label={
                        <Text size="md" mb={3} fw={600} c={"#555"}>
                          Tone
                        </Text>
                      }
                      placeholder="Select tone"
                      data={tones}
                      value={selectedTone}
                      onChange={(value) => setSelectedTone(value!)}
                      mt="md"
                    />
                    <Select
                      label={
                        <Text size="md" mb={3} fw={600} c={"#555"}>
                          Creativity
                        </Text>
                      }
                      placeholder="Select creativity"
                      data={creativities}
                      value={selectedCreativity}
                      onChange={(value) => setSelectedCreativity(value!)}
                      mt="md"
                    /> */}
                    <Select
                      label={
                        <Text size="md" mb={3} fw={600} c={"#555"}>
                          Category
                        </Text>
                      }
                      placeholder="Select category"
                      data={categories.map((category) => ({
                        value: category.name,
                        label: category.name,
                      }))}
                      value={selectedCategory}
                      onChange={(value) => handleCategoryChange(value!)}
                      mt="md"
                    />
                    <Select
                      label={
                        <Text size="md" mb={3} fw={600} c={"#555"}>
                          AI Tools
                        </Text>
                      }
                      placeholder="Select an AI tool"
                      data={
                        selectedCategory
                          ? categories
                              .find(
                                (category) => category.name === selectedCategory
                              )
                              ?.topics.map((topic) => ({
                                value: topic.name,
                                label: topic.name,
                              })) || []
                          : []
                      }
                      value={selectedTopic}
                      onChange={(value) => handleTopicChange(value!)}
                      mt="md"
                      disabled={!selectedCategory}
                    />
                    <Button
                      fullWidth
                      color="blue"
                      mt="md"
                      onClick={() => {
                        setFinalPrompt(
                          `Language: ${selectedLanguage}, Tone: ${selectedTone}, Creativity: ${selectedCreativity}. ${prompt}`
                        );

                        setOpenGenerateContentModal(true);
                      }}
                      disabled={!selectedTopic}
                    >
                      Generate
                    </Button>
                  </div>
                ) : (
                  // <span> Bookmarks</span>
                  <BookmarkAccordion />
                )}
              </Card>
            </Col>
            <Col md={9}>
              <ReactQuill
                className="react-quill-container"
                value={content}
                onChange={(value) => setContent(value)}
              />
              {examples === null ? null : examples === "" ? null : (
                <Accordion
                  chevronPosition="right"
                  variant="contained"
                  radius={"0"}
                  mt={8}
                >
                  {exampleSection()}
                </Accordion>
              )}
            </Col>
          </Row>
        </motion.div>
      </Container>
      {/* Delete document modal */}
      <Modal
        opened={deleteModalOpened}
        onClose={() => {
          setDeleteModalOpened(false);
          setIsDeleting(false);
        }}
        title={
          <Text size="xl" fw={700} c={"#333"}>
            Confirm Delete
          </Text>
        }
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        centered
        radius={15}
      >
        <Text size="sm">
          Are you sure you want to delete this document? This action cannot be
          undone.
        </Text>
        <Flex mt="md" justify="flex-end" gap="xs" align="center">
          <Button
            variant="default"
            radius="md"
            onClick={() => {
              setDeleteModalOpened(false);
              setIsDeleting(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="red"
            radius="md"
            loading={isDeleting}
            loaderProps={{ type: "dots" }}
            onClick={() => {
              handleDeleteDocument();
            }}
          >
            Confirm
          </Button>
        </Flex>
      </Modal>
      {/* View template modal */}
      <Modal
        opened={viewTemplateModalOpened}
        onClose={() => {
          setViewTemplateModalOpened(false);
        }}
        title={
          <Text size="xl" fw={700} c={"#333"}>
            View template
          </Text>
        }
        size="70%"
        fullScreen={isMobile}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        centered
        radius={15}
      >
        <Divider />
        <ScrollArea style={{ height: isMobile ? "78vh" : "65vh" }}>
          <ReactQuill value={templateContent!} readOnly={true} theme="bubble" />
        </ScrollArea>
        <Divider my="sm" />
        <Flex mt="md" justify="flex-end" gap="xs" align="center">
          <Button
            variant="default"
            radius="md"
            onClick={() => {
              setContent(templateContent!);
            }}
          >
            Copy & Replace
          </Button>
        </Flex>
      </Modal>

      <EditDocumentModal
        opened={editArtifactModalOpened}
        onClose={() => setEditArtifactModalOpened(false)}
        refreshArtifact={fetchArtifact}
        artifactName={artifactName}
        artifactId={id!}
        projectId={projectId!}
        projectName={projectName!}
      />

      <GenerateContentModal
        opened={openGenerateContentModal}
        onClose={() => setOpenGenerateContentModal(false)}
        content={content}
        setContent={setContent}
        prompt={finalPrompt}
      />
    </div>
  );
};

export default AiWritingEditor;
