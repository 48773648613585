/* eslint-disable */
import React, { useState } from "react";
import {
  Text,
  Modal,
  ScrollArea,
  Divider,
  Flex,
  Button,
  TextInput,
  Card,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import "react-quill/dist/quill.snow.css";
import { AxiosPostWithToken } from "../../../../Utils/Api";
import { toast } from "react-toastify";

interface Header {
  Authorization: string | null;
}

interface CreateBlankModalProps {
  opened: boolean;
  onClose: () => void;
  refreshDocuments: () => void;
}

const CreateBlankModal: React.FC<CreateBlankModalProps> = ({
  opened,
  onClose,
  refreshDocuments,
}) => {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [documentName, setDocumentName] = useState<string>("");
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const handleCreateClick = async () => {
    setIsCreating(true);
    const artifactData = {
      artifactName: documentName,
    };

    try {
      await AxiosPostWithToken("/artifact/createBlank", artifactData, {
        headers,
      });
      toast.success("Document created successfully!");
      setIsCreating(false);
      setDocumentName("");
      refreshDocuments();
      onClose();
    } catch (error) {
      toast.error("Failed to create document.");
      setIsCreating(false);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
        setDocumentName("");
      }}
      title={
        <Text size="xl" fw={700} c={"#333"}>
          Create blank
        </Text>
      }
      fullScreen={isMobile}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      centered
      radius={15}
    >
      <Divider />
      <ScrollArea>
        <Card p="0" className="pr-5 pt-1">
          <TextInput
            label={
              <Text size="lg" mb={3} fw={600} c={"#555"}>
                Document Name
              </Text>
            }
            placeholder="Enter document name"
            value={documentName}
            onChange={(e) => setDocumentName(e.currentTarget.value)}
          />
        </Card>
      </ScrollArea>
      {/* <Divider my="sm" /> */}
      <Flex mt="md" justify="flex-end" gap="xs" align="center">
        <>
          <Button
            variant="default"
            radius="md"
            onClick={() => {
              onClose();
              setDocumentName("");
            }}
          >
            Cancel
          </Button>
          <Button
            loading={isCreating}
            loaderProps={{ type: "dots" }}
            variant="filled"
            radius="md"
            onClick={handleCreateClick}
          >
            Create
          </Button>
        </>
      </Flex>
    </Modal>
  );
};

export default CreateBlankModal;
