/* eslint-disable */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { RiSortDesc } from "react-icons/ri";
import { setProjectDetails } from "../../Redux/Action/projectDetails";
import Pagination from "../../Components/Pagination";
import deleteIcon from "../../assets/images/delete_icon.svg";

// eslint-disable-next-line no-unused-vars
enum sortingOrder {
  // eslint-disable-next-line no-unused-vars
  ascending = "asc",
  // eslint-disable-next-line no-unused-vars
  descending = "desc",
}

interface Data {
  ID: Number;
  DESCRIPTION: string;
  TITLE: string;
  UPDATED_ON: Date;
}

interface Props {
  data: Data[];
  loading: Boolean;
  setShowDelateModal: Function;
  setTemplateId: Function;
}

function descendingComparator(a: string, b: string, orderBy: string) {
  if (b[orderBy].toLowerCase().trim() < a[orderBy].toLowerCase().trim()) {
    return -1;
  } else if (
    b[orderBy].toLowerCase().trim() > a[orderBy].toLowerCase().trim()
  ) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator(order: Order, orderBy: string): (a, b) => number {
  return order === sortingOrder.descending
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
const swapNullDataIntoLastPosition = (
  orderBy: any,
  sortedTemplatedList: any
): any => {
  const nullArray: any = [];
  const notNullArray: any = [];
  sortedTemplatedList.map((val: any, index: any) => {
    if (orderBy === "DESCRIPTION") {
      if (val.DESCRIPTION === "") {
        nullArray.push(sortedTemplatedList[index]);
      } else {
        notNullArray.push(sortedTemplatedList[index]);
      }
    }
    return null;
  });
  const projectListData = [...notNullArray, ...nullArray];
  return projectListData;
};

function stableSort<T>(
  array: readonly T[],
  orderBy,
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  const sortedTemplateList = stabilizedThis.map((el) => el[0]);
  if (orderBy === "DESCRIPTION") {
    const templateListWithNullDataLast = swapNullDataIntoLastPosition(
      orderBy,
      sortedTemplateList
    );
    return templateListWithNullDataLast;
  } else {
    return sortedTemplateList;
  }
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "TITLE",
    label: "template name",
  },
  {
    id: "DESCRIPTION",
    label: "Description",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, rowCount } = props;

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.label === "template name" ? "ps-4" : ""}
          >
            <TableSortLabel
              IconComponent={RiSortDesc}
              active={rowCount !== 0 ? orderBy === headCell.id : false}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TemplateList(props: Props) {
  const { data, loading, setShowDelateModal, setTemplateId } = props;

  const [showMore, setShowMore] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("UPDATED_ON");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          className="table table-projectlist"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data?.length}
          />

          <TableBody>
            {data?.length > 0
              ? stableSort(data, orderBy, getComparator(order, orderBy))
                  ?.length > 0 &&
                stableSort(data, orderBy, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow tabIndex={-1} key={row.TITLE}>
                        <TableCell
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/edit/template/${row._id}`);
                            dispatch(setProjectDetails(row));
                            setShowMore(!showMore);
                          }}
                          component="td"
                          scope="row"
                          data-label="template name"
                        >
                          <span className="template">
                            <a
                              href={`/edit/template/${row._id}`}
                              className="project-name"
                            >
                              <b
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="View template"
                              >
                                {row.TITLE}
                              </b>
                            </a>
                          </span>
                        </TableCell>
                        <TableCell data-label="Description">
                          <span className="Description">
                            {row.DESCRIPTION || "-"}
                          </span>
                        </TableCell>
                        <TableCell
                          data-label=""
                          onClick={() => {
                            console.log(row._id);
                            setTemplateId(row._id);
                            setShowDelateModal(true);
                          }}
                        >
                          <span>
                            <button
                              className="btn-del mx-3"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Delete"
                            >
                              <img src={deleteIcon} />
                            </button>
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })
              : null}
            {data?.length === 0 && Array.isArray(data) && !loading && (
              <TableRow>
                <TableCell colSpan={5}>
                  {!loading && (
                    <div
                      className="alert alert-danger text-center"
                      role="alert"
                    >
                      No data found
                    </div>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {data?.length > 5 && (
        <Pagination
          data={data}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}
    </>
  );
}

export default TemplateList;
