/* eslint-disable */
import React from "react";
import "./landing.css";
import logo from "./momlogo.webp";
import screenshot1 from "../../images/screenshot1.png";
import screenshot2 from "../../images/screenshot2.png";
import meetingImage from "../../images/meetingImage.webp";
import { FaEye, FaPen, FaBook, FaChartPie } from "react-icons/fa";

const HomePage: React.FC = () => {
  return (
    <div className="homepage-div">
      <header className="header">
        <div className="logo">
          <img src={logo} alt="Mind of the Market" />
        </div>
        <nav className="navigation">
          <ul>
            <li>
              <a href="/" style={{ color: "#FFA500" }}>
                Home
              </a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
            <li className="login-button">
              <a href="/home">Login</a>
            </li>
          </ul>
        </nav>
      </header>

      <section className="main-banner">
        <div className="top-content">
          <h1>Effortlessly master the art of copywriting with Gen AI.</h1>
        </div>
        <div className="bottom-content">
          <img
            src={screenshot1}
            alt="Dashboard"
            className="screenshot showup-animation"
          />
          <img
            src={screenshot2}
            alt="Editor"
            style={{ marginRight: "10px" }}
            className="screenshot showup-animation"
          />
        </div>
      </section>

      <section className="icon-bar">
        <div className="icon-item">
          <i className="icon">
            <FaPen />
          </i>{" "}
          {/* Replace with actual icon */}
          <span className="icon-text">Copywriting</span>
        </div>
        <div className="icon-item">
          <i className="icon">
            <FaBook />
          </i>{" "}
          {/* Replace with actual icon */}
          <span className="icon-text">Custom Artifacts</span>
        </div>
        <div className="icon-item">
          <i className="icon">
            <FaChartPie />
          </i>{" "}
          {/* Replace with actual icon */}
          <span className="icon-text">Marketing Platform</span>
        </div>
        <div className="icon-item">
          <i className="icon">
            <FaEye />
          </i>{" "}
          {/* Replace with actual icon */}
          <span className="icon-text">Build Your Brand</span>
        </div>
      </section>

      <section className="success-section">
        <div className="text-content">
          <p className="success-title">Let's achieve Success Together!</p>
          <p className="success-text">
            Our copywriting tools are designed with your success in mind. We
            provide a user-friendly platform that streamlines the optimization
            of your content while helping you cultivate a powerful and
            consistent brand voice. Whether you're creating engaging blog posts
            or refining your website's copy, our tools are versatile and
            adaptable to suit your specific needs.
          </p>

          <span className="login-button">
            <a href="/signup">Sign Up Now</a>
          </span>
        </div>
        <div className="image-content">
          <img src={meetingImage} alt="Team meeting" />
        </div>
      </section>

      <section className="choose-section">
        <div>
          <p className="choose-title">Why Choose Us?</p>
          <p className="choose-text">Customizable Solutions</p>
        </div>
      </section>

      <section className="features-section">
        <div className="feature">
          <h3>Research Driven Data</h3>
          <p>
            Our copywriting tools are here to make your content better. They use
            state of the art research engine algorithm to help you decide what
            works and what doesn't. You can enhance your authority and
            credibility in your industry or niche with help of Mind of the
            Market's detailed analytics and insights as well as well-researched
            and well-written copy writing features
          </p>
        </div>
        <div className="feature">
          <h3>AI Technology</h3>
          <p>
            We're at the forefront of technology, creating new tools and
            features to make your copywriting better. Our tools include AI
            content generators and advanced SEO optimization to help you stay
            ahead.
          </p>
        </div>
        <div className="feature">
          <h3>Effortless results</h3>
          <p>
            Well-crafted copy can capture the audience's attention, leading to
            higher levels of engagement with your content or
            message.Well-crafted copy can create a connection with your
            audience, fostering loyalty and repeat business.
          </p>
        </div>
      </section>

      <footer className="site-footer">
        <div className="footer-section">
          <h4>MIND OF THE MARKET</h4>
        </div>
        <div className="footer-section">
          <h4>Menu</h4>
          <ul>
            <li>Home</li>

            <li>About</li>
            <li>Contact</li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Address</h4>
          <address>
            7520 Standish Place,
            <br />
            St 260, Derwood MD 20866
          </address>
        </div>
        <div className="footer-section">
          <h4>Socials</h4>
          <ul>
            <li>LinkedIn</li>
            <li>Facebook</li>
            <li>Instagram</li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
