/* eslint-disable */
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { AxiosPostWithToken } from "../../../Utils/Api";

interface Header {
  Authorization: string | null;
}

interface propsType {
  setTermsAndC: Function;
  showTermsAndC: boolean;
  accessToken?: string | undefined;
}

export default function TermsAndCondition(props: propsType) {
  const { setTermsAndC, showTermsAndC, accessToken } = props;
  const navigate = useNavigate();

  const location = useLocation();

  const headers: Header = { Authorization: `"${accessToken}"` };

  const isAcceptTermsAndCondition = (status) => {
    if (headers?.Authorization) {
      AxiosPostWithToken(
        "/termsAndCondition/create",
        { STATUS: status },
        { headers }
      ).then((response) => {
        if (status === true) {
          localStorage.setItem("accessToken", JSON.stringify(accessToken));
          navigate("/home");
        } else {
          // navigate('/login');
          setTermsAndC(!showTermsAndC);
        }
      });
    }
  };

  return (
    <Modal
      show={showTermsAndC}
      scrollable={true}
      size="xl"
      id="termsAndCondition"
      onHide={() => {
        setTermsAndC(!showTermsAndC);
      }}
    >
      <Modal.Header closeButton={!location.state}>
        <Modal.Title>Terms and conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          By clicking the &quot;I Agree&quot; button, downloading or using the
          Application, You are agreeing to be bound by the terms and conditions
          of this Agreement. If You do not agree to the terms of this Agreement,
          do not click on the &quot;I Agree&quot; button, do not download or do
          not use the Application.
        </p>
        <p>
          This Agreement is a legal document between You and the Mind of the
          Market Bot and it governs your use of the Application made available
          to You by the Mind of the the Market.
        </p>
        <p>
          The Application is licensed, not sold, to You by the Mind of the
          Market for use strictly in accordance with the Terms of this
          Agreement.
        </p>

        <b>Terms and Agreement </b>
        <br />
        <b>Scope of License</b>
        <p>
          The Mind of the Market grants You a revocable, non-exclusive,
          non-transferable, limited license to download, install and use the
          Application strictly in accordance with the terms of this Agreement.
          <br />
          The license that is granted to You by the Mind of the Market is solely
          for your personal, non-commercial purposes strictly in accordance with
          the terms of this Agreement.
        </p>
        <b>Third-Party Services</b>
        <p>
          The Application may display, include or make available third-party
          content (including data, information, applications and other products
          services) or provide links to third-party websites or services.
          <br />
          You acknowledge and agree that the Mind of the Market shall not be
          responsible for any Third-party Services, including their accuracy,
          completeness, timeliness, validity, copyright compliance, legality,
          decency, quality or any other aspect thereof. The Mind of the Market
          does not assume and shall not have any liability or responsibility to
          You or any other person or entity for any Third-party Services.
          <br />
          You must comply with applicable Third parties&apos; Terms of agreement
          when using the Application. Third-party Services and links thereto are
          provided solely as a convenience to You and You access and use them
          entirely at your own risk and subject to such third parties&apos;
          Terms and conditions.
        </p>
        <b>Term and Termination</b>
        <p>
          This Agreement shall remain in effect until terminated by You or the
          Mind of the Market. The Mind of the Market may, in its sole
          discretion, at any time and for any or no reason, suspend or terminate
          this Agreement with or without prior notice.
          <br />
          This Agreement will terminate immediately, without prior notice from
          the Mind of the Market, in the event that you fail to comply with any
          provision of this Agreement. You may also terminate this Agreement by
          deleting the Application and all copies thereof from your Device or
          from your computer.
          <br />
          Upon termination of this Agreement, You shall cease all use of the
          Application and delete all copies of the Application from your Device.
          <br />
          Termination of this Agreement will not limit any of the Mind of the
          Market&apos;s rights or remedies at law or in equity in case of breach
          by You (during the term of this Agreement) of any of your obligations
          under the present Agreement.
        </p>
        <b>Indemnification</b>
        <p>
          You agree to indemnify and hold the Mind of the Market and its
          parents, subsidiaries, affiliates, officers, employees, agents,
          partners and licensors (if any) harmless from any claim or demand,
          including reasonable attorneys&apos; fees, due to or arising out of
          your: (a) use of the Application; (b) violation of this Agreement or
          any law or regulation; or (c) violation of any right of a third party.
        </p>
        <b>No Warranties</b>
        <p>
          The Application is provided to You &quot;AS IS&quot; and &quot;AS
          AVAILABLE&quot; and with all faults and defects without warranty of
          any kind. To the maximum extent permitted under applicable law, the
          Mind of the Market, on its own behalf and on behalf of its affiliates
          and its and their respective licensors and service providers,
          expressly disclaims all warranties, whether express, implied,
          statutory or otherwise, with respect to the Application, including all
          implied warranties of merchantability, fitness for a particular
          purpose, title and non-infringement, and warranties that may arise out
          of course of dealing, course of performance, usage or trade practice.
          Without limitation to the foregoing, the Mind of the Market provides
          no warranty or undertaking, and makes no representation of any kind
          that the Application will meet your requirements, achieve any intended
          results, be compatible or work with any other software, applications,
          systems or services, operate without interruption, meet any
          performance or reliability standards or be error free or that any
          errors or defects can or will be corrected.
          <br />
          Without limiting the foregoing, neither the Mind of the Market nor any
          of the Mind of the Market&apos;s provider makes any representation or
          warranty of any kind, express or implied: (i) as to the operation or
          availability of the Application, or the information, content, and
          materials or products included thereon; (ii) that the Application will
          be uninterrupted or error-free; (iii) as to the accuracy, reliability,
          or currency of any information or content provided through the
          Application; or (iv) that the Application, its servers, the content,
          or e-mails sent from or on behalf of the Mind of the Market are free
          of viruses, scripts, trojan horses, worms, malware, time bombs or
          other harmful components.
          <br />
          Some jurisdictions do not allow the exclusion of certain types of
          warranties or limitations on applicable statutory rights of a
          consumer,so some or all of the above exclusions and limitations may
          not apply to You. But in such a case the exclusions and limitations
          set forth in this section shall be applied to the greatest extent
          under applicable law. To the extent any warranty exists under law that
          cannot be disclaimed, the Mind of the Market shall be solely
          responsible for such warranty.
        </p>
        <b>Limitation of Liability</b>
        <p>
          Notwithstanding any damages that You might incur, the entire liability
          of the Mind of the Market and any of its suppliers under any provision
          of this Agreement and your exclusive remedy for all of the foregoing
          shall be limited to the amount actually paid by You for the
          Application or through the Application or 100 USD if You haven&apos;t
          purchased anything through the Application.
          <br />
          To the maximum extent permitted by applicable law, in no event shall
          the Mind of the Market or its suppliers be liable for any special,
          incidental, indirect, or consequential damages whatsoever (including,
          but not limited to, damages for loss of profits, loss of data or other
          information, for business interruption, for personal injury, loss of
          privacy arising out of or in any way related to the use of or
          inability to use the Application, third-party software and/or
          third-party hardware used with the Application, or otherwise in
          connection with any provision of this Agreement), even if the Mind of
          the Market or any supplier has been advised of the possibility of such
          damages and even if the remedy fails of its essential purpose.
          <br />
          Some states/jurisdictions do not allow the exclusion or limitation of
          incidental or consequential damages, so the above limitation or
          exclusion may not apply to You.
        </p>
        <b>Severability and Waiver</b>
        <p>
          <b>Severability</b>
          <p>
            If any provision of this Agreement is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect.
          </p>
          <b>Waiver</b>
          <p>
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under this Agreement shall not
            effect a party&apos;s ability to exercise such right or require such
            performance at any time thereafter nor shall the waiver of a breach
            constitute a waiver of any subsequent breach.
          </p>
        </p>

        <b>Product Claims</b>
        <p>
          The Mind of the Market does make 60 day warranties concerning the
          Application.
        </p>
        <b>United States Legal Compliance</b>
        <p>
          You represent and warrant that (i) You are not located in a country
          that is subject to the United States government embargo, or that has
          been designated by the United States government as a &quot;terrorist
          supporting&quot; country, and (ii) You are not listed on any United
          States government list of prohibited or restricted parties.
        </p>
        <b>Changes to this Agreement</b>
        <p>
          The Mind of the Market reserves the right, at its sole discretion, to
          modify or replace this Agreement at any time. If a revision is
          material we will provide at least 30 days&apos; notice prior to any
          new terms taking effect. What constitutes a material change will be
          determined at the sole discretion of the Mind of the Market.
          <br />
          By continuing to access or use the Application after any revisions
          become effective, You agree to be bound by the revised terms. If You
          do not agree to the new terms, You are no longer authorized to use the
          Application.
        </p>
        <b>Governing Law</b>
        <p>
          The laws of the Country, excluding its conflicts of law rules, shall
          govern this Agreement and your use of the Application. Your use of the
          Application may also be subject to other local, state, national, or
          international laws.
        </p>
        <b>Entire Agreement</b>
        <p>
          The Agreement constitutes the entire agreement between You and the
          Mind of the Market regarding your use of the Application and
          supersedes all prior and contemporaneous written or oral agreements
          between You and the Mind of the Market.
          <br />
          You may be subject to additional terms and conditions that apply when
          You use or purchase other Mind of the Market&apos;s services, which
          the Mind of the Market will provide to You at the time of such use or
          purchase.
        </p>
      </Modal.Body>
      <Modal.Footer>
        {accessToken !== undefined && (
          <>
            {/* <Button className='btn btn-primary border-0' onClick={() => { isAcceptTermsAndCondition(false); }}>
              Cancel
             </Button> */}
            <Button
              className="btn btn-primary border-0"
              onClick={() => {
                isAcceptTermsAndCondition(true);
              }}
            >
              I agree
            </Button>
          </>
        )}
        {accessToken === undefined && (
          <>
            <Button
              className="btn btn-primary border-0"
              onClick={() => {
                setTermsAndC(!showTermsAndC);
              }}
            >
              ok
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}
