/* eslint-disable */
import React, { useState, useRef } from "react";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import {
  registerUsers,
  verifyUsers,
  clearVerifyUsers,
} from "../../../Redux/Action/auth";
import PrivacyAndPolicy from "../../../Components/Modal/PrivacyPolicy";
import { AxiosPostWithToken } from "../../../Utils/Api";
import Loader from "../../../Components/Loader/loader";
import momlogo from "../../../assets/images/momlogo-transperant.png";
import abrahamLogo from "../../../assets/images/abrahamLogo-transperant.png";
import momhoriLogo from "../../../assets/images/momhorilogo.png";
import resendLogo from "../../../assets/images/icon-sendotp.svg";
import "./signup.scss";
import TermsAndCondition from "../Login/TermsAndCondition";

function Signup() {
  interface Header {
    Authorization: string | null;
  }

  interface Error {
    response: {
      data: {
        message: string;
      };
    };
  }

  interface SignUpCredentials {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
    phoneNumber: Number;
    otp: Number;
    TermsandCondition: string;
  }

  interface otpCredential {
    otp: Number;
    password: string;
  }

  interface registerUser {
    email: string;
  }

  interface data {
    status: number;
    data: {
      ID: number;
      _id: any;
    };
  }

  interface ResponseData extends AxiosResponse {
    data: data;
  }

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Ref: {
    current: string | Number | any;
  } = useRef(null);

  const [tAndC, setTAndC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendedOtp, setSendedOtp] = useState(false);
  const [timer, setTimer] = useState("0");
  const [showPrivacyAndPolicy, setPrivacyAndPolicy] = useState<boolean>(false);
  const [showTermsAndC, setTermsAndC] = useState<boolean>(false);

  const registerUserData = useSelector(
    (state: {
      AuthReducer: {
        registerUserData: {
          email: string;
          firstName: string;
          lastName: string;
        };
      };
    }) => state.AuthReducer.registerUserData
  );

  const registerData: string = sessionStorage.getItem("registerUser") || "";

  const sessionUserData = registerData && JSON.parse(registerData);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<SignUpCredentials>();

  const catchError = (error) => {
    toast.error(error.response.data.message);

    setLoading(false);
  };
  const handleRegister = (data: SignUpCredentials) => {
    if (tAndC) {
      if (data.firstName.trim() === "") {
        setError("firstName", {
          type: "custom",
          message: "Please enter a valid First name.",
        });
      }
      if (data.lastName.trim() === "") {
        setError("lastName", {
          type: "custom",
          message: "Please enter a valid Last name",
        });
      }
      if (data.firstName.trim() !== "" && data.lastName.trim() !== "") {
        onSignUp(data);
      }
    } else {
      setError("TermsandCondition", {
        type: "custom",
        message:
          "Please confirm that you have read and agree to the terms, conditions, and privacy statement.",
      });
    }
  };

  const onSignUp = (userData: {
    password: string;
    lastName: string;
    firstName: string;
    email: string;
  }) => {
    setLoading(true);

    const bodyData = {
      firstName: `${userData.firstName.trim()}`,
      lastName: `${userData.lastName.trim()}`,
      name: `${userData.firstName.trim()} ${userData.lastName.trim()}`,
      password: userData.password,
      email: userData.email,
    };

    AxiosPostWithToken("/user/register", bodyData, { headers })
      .then((response: ResponseData) => {
        if (response.status === 200) {
          setLoading(false);
          console.log(response);
          sessionStorage.setItem(
            "registerUser",
            JSON.stringify({
              firstName: bodyData.firstName,
              lastName: bodyData.lastName,
              email: bodyData.email,
              //ID: response.data.data.ID,

              //MongoDB Change
              ID: response.data.data._id,
            })
          );
          setSendedOtp(true);
          toast.success(
            "An OTP has been sent to your registered email id. Please verify the OTP."
          );
        }

        dispatch(registerUsers(userData));
      })
      .catch((error: Error) => {
        catchError(error);
      });
  };

  const verifyUser = (otpData: otpCredential, registerUser: registerUser) => {
    const bodyData = {
      code: otpData.otp,
      email: registerUser.email,
      password: otpData.password,
    };

    dispatch(clearVerifyUsers());
    setLoading(true);

    AxiosPostWithToken("/user/verify", bodyData, { headers })
      .then((response: ResponseData) => {
        if (response.status === 200) {
          setLoading(false);

          toast.success("Your email address has been verified successfully.");
          sessionStorage.setItem("isSignUp", "true");
          navigate("/subscribe");
        }
        dispatch(verifyUsers());
      })
      .catch((error: Error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  const onOtpCheck = (data: otpCredential) => {
    if (tAndC) {
      if (data.otp) {
        if (registerUserData && registerUserData?.email) {
          verifyUser(data, registerUserData);
        } else {
          sessionUserData?.email
            ? verifyUser(data, sessionUserData)
            : toast.error("No email address found.");
        }
      } else {
        toast.error("Please accept all terms and condition.");
      }
    } else {
      toast.error("Please accept all terms and condition.");
    }
  };

  const getDeadTime = () => {
    const deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  const startTimer = (e) => {
    const currentDate: any = new Date();
    const total = Date.parse(e) - Date.parse(currentDate);
    const seconds: number = Math.floor((total / 1000) % 60);

    if (total >= 0) {
      setTimer(seconds > 9 ? `${seconds}` : "0" + seconds);
    }
  };

  const clearTimer = (e) => {
    setTimer("30");

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);

    Ref.current = id;
  };

  const resendOtp = (bodyData: object) => {
    setLoading(true);

    AxiosPostWithToken("/user/resend-code", bodyData, { headers })
      .then((response: ResponseData) => {
        if (response.status === 200) {
          setLoading(false);

          clearTimer(getDeadTime());

          toast.success(
            "OTP has been sent successfully to your registered email address."
          );
        }
      })
      .catch((error: Error) => {
        catchError(error);
      });
  };

  const onResendOtp = (e) => {
    e.preventDefault();
    if (registerUserData && registerUserData?.email) {
      const bodyData = {
        email: registerUserData?.email,
      };

      resendOtp(bodyData);
    } else {
      if (sessionUserData?.email) {
        const bodyData = {
          email: sessionUserData?.email,
        };

        resendOtp(bodyData);
      } else {
        toast.error("Email address not found.");
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className={"signup-page bg-white"}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div
                className={
                  sendedOtp
                    ? "bg-light-md signup-block-with-otp shadow rounded"
                    : "bg-light-md signup-block shadow rounded"
                }
              >
                <div className="row">
                  <div className="col-md-4 d-none d-md-block">
                    <div className="form-left h-100 brand-block text-white text-center py-5">
                      <div className="d-flex flex-column">
                        <div className="logo">
                          <img src={momlogo} alt="" />
                        </div>
                        <div className="gp-logo align-items-end py-2">
                          <img src={abrahamLogo} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="signup-card bg-white">
                      <div className="mob-logo mt-3">
                        <img src={momhoriLogo} />
                      </div>
                      <div
                        className={
                          "form-right signup-section  d-block h-100 py-4 px-4"
                        }
                      >
                        <form
                          className="row g-3"
                          onSubmit={
                            sendedOtp
                              ? handleSubmit(onOtpCheck)
                              : handleSubmit(handleRegister)
                          }
                        >
                          <p className="mt-3 mb-1">
                            Welcome to Mind of the Market BOT!
                          </p>
                          <h2 className="mt-1 mb-3">Signup</h2>
                          <div className="col-md-6 col-12 textfield">
                            <label>
                              First name<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              autoFocus
                              placeholder="Enter first name"
                              disabled={sendedOtp}
                              maxLength={25}
                              {...register("firstName", {
                                required: "First name is required.",
                                pattern: {
                                  value: /^[a-z\d\-_\s]+$/i,
                                  message:
                                    "Special characters are not allowed.",
                                },
                                minLength: {
                                  value: 1,
                                  message: "Please enter a value.",
                                },
                                // maxLength: {
                                //   value: 25,
                                //   message: 'Please enter a value lesser than 26 characters.'
                                // }
                              })}
                            />
                            <p className="error invalid-feedback d-block h-30">
                              <span className="text-danger">
                                <ErrorMessage
                                  errors={errors}
                                  name="firstName"
                                />
                              </span>
                            </p>
                          </div>
                          <div className="col-md-6 col-12 textfield">
                            <label>
                              Last name<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter last name"
                              disabled={sendedOtp}
                              maxLength={25}
                              {...register("lastName", {
                                required: "Last name is required.",
                                pattern: {
                                  value: /^[a-z\d\-_\s]+$/i,
                                  message:
                                    "Special characters are not allowed.",
                                },
                                minLength: {
                                  value: 1,
                                  message: "Please enter a value.",
                                },
                                // maxLength: {
                                //   value: 25,
                                //   message: 'Please enter a value lesser than 26 characters.'
                                // }
                              })}
                            />
                            <p className="error invalid-feedback d-block h-30">
                              <span className="text-danger">
                                <ErrorMessage errors={errors} name="lastName" />
                              </span>
                            </p>
                          </div>
                          <div className="col-md-6 col-12 textfield">
                            <label>
                              Email<span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter email"
                              disabled={sendedOtp}
                              {...register("email", {
                                required: "Email is required.",
                                pattern: {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: "Please enter a valid email.",
                                },
                              })}
                            />
                            <p className="error invalid-feedback d-block h-30">
                              <span className="text-danger">
                                <ErrorMessage errors={errors} name="email" />
                              </span>
                            </p>
                          </div>
                          <div className="col-md-6 col-12 textfield">
                            <label>
                              Password<span className="text-danger">*</span>
                            </label>

                            <input
                              type="password"
                              className="form-control"
                              placeholder="Enter password"
                              disabled={sendedOtp}
                              {...register("password", {
                                required: "Please enter a password.",
                                minLength: {
                                  value: 8,
                                  message:
                                    "Please enter at least 8 character password.",
                                },
                                pattern: {
                                  value:
                                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
                                  message:
                                    "Password must have at least one lowercase, uppercase, special, and numeric character.",
                                },
                              })}
                            />

                            <p className="error invalid-feedback d-block h-30">
                              <span className="text-danger">
                                <ErrorMessage errors={errors} name="password" />
                              </span>
                            </p>
                          </div>

                          {sendedOtp ? (
                            <>
                              <div className="col-md-6 col-12 textfield">
                                <label>
                                  OTP<span className="text-danger">*</span>
                                </label>
                                <input
                                  type="Number"
                                  className="form-control"
                                  autoFocus
                                  placeholder="Enter OTP"
                                  {...register("otp", {
                                    required: "Please enter a valid OTP.",
                                    minLength: {
                                      value: 6,
                                      message: "Please enter a valid OTP.",
                                    },
                                    maxLength: {
                                      value: 6,
                                      message: "Please enter a valid OTP.",
                                    },
                                  })}
                                />
                                <button
                                  type="button"
                                  className="btn-send-otp"
                                  onClick={onResendOtp}
                                  disabled={parseInt(timer) > 0}
                                >
                                  {parseInt(timer) > 0 ? (
                                    `${timer}`
                                  ) : (
                                    <img src={resendLogo} />
                                  )}
                                </button>
                                <p className="error invalid-feedback d-block h-30">
                                  <span className="text-danger">
                                    <ErrorMessage errors={errors} name="otp" />
                                  </span>
                                </p>
                              </div>
                              <div className="col-sm-12">
                                <div className="cust-checkbox">
                                  <div className="custom-checkbox">
                                    <input
                                      className="checkbox-custom"
                                      id="chk_agree"
                                      type="checkbox"
                                      checked={tAndC}
                                      onChange={() => setTAndC(!tAndC)}
                                      onClick={() => setTAndC(!tAndC)}
                                    />
                                    <label
                                      className="checkbox-custom-label"
                                      htmlFor="chk_agree"
                                    >
                                      I agree to the{" "}
                                      <a href="#">
                                        <strong> terms of use</strong>
                                      </a>{" "}
                                      of application and{" "}
                                      <a href="#">
                                        <strong> privacy policy</strong>
                                      </a>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <button
                                  type="submit"
                                  className="btn btn-signup border-0 float-start mt-2 me-3"
                                >
                                  Continue
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-sec border-0 mt-2"
                                  onClick={() => navigate("/login")}
                                >
                                  Cancel
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-12 textfield">
                                <div className="col-sm-12">
                                  <div className="cust-checkbox">
                                    <div className="custom-checkbox">
                                      <input
                                        className="checkbox-custom"
                                        id="chk_agree"
                                        type="checkbox"
                                        {...register("TermsandCondition", {
                                          minLength: {
                                            value: 1,
                                            message: "",
                                          },
                                        })}
                                        checked={tAndC}
                                        onChange={() => {
                                          setTAndC(!tAndC);
                                        }}
                                        onClick={() => {
                                          setTAndC(!tAndC);
                                        }}
                                      />
                                      <label
                                        className="checkbox-custom-label"
                                        htmlFor="chk_agree"
                                      >
                                        I agree to the
                                        <a
                                          href=""
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setTermsAndC(!showTermsAndC);
                                          }}
                                        >
                                          <strong> terms of use</strong>
                                        </a>{" "}
                                        of application and
                                        <a
                                          href=""
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setPrivacyAndPolicy(
                                              !showPrivacyAndPolicy
                                            );
                                          }}
                                        >
                                          <strong> privacy policy</strong>
                                        </a>
                                      </label>
                                      <p className="error invalid-feedback d-block h-30">
                                        <span className="text-danger">
                                          <ErrorMessage
                                            errors={errors}
                                            name="TermsandCondition"
                                          />
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <button
                                  type="submit"
                                  className="btn btn-signup border-0 float-start mt-2 me-3"
                                >
                                  Get otp
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-sec border-0 mt-2"
                                  onClick={() => navigate("/login")}
                                >
                                  Cancel
                                </button>
                              </div>
                              <PrivacyAndPolicy
                                showPrivacyAndPolicy={showPrivacyAndPolicy}
                                setPrivacyAndPolicy={setPrivacyAndPolicy}
                              />
                              <TermsAndCondition
                                showTermsAndC={showTermsAndC}
                                setTermsAndC={setTermsAndC}
                              />
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
