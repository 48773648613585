/* eslint-disable */
// export default function FormateClipBoardData(responseData) {
//   const listData = {};

//   responseData.map((data: any, index: number) => {
//     // const id = data.ID;
//     // const parentId = data.PARENT_CATEGORY_ID;
//     const id = data.categories._id;
//     const parentId = data._id;
//     console.log(id, parentId);
//     console.log(data);
//     const keys = Object.keys(listData);
//     if (keys.includes(id)) {
//       listData[id][0].push(data);
//       console.log(listData);
//     } else {
//       if (parentId === null) {
//         listData[id] = {};
//         listData[id][0] = [];
//         listData[id][0].push(data);
//         console.log(listData);
//       } else {
//         if (!listData[parentId][id]) {
//           listData[parentId][id] = [];
//           listData[parentId][id].push(data);
//           console.log(listData);
//         } else {
//           listData[parentId][id].push(data);
//           console.log(listData);
//         }
//       }
//     }
//     return null;
//   });
//   return listData;
// }

//Chatgpt v1

// export default function FormateClipBoardData(responseData) {
//   const listData = {};

//   responseData.map((data: any, index: number) => {
//     const id = data.categories._id;
//     const parentId = data._id;
//     console.log(id, parentId);
//     console.log(data);
//     const keys = Object.keys(listData);

//     if (keys.includes(id)) {
//       listData[id][0].push(data);
//       console.log(listData);
//     } else {
//       if (parentId === null) {
//         listData[id] = {};
//         listData[id][0] = [];
//         listData[id][0].push(data);
//         console.log(listData);
//       } else {
//         // Ensure listData[parentId] is initialized
//         if (!listData[parentId]) {
//           listData[parentId] = {};
//         }
//         if (!listData[parentId][id]) {
//           listData[parentId][id] = [];
//         }
//         listData[parentId][id].push(data);
//         console.log(listData);
//       }
//     }
//     return null;
//   });

//   return listData;
// }

// Chatgpt v2

export default function FormateClipBoardData(responseData) {
  const listData = {};

  responseData.map((data: any) => {
    const id = data.categories._id;
    const parentId = data.categories.PARENT_ID;

    if (listData[id]) {
      if (!listData[id][0]) {
        listData[id][0] = [];
      }
      listData[id][0].push(data);
    } else {
      if (parentId === null) {
        listData[id] = {};
        listData[id][0] = [];
        listData[id][0].push(data);
      } else {
        if (!listData[parentId]) {
          listData[parentId] = {};
        }
        if (!listData[parentId][id]) {
          listData[parentId][id] = [];
        }
        listData[parentId][id].push(data);
      }
    }
    return null;
  });
  console.log(listData);
  return listData;
}

// Example of the converted data into the formated data.

// real:

// > Predefined
//   Text 1
//   Text 2

//   > Positive
//     TextClip 3
//     TextClip 4

//   > Negative
//     TextClip 5

// > CAT 1
//   Text 3
//   Text 4

//   > Positive
//     TextClip 6
//     TextClip 7

//   > Negative
//     TextClip 8
//     TextClip 9

// CONVERTED  DATA:

// {
//   100 (Predefined) : {
//     0 : [
//       {Text 1 }
//       {Text 2 }
//     ],
//     200(Positive) : [
//       {TextClip 3 }
//       {TextClip 4 }
//     ],
//     201(Negative) : [
//       {TextClip 5 }
//     ],
//   }

//   101 (CAT 1) : {
//     0 : [
//       {Text 3 }
//       {Text 4 }
//     ],
//     202(Positive) : [
//       {TextClip 6 }
//       {TextClip 7 }
//     ],
//     203(Negative) : [
//       {TextClip 8 }
//       {TextClip 9 }
//     ],
//   }
// }
