/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Text,
  Modal,
  ScrollArea,
  Divider,
  Flex,
  Button,
  Grid,
  TextInput,
  Select,
  Textarea,
  Card,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AxiosDeleteWithToken, AxiosPutWithToken } from "../../../../Utils/Api";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import AddSnippetGeneral from "../../../Bookmarks/Modal/AddSnippetGeneral";

interface Header {
  Authorization: string | null;
}

interface GenerateContentModalProps {
  opened: boolean;
  onClose: () => void;
  content: string;
  setContent: Function;
  prompt: string;
}

const GenerateContentModal: React.FC<GenerateContentModalProps> = ({
  opened,
  onClose,
  content,
  setContent,
  prompt,
}) => {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [isGenerateComplete, setIsGenerateComplete] = useState<boolean>(false);
  const [generatedContent, setGeneratedContent] = useState<string>("");
  const [clipboardShow, setClipboardShow] = useState(false);

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  useEffect(() => {
    if (opened) {
      if (content && prompt) {
        generateContent();
      }
    }
  }, [opened, content, prompt]);

  const generateContent = async () => {
    setIsGenerateComplete(false);
    const url = `${process.env.REACT_APP_BASE_URL}/analyzeReviews`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: content,
          message: prompt,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (!response.body) {
        throw new Error("ReadableStream not available in the response");
      }

      const reader = response.body.getReader();
      let analysisResult = "";

      // Read the stream
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = new TextDecoder().decode(value);
        analysisResult += chunk;
        setGeneratedContent((prevResult) => prevResult + chunk);
      }

      // console.log("Stream complete:", analysisResult);
      setIsGenerateComplete(true);
    } catch (error) {
      console.error("Failed to analyze review:", error);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
        setGeneratedContent("");
        setIsGenerateComplete(false);
      }}
      title={
        <Text size="xl" fw={700} c={"#333"}>
          {isGenerateComplete ? "Generated content" : "Generating content..."}
        </Text>
      }
      size="70%"
      fullScreen={isMobile}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      centered
      radius={15}
    >
      <Divider />
      <ScrollArea style={{ height: isMobile ? "78vh" : "65vh" }} mt={"xs"}>
        <ReactQuill value={generatedContent} readOnly={true} theme="bubble" />
      </ScrollArea>
      <Divider my="sm" />
      <Flex mt="md" justify="flex-end" gap="xs" align="center">
        <Button
          variant="default"
          radius="md"
          onClick={() => {
            setClipboardShow(true);
          }}
          disabled={!isGenerateComplete}
        >
          Copy to bookmark
        </Button>
        <Button
          variant="filled"
          radius="md"
          disabled={!isGenerateComplete}
          onClick={() => {
            onClose();
            setContent(generatedContent);
          }}
        >
          Copy & Replace
        </Button>
      </Flex>

      {clipboardShow && (
        // <AddTextClip
        //   show={clipboardShow}
        //   setShow={setClipboardShow}
        //   content={clipboardContent}
        //   setContent={setClipboardContent}
        //   selectedCategory={clipboardSelectedCategory}
        //   setSelectedCategory={setClipboardSelectedCategory}
        //   list={list}
        //   setList={setList}
        //   bookUrl={bookUrl}
        //   setBookUrl={setBookUrl}
        //   setAddReference={setAddReference}
        // />
        <AddSnippetGeneral
          opened={clipboardShow}
          onClose={() => setClipboardShow(false)}
          content={generatedContent!}
          type={"rich-text"}
          isBook={false}
        />
      )}
    </Modal>
  );
};

export default GenerateContentModal;
