/* eslint-disable */
import React from "react";
import "./contact.css";
import logo from "../main/momlogo.webp";
import image1 from "../about/placeholderimage1.webp";
import image2 from "../about/placeholderimage2.webp";
import image3 from "../about/placeholderimage3.webp";
import meetingImage from "../../images/meetingImage.webp";
import { FaEye, FaPen, FaBook, FaChartPie } from "react-icons/fa";

const ContactPage: React.FC = () => {
  return (
    <div className="homepage-div">
      <header className="header">
        <div className="logo">
          <img src={logo} alt="Mind of the Market" />
        </div>
        <nav className="navigation">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/contact" style={{ color: "#FFA500" }}>
                Contact
              </a>
            </li>
            <li className="login-button">
              <a href="/home">Login</a>
            </li>
          </ul>
        </nav>
      </header>

      <section className="choose-section">
        <div>
          <p className="choose-title">Contact Us</p>
          {/* <p className="choose-text">
            Mind of the Market is a copywriting tool that helps businesses
            create compelling content that drives sales and engagement. Our
            easy-to-use platform offers a range of features and tools that make
            it simple to craft high-quality content that resonates with your
            target audience. Click here to sign up and get started today!
          </p> */}
        </div>
      </section>

      <section className="success-section">
        <div className="text-content">
          <h2>Ready to Elevate Your Content?</h2>
          <form className="contact-form">
            <div className="form-group">
              <input
                type="text"
                id="first-name"
                placeholder="First Name *"
                required
              />
              <input
                type="text"
                id="last-name"
                placeholder="Last Name *"
                required
              />
            </div>
            <div className="form-group">
              <input type="email" id="email" placeholder="Email *" required />
              <input type="tel" id="phone" placeholder="Phone" />
            </div>
            <textarea id="message" placeholder="Message"></textarea>
            <button type="submit" className="submit-button">
              <a>Contact Us</a>
            </button>
          </form>
        </div>
        <div className="image-content">
          <img src={meetingImage} alt="Team meeting" />
        </div>
      </section>

      <footer className="site-footer">
        <div className="footer-section">
          <h4>MIND OF THE MARKET</h4>
        </div>
        <div className="footer-section">
          <h4>Menu</h4>
          <ul>
            <li>Home</li>

            <li>About</li>
            <li>Contact</li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Address</h4>
          <address>
            7520 Standish Place,
            <br />
            St 260, Derwood MD 20866
          </address>
        </div>
        <div className="footer-section">
          <h4>Socials</h4>
          <ul>
            <li>LinkedIn</li>
            <li>Facebook</li>
            <li>Instagram</li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default ContactPage;
