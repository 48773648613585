import React, { useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { AxiosPutWithToken } from '../../Utils/Api';

interface userList
{
  email:string
  firstName:string
  lastName: string
  status:number
  id:number
}

interface propsTypes {
  setShowUserDetailsModal: Function,
  showUserDetailsModal: boolean,
  setUserDetails:Function,
  userDetails: userList | undefined
  getAllUsersList:Function
}

interface Header { Authorization: string | null }

// eslint-disable-next-line no-unused-vars
enum StatusType {
  // eslint-disable-next-line no-unused-vars
  Active = 'Active',
  // eslint-disable-next-line no-unused-vars
  Inactive = 'In active',
};

const StatusValues = [StatusType.Active, StatusType.Inactive];

const UserDetail = (props: propsTypes) => {
  const { showUserDetailsModal, setShowUserDetailsModal, userDetails, setUserDetails, getAllUsersList } = props;

  const { register, setValue, handleSubmit } = useForm();

  const headers: Header = { Authorization: localStorage.getItem('accessToken') };

  const handleClose = () => { setShowUserDetailsModal(!showUserDetailsModal); };

  useEffect(() => {
    setValue('email', userDetails?.email);
    setValue('firstName', userDetails?.firstName);
    setValue('lastName', userDetails?.lastName);
    setValue('status', userDetails?.status === 1 ? StatusType.Active : userDetails?.status === 0 && StatusType.Inactive);
  }, [userDetails]);

  const updateUserStatusApi = (userId, status) => {
    AxiosPutWithToken(`/admin/users/update/${userId}`, { status }, { headers })
      .then((response) => {
        setShowUserDetailsModal(!showUserDetailsModal);
        getAllUsersList();
        setUserDetails({});
        toast.success(response.data.message);
      }).catch((error) => {
        toast.error(error.response.data.message);
      });
    setShowUserDetailsModal(!showUserDetailsModal);
  };

  const updateUserStatus = handleSubmit((data) => {
    if (headers?.Authorization) {
      updateUserStatusApi(userDetails?.id, data.status);
    }
  });

  return (
    <>
      <Modal show={showUserDetailsModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>User details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>First name</Form.Label>
              <Form.Control placeholder="Enter first name" {...register('firstName')} disabled/>
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Last name</Form.Label>
              <Form.Control placeholder="Enter first name" {...register('lastName')} disabled />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Email</Form.Label>
              <Form.Control placeholder="Enter email " {...register('email')} disabled />
            </Form.Group>
            <Form.Group
              className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Select className='form-control' placeholder="Select" {...register('status', { required: 'status is required' })}>
                {StatusValues.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}

              </Form.Select>

            </Form.Group>
            <button className='float-start btn btn-primary border-0 mt-1' onClick={updateUserStatus}>
          Save
            </button>
          </Form>
        </Modal.Body>

      </Modal></>
  );
};

export default UserDetail;
