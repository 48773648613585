import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { dateFormat } from '../../Utils/helper';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment';
import { AxiosPutWithToken, AxiosGetWithToken, AxiosPostWithToken } from '../../Utils/Api';
import Loader from '../../Components/Loader/loader';

export default function CreateNewProject (props:any) {
  interface Header {
    Authorization: string | null
  }
  interface updateData{
    data: object;
    message: string;
    status: number;
  }

  interface updateProjectResponse extends AxiosResponse {
    data: updateData;
  };

  // const navigate = useNavigate();
  const headers:Header = { Authorization: localStorage.getItem('accessToken') };

  const { show, setShow, projectId, setIsCreated, setIsUpdate } = props;

  const [loading, setLoading] = useState(false);
  const [startDate, setStartData] = useState<string | null>('');

  const [dueDate, setDueData] = useState<string | null>('');
  // const [tAndC, setTAndC] = useState(false);

  const { register, setValue, handleSubmit, setError, control, formState: { errors } } = useForm();

  function handleClose () {
    setShow(!show);
  }
  const handleCreate = (data) => {
    if (data.projectName.trim() === '') {
      setError('projectName', { type: 'custom', message: 'Please enter a valid Project name.' });
    }
    if (data.projectName.trim() !== '') {
      if (!projectId) {
        createProject(data);
      } else {
        updateProject(data);
      }
    }
  };

  const createProject = (data) => {
    if (headers?.Authorization) {
      setIsCreated(false);
      AxiosPostWithToken('/project/create',
        { ...data, startDate: data.startDate === undefined ? '' : moment(startDate).format('YYYY-MM-DD'), endDate: data.endDate === undefined ? '' : moment(dueDate).format('YYYY-MM-DD') }, { headers })
        .then((response: any) => {
          setIsCreated(true);
          toast.success(response.data.message);
        }).catch((error) => {
          toast.error(error.response.data.message);
        });
    }
    setLoading(true);
    handleClose();
  };

  const updateProject = (data) => {
    if (headers?.Authorization) {
      setIsUpdate(false);
      AxiosPutWithToken(`/project/update/${projectId}`, { ...data, startDate: data.startDate === null ? '' : moment(startDate).format('YYYY-MM-DD'), endDate: data.endDate === null ? '' : moment(dueDate).format('YYYY-MM-DD') }, { headers })
        .then((response: updateProjectResponse) => {
          setIsUpdate(true);
          toast.success(response.data.message);
        }).catch((error:any) => {
          toast.error(error.response.data.message);
        });
    }
    handleClose();
  };

  useEffect(() => {
    if (headers?.Authorization && projectId) {
      AxiosGetWithToken(`/project/${projectId}`, { headers })
        .then((response: any) => {
          setStartData(response.data.data.project?.START_ON ? moment(response.data.data.project?.START_ON).format(dateFormat) : null);
          setDueData(response.data.data.project?.DUE_ON ? moment(response.data.data.project?.DUE_ON).format(dateFormat) : null);
          if (projectId) {
            setValue('startDate', response.data.data.project?.START_ON ? moment(response.data.data.project?.START_ON).format('YYYY-MM-DD') : null);
            setValue('endDate', response.data.data.project?.DUE_ON ? moment(response.data.data.project?.DUE_ON).format('YYYY-MM-DD') : null);
            setValue('status', response.data.data.project?.STATUS);
            setValue('description', response.data.data.project?.DESCRIPTION);
            setValue('clientName', response.data.data.project?.CLIENT_NAME);
            setValue('projectName', response.data.data.project?.NAME);
          }
        });
    }
  }, []);

  return (

    <Container fluid>
      <Row>
        {loading && (<Loader />)}
        <Col >
          <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton className='px-4'>
              <Modal.Title >
                {!projectId
                  ? 'Create new project'
                  : 'Edit project'}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className='px-4'>
              <Form onSubmit={handleSubmit(handleCreate)}>
                <Col>
                  <Form.Group className="mb-4" controlId="First_name" >
                    <div className='col-12 textfield'>
                      <Form.Label>Project name
                        <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control type="text"
                        placeholder="Enter project name"
                        maxLength={255}
                        {...register('projectName', {
                          required: 'Please enter a valid name.',
                          minLength: {
                            value: 1,
                            message: 'Please enter a valid name.'
                          }
                          // maxLength: {
                          //   value: 256,
                          //   message: ' Please Enter less Than 256 Words'
                          // }
                        })}
                      />
                      <p className='error invalid-feedback d-block h-50'>
                        <span className='text-danger'>
                          <ErrorMessage errors={errors} name="projectName" />
                        </span>
                      </p>
                    </div>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-4" controlId="First_name" >
                    <div className='col-12 textfield'>
                      <Form.Label>Description
                      </Form.Label>
                      <textarea className='form-control area-size'
                        rows={3} cols={100}
                        placeholder="Enter description"
                        maxLength={255}
                        {...register('description', {
                          // maxLength: {
                          //   value: 256,
                          //   message: ' Please Enter less than 256 Words'
                          // }
                        })}
                      />
                      <p className='error invalid-feedback d-block h-50'>
                        <span className='text-danger'>
                          <ErrorMessage errors={errors} name="description" />
                        </span>
                      </p>
                    </div>
                  </Form.Group>
                </Col>

                <Row>
                  <Col xs lg='6'>
                    <Form.Group className="mb-4" controlId="First_name" >
                      <Form.Label>Start date
                      </Form.Label>
                      <Controller
                        control={control}
                        name='startDate'
                        render={({ field }) => {
                          return (
                            <DatePicker
                              dateFormate={dateFormat}
                              placeholderText={dateFormat}
                              onKeyDown={(e) => { e.preventDefault(); }}
                              onChange={(e) => {
                                setStartData(moment(e).format(dateFormat));
                                field.onChange(e);
                              }
                              }
                              value={startDate ? moment(startDate).format(dateFormat) : null}
                              className={'form-control'}
                            />);
                        }}
                      />

                    </Form.Group>
                  </Col>
                  <Col xs lg='6'>
                    <Form.Group className="mb-4" controlId="First_name" >
                      <Form.Label>Due date
                      </Form.Label>
                      <Controller
                        control={control}
                        name='endDate'
                        // rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <DatePicker
                              dateFormate={dateFormat}
                              placeholderText={dateFormat}
                              onKeyDown={(e) => { e.preventDefault(); }}
                              onChange={(e) => {
                                setDueData(moment(e).format(dateFormat));
                                field.onChange(e);
                              }
                              }
                              value={dueDate ? moment(dueDate).format(dateFormat) : null}
                              className={'form-control'}
                            />);
                        }}
                      />
                      {/* {errors.endDate && <p className='error invalid-feedback d-block h-50'>
                        <span className='text-danger'>
                          End Date is required
                        </span></p>} */}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs lg='6'>
                    <Form.Group className="mb-4" controlId="First_name" >
                      <div className='col-12 textfield'>
                        <Form.Label>Client name
                        </Form.Label>
                        <Form.Control type="text"
                          placeholder="Enter client name"
                          maxLength={255}
                          {...register('clientName'
                          // {
                            // maxLength: {
                            //   value: 256,
                            //   message: ' Please Enter less 256 Words'
                            // }
                          // }
                          )}
                        />
                        <p className='error invalid-feedback d-block h-50'>
                          <span className='text-danger'>
                            <ErrorMessage errors={errors} name="clientName" />
                          </span>
                        </p>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs lg='6'>
                    <Form.Group className="mb-4" controlId="First_name" >
                      <div className='col-12 textfield'>
                        <Form.Label>Status
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Select className='form-control'
                          placeholder="Select"
                          {...register('status', {
                            required: 'status is required'
                          })}
                        >
                          {/* <option value=''>Select</option> */}
                          <option value='in progress'>In progress</option>
                          <option value='paused'>Paused</option>
                          <option value='complete'>Complete</option>
                          <option value='archived'>Archived</option>

                        </Form.Select>
                        <p className='error invalid-feedback d-block h-50'>
                          <span className='text-danger'>
                            <ErrorMessage errors={errors} name="status" />
                          </span>
                        </p>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Button type='submit' className='px-3 momBotTextButton' variant="primary"> {!projectId ? 'Create' : 'Update' }</Button>
              </Form>

            </Modal.Body>

          </Modal>
        </Col>
      </Row>
    </Container>

  );
}
