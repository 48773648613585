export const registerUsers = (data) => {
  return {
    type: 'REGISTER_USER',
    payload: data
  };
};

export const clearVerifyUsers = () => {
  return {
    type: 'CLEAR_VERIFY_USER',
    payload: false
  };
};

export const verifyUsers = () => {
  return {
    type: 'VERIFY_USER',
    payload: true
  };
};
